import { ofType, combineEpics } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs'
import { addNotification } from '@core-action';
import { NotificationType } from '@core-interface';
import {
  GET_PASSWORD_RESET_LINK, setPasswordResetLink
} from './forgetPassword.action';
import { httpPostV2 } from 'app-core/httpCall/httpCallv2';
import { StatusCode } from 'app-core/constant/authTypes';

export const epicGetForgetPasswordLink = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PASSWORD_RESET_LINK),
    switchMap(({ payload }: any) =>
      httpPostV2({
        call: `user/forgot/password`,
        data: payload
      }).pipe(mergeMap(({ status }: any) => {
        if (status === StatusCode.success) {
          return of(
            setPasswordResetLink(status),
            addNotification({
              type: NotificationType.SUCCESS,
              messageKey: 'successfully_send_reset_link',
              message: `Successfully Send Reset Link to provided Email`,
            }),
          );
        } else {
          return of(
            setPasswordResetLink(status),
            addNotification({
            type: NotificationType.ERROR,
            messageKey: 'error_sending_reset_link',
            message: 'Error Sending Reset Link, Please try again',
          }),
          );
        }
      }),
      )
    )
  );
};

const ForgetPasswordEpic = combineEpics(
  epicGetForgetPasswordLink
);

export default ForgetPasswordEpic;