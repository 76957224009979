import { faPenToSquare } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlexRow } from "app-styled/flex";
import styled, { css } from "styled-components";

export const IconWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    .fa-primary {
      fill: ${theme["smylor-blue-darken-D"]} !important;
      opacity: unset;
    }
    .fa-secondary {
      fill: ${theme["smylor-light-blueA"]} !important;
      opacity: unset;
    }
  `}
`;

const SmylorEditIcon = ({ onClick }: { onClick: Function }) => {
  return (
    <IconWrapper onClick={onClick}>
      <FontAwesomeIcon icon={faPenToSquare} />
    </IconWrapper>
  );
};

export { SmylorEditIcon };
