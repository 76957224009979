import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, groupBy, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { httpGet, httpPost } from "@core-http";
import {
  GET_REVIEW_DATA,
  SUBMIT_DENTIST_REVIEW_DATA,
  setReviewData,
  updateDentistReviewData,
} from "./reviewCommon.actions";
import { addNotification } from "@core-action";
import { NotificationType, ReviewTreatmentStatus } from "@core-interface";

export const epicsubmitDentistReviewData = (action$, state$) => {
  return action$.pipe(
    ofType(SUBMIT_DENTIST_REVIEW_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `TreatmentReview/UpdateTreatmentReviewStatus`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }) => {
          if (Success) {
            if (payload.Status === ReviewTreatmentStatus.ACCEPTED) {
              return of(
                updateDentistReviewData(payload),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: "successfully_review_added",
                  message: "Successfully Review Added",
                })
              );
            } else if (payload.Status === ReviewTreatmentStatus.REJECTED) {
              return of(
                updateDentistReviewData(payload),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: "successfully_review_rejected",
                  message: "Successfully Review Rejected",
                })
              );
            }
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_submitting_review",
                message: "Error on adding the Review",
              })
            );
          }
        })
      )
    )
  );
};
export const epicGetReviewData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_REVIEW_DATA),
    switchMap(({ payload }) =>
      httpGet({
        call: `TreatmentReview/GetTreatmentReviewsById/${payload}`,
      }).pipe(map(({ Data }: any) => setReviewData(Data)))
    )
  );
};

const TreatmentReviewDataEpic = combineEpics(
  epicGetReviewData,
  epicsubmitDentistReviewData
);

export default TreatmentReviewDataEpic;
