import styled, { css } from "styled-components";
import { device } from "@core-const";
import {
  SubTitle,
  ItemTitle,
  LabelTiltle,
  Title,
  PageContainerFullWidth,
  PageContainerSmall,
} from "app-styled-components";

import { Row, Col } from "reactstrap";
import { Image } from "app-styled-components";
import { FlexCenter, FlexRow } from "app-styled/flex";
import { LinkButton } from "app-styled/link";

export const CategoryWrapper = styled(PageContainerFullWidth)`
  height: auto;
  margin-top: 80px !important;
  margin: 0px;
  ${({ theme }) => css`
    padding: ${theme["padding-container"]};
  `}
  @media ${device.tablet} {
    ${({ theme }) => css`
      padding: 0;
    `}
  }
`;

export const MainContainer = styled(PageContainerSmall)`
  ${({ theme }) => css`
    padding-bottom: ${theme["padding-medium"]};
    width: 100%;
  `}
`;

// New Styles

export const ServicesContainer = styled(Row)``;

export const ServicesDescriptionInner = styled(Col)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-lg"]};
  `};
`;

export const ServicesTypeInner = styled(Col)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-lg"]};
  `};
  @media ${device.tablet} {
    ${({ theme }) => css`
      padding-left: 0;
    `};
  }
  @media ${device.mobileL} {
    ${({ theme }) => css`
      padding: ${theme["thm-space-lg"]};
    `};
  }
`;

export const MainImageWrapper = styled(FlexCenter)`
  position: relative;
`;

export const MainImageContainer = styled(Image)`
  ${({ theme }) => css`
    width: auto;
    height: 300px;
    margin-bottom: ${theme["thm-space-xl"]};
    border-radius: 6px;
    @media ${device.laptop} {
      width: 100%;
      height: auto;
    } ;
  `};
`;

export const ServicesTitle = styled(Title)`
  font-size: 2.5rem;
  text-shadow: 2px 2px #292a31;
  ${({ theme }) => css`
    color: ${theme["color-backgroundDark"]};
  `};
`;

export const ServicesSubTitle = styled(SubTitle)`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme["thm-b-text"]};
    padding: ${theme["space-lg"]};
  `};

  @media ${device.tablet} {
    ${({ theme }) => css`
      font-size: ${theme["thm-font-md"]};
      padding: ${theme["space-md"]};
    `}
  }
`;

export const ServicesDescription = styled(FlexRow)`
  text-align: justify;
  ${({ theme }) => css`
    padding-bottom: ${theme["thm-space-xl"]};
  `};
`;

export const ServicesList = styled.ul`
  ${({ theme }) => css`
    padding: 0;
    padding-bottom: ${theme["thm-space-xl"]};
    margin: 0;
    list-style-type: none;
  `};
`;

export const ServicesListData = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme["thm-space-lg"]} 0 ${theme["thm-space-md"]} 0;
    color: ${theme["thm-b-text"]};
    border-top: 1px solid ${theme["thm-border"]};
    transition: padding 0.5s;
    &:first-child {
      border-top: none;
    }
    :hover {
      font-weight: 700;
      cursor: pointer;
      padding-left: ${theme["thm-space-md"]};
      span {
        color: ${theme["thm-base"]};
      }
    }
  `};
  ${({ theme, selected }) =>
    selected &&
    css`
      padding-left: ${theme["thm-space-lg"]};
      transition: none;
      span {
        color: ${theme["thm-base"]};
      }
      &:hover {
        cursor: pointer;
        padding-left: ${theme["thm-space-lg"]};
      }
    `};
  @media ${device.tablet} {
    ${({ theme }) => css`
      span {
        font-size: ${theme["thm-font-sm"]} !important;
      }
    `}
  }
`;

export const SectionContainter = styled.div`
  .setWidth {
    width: 80%;
  }
  ${({ theme, right }) =>
    right &&
    css`
      display: flex;
      justify-content: flex-end;
      .setWidth {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    `};

  @media ${device.tablet} {
    ${({ theme }) => css`
      .setWidth {
        width: 100% !important;
        display: unset;
      }
    `}
  }
`;

export const FooterContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  width: calc(100% - 80px);
  @media ${device.tablet} {
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media ${device.mobileL} {
    width: 100%;
  } ;
`;

export const ServiceIcon = styled(Image)`
  width: 20px;
  height: 20px;
  ${({ theme }) => css`
    margin-right: ${theme["thm-space-sm"]};
    margin-left: ${theme["thm-space-lg"]};
  `}
`;

export const BookNowButtonContainer = styled(LinkButton)`
  margin-left: auto;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    background-color: ${theme["thm-secondary"]};
    // margin-top: ${theme["thm-space-sm"]};
    padding: ${theme["thm-space-sm"]} ${theme["thm-space-lg"]};

    svg {
      ${({ theme }) => css`
        padding-left: ${theme["thm-space-sm"]};
        padding-right: ${theme["thm-space-md"]};
        font-size: 24px;
      `}
    }
  `}
`;

export const TreatmentPriceRangeContainer = styled(FlexRow)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 30px;
    padding: ${theme['thm-space-md']} 0;
    color: ${theme['thm-secondary']};
    align-items: center;
    justify-content: space-between;
    @media ${device.tabletL} {
      flex-direction: column;
      line-height: 10px;
      /* gap: ${theme['thm-space-sm']};  */
  `};
`;

export const TreatmentPriceTitle = styled(LabelTiltle)`
  ${({ theme }) => css`
      font-size: 22px;
      /* color: white; */
      text-shadow: 2px 1px 0 ${theme['thm-light']};
    @media ${device.tabletL} {
      font-size: ${theme['thm-space-lg']};
  `};
`;

export const PriceRatingWrapper = styled.span`
  ${({ theme }) => css`
    /* margin-bottom: ${theme["thm-space-sm"]}; */
  `}
`;


export { ItemTitle, LabelTiltle };
