import { SizeProp } from "@fortawesome/fontawesome-svg-core"

export const EmergencyRequestStatus = {
    PENDING: 1,
    ACCEPTED: 2,
    REPLIED: 3,
    EXPIRED: 4,
    REJECTED: 5,
    COMPLETED: 6,
    ALREADYTAKEN: 7,
    PARTIALLYCOMPLETED: 8,
    NOTRESPONDED: 9
}

export const TreatmentRequestData: SharepointStatusDataProps[] = [
    { keyValue: 'pending', name: 'Pending', id: 1 },
    { keyValue: 'accepted', name: 'Accepted', id: 2 },
    { keyValue: 'rejected', name: 'Rejected', id: 3 },
    { keyValue: 'replied', name: 'Replied', id: 3 },
    { keyValue: 'expired', name: 'Expired', id: 4 },
    { keyValue: 'completed', name: 'Completed', id: 6 },
    { keyValue: 'already_taken', name: 'Already Taken', id: 7 },
    { keyValue: 'partially_completed', name: 'Partially Completed', id: 8 },
    { keyValue: 'not_responded', name: 'Not Responded', id: 9 }
]

export const SharepointStatus = {
    CONSULT: 1,
    PENDING: 2,
    RESERVED: 3,
    BOOKED: 4,
    PAID: 5,
    CHECKING: 6,
    CANCELLED: 7,
    CLOSED: 8,
    UNAVAILABLE: 9,
    OTHER: 10,
    NOSHOW: 11,
}
export const SharepointStatusData: SharepointStatusDataProps[] = [
    { keyValue: 'consult', name: 'Consult', id: 1 },
    { keyValue: 'pending', name: 'Pending', id: 2 },
    { keyValue: 'reserved', name: 'Reserved', id: 3 },
    { keyValue: 'booked', name: 'Booked', id: 4 },
    { keyValue: 'paid', name: 'Paid', id: 5 },
    { keyValue: 'checking', name: 'Checking', id: 6 },
    { keyValue: 'cancelled', name: 'Cancelled', id: 7 },
    { keyValue: 'closed', name: 'Closed', id: 8 },
    { keyValue: 'unavailable', name: 'Unavailable', id: 9 },
    { keyValue: 'other', name: 'Other', id: 10 },
    { keyValue: 'noshow', name: 'No show', id: 11 },
]

export const ReviewTreatmentStatus = {
    PENDING: 1,
    ACCEPTED: 2,
    REJECTED: 3,
}

export const AdenewReview = 'add_new_review'

export const DaySessionEnum = {
    MORNING: 1,
    AFTERNOON: 2,
    ALL: 3,
}

export const TreatmentReviewIconStatus = {
    BOOKING_PROCESS: 1,
    LOCATION_ACCESS: 2,
    WELCOME_BRIEFING: 3,
    TREATMENT_EXPERIENCE: 4,
    POST_TREATMENT_ADVICE: 5,
    TREATMENT_AFFORDABILITY: 6,
    TREATMENT_PAYMENT_OPTIONS: 7,
    TREATMENT_FOLLOW_UP: 8,
    TREATMENT_COSMETIC_RESULT: 9,
}

export interface TreatmentReviewIconProps {
    status: number,
    size?: SizeProp
}
export interface TreatmentRatingDrawerProps {
    reviewId: number;
    messageKey: string;
    defaultMessage: string;
    rating?: number;
    fliedData?: any;
}
export interface SharepointStatusDataProps {
    id: number,
    keyValue: string,
    name:  string
}


