import { FlexRow } from "app-styled/flex";
import styled, { css } from "styled-components";

export const PageLabelWrapper = styled(FlexRow)`
  /* width: 100%; */

  ${({ theme, fullView, background, color }) => css`
    width: ${fullView ? '100%' : undefined};
    padding: 20px;
    border-radius: ${theme["thm-space-md"]};
    background-color: ${background ? theme[background] : theme["smylor-light-blueC"] };
    color: ${background && theme[color]};
  `}

  ${({ theme, border, background}) => border && css`
    background-color: ${theme['smylor-white']};
    border: 1px solid ${background ? theme[background] : theme["smylor-light-blueC"] };
  `}
`;

const SmylorLabelCard = ({ children, fullView, style, color, background, border, ...others }: { children: any; fullView?: boolean, style?: any, color?: any, background?: any, border?: boolean }) => {

    return (
        <PageLabelWrapper fullView={fullView} style={style} color={color} background={background} border={border} {...others}>
            {children}
        </PageLabelWrapper>
    );
};

export { SmylorLabelCard }