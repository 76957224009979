import React from 'react';
import styled, { css } from "styled-components";
import { FlexRow } from 'app-styled/flex';
import { device } from '@core-const';
import { DurationCalculator } from 'app-core/styled/components_v3/TimeDuration';
export const TitleContainer = styled(FlexRow)`

  ${({ theme }) => css`
    padding: ${theme["thm-space-"]} 20px;
    border-top-left-radius: ${theme["thm-space-md"]};
    border-top-right-radius: ${theme["thm-space-md"]};
    background-color: rgba(93, 135, 255, .8);
    color: ${theme["smylor-white"]};
    font-weight: bold;
  `}/* @media ${device.tablet} {
    padding: 1rem 0.5rem;
  } ; */
`;
export const DurationContainer = styled(FlexRow)`
  align-items: center;
  ${({ theme }) => css`
    margin-left: auto;

  `}/* @media ${device.tablet} {
    padding: 1rem 0.5rem;
  } ; */
`;

export const SmylorTimeSlotCardTitle = ({ duration }: any) => {

  return (
    <TitleContainer>
      <DurationContainer>
        <DurationCalculator duration={duration} durationType='minutes' />
      </DurationContainer>
    </TitleContainer>
  );
};

