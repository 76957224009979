export const GET_REVIEW_DATA = "GET_REVIEW_DATA";
export const getReviewData = (payload) => ({ type: GET_REVIEW_DATA, payload });

export const SET_REVIEW_DATA = "SET_REVIEW_DATA";
export const setReviewData = (payload) => ({ type: SET_REVIEW_DATA, payload });

export const SUBMIT_DENTIST_REVIEW_DATA = "SUBMIT_DENTIST_REVIEW_DATA";
export const setDentistReviewData = (payload) => ({ type: SUBMIT_DENTIST_REVIEW_DATA, payload });

export const UPDATE_SUBMIT_DENTIST_REVIEW_DATA = "UPDATE_SUBMIT_DENTIST_REVIEW_DATA";
export const updateDentistReviewData = (payload) => ({ type: UPDATE_SUBMIT_DENTIST_REVIEW_DATA, payload });
