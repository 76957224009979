export const AvailabilityCheckType = {
    EMAIL: 1,
    ADDRESS: 2,
    HOTLINE: 3,
    POSTALCODE: 4
}

export const FormInputTypes = {
    EMAIL: 0,
    ADDRESS: 1,
    HOTLINE: 2,
    POSTALCODE: 3
}

export const AvailabilityInputCheckType = {
    EMAIL: 'email',
    ADDRESS: 'webAddress',
    HOTLINE: 'mobilenumber',
    POSTALCODE: 'postalcode',
}
