import { device } from "@core-const";
import styled, { css } from "styled-components";
import { SmylorSmallButton } from "../components_v3";

export const LinkSmall = styled.a`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-decoration: unset;
    cursor: pointer;

    &:hover,
    &:active {
      color: #fff;
    }
  `}
`;

export const LinkButton = styled.button`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  border: 0;
  font-weight: 500;
  transition: 0.3s;
  text-transform: uppercase;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
  border-radius: 6px;
  text-decoration: unset;
  line-height: 2;
  ${({ theme, light }) => css`
    background-color: ${light ? "#ffffff" : theme["thm-base"]};
    color: ${light ? theme["thm-base"] : "#ffffff"} !important;
    font-size: ${theme["thm-font-sm"]};
    padding: ${theme["thm-space-lg"]} ${theme["thm-space-xl"]};
  `};

  ${({ theme, pinkBlue }) => css`
    background-color: ${pinkBlue && theme["smylor-pink"]};
    font-size: ${pinkBlue && theme["thm-font-sm"]};
  `};

  &::before {
    content: "";
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 0;
    border-radius: 0;
    z-index: -1;
    transition: 0.3s;
    ${({ theme, light }) => css`
      background-color: ${light
      ? "rgba(0, 0, 0, 0.1)"
      : theme["thm-base-hover"]};
    `}
    ${({ theme, pinkBlue }) => css`
      background-color: ${pinkBlue && theme["header-blue"]} !important;
    `}
  }

  &::hover {
    color: #fff;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
    outline: none;
  }

  &:hover:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  :hover {
    ${({ theme, pinkBlue }) => css`+
      transition: .3s;
      background-color: ${pinkBlue && theme["header-blue"]} !important;
    `}
  }
  svg {
    ${({ theme }) => css`
      padding-left: ${theme["thm-space-sm"]};
      padding-right: ${theme["thm-space-md"]};
    `}
  }
`;

export const LinkButtonSmall = styled(SmylorSmallButton)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]} ${theme["thm-space-xl"]};
    margin: 0 ${theme["thm-space-md"]};

    @media ${device.mobileS} {
      padding: ${theme["thm-space-md"]} ${theme["thm-space-lg"]};
    }
  `}
`;

export const LinkBorderButton = styled(LinkButtonSmall)`
  height: fit-content;
  box-shadow: none;
  ${({ theme }) => css`
    background: ${theme["thm-light"]};
    padding: ${theme["thm-space-sm"]} ${theme["thm-space-lg"]} !important;
    color: ${theme["thm-base-hover"]} !important;
    border: 1px solid ${theme["thm-base-hover"]};
    :hover {
      color: ${theme["thm-light"]} !important;
    }
  `};
`;

export const LinkButtonXSmall = styled(LinkButton)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-sm"]} ${theme["thm-space-lg"]};
    margin: 0 ${theme["thm-space-md"]};
  `}
`;
