import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => css`
    font-size: ${theme['height-iconMedium']};
    width: ${theme['height-iconMedium']};
    height: ${theme['height-iconMedium']};
    color: ${theme['color-text']};
    svg {
      font-size: inherit;
    }
  `}

  ${({ theme, small }) =>
    small &&
    css`
      font-size: ${theme['height-iconSmall']};
      width: ${theme['height-iconSmall']};
      height: ${theme['height-iconSmall']};
    `}

    ${({ right }) =>
    right &&
    css`
      margin-left: auto;
    `}
`;
