import { device } from "@core-const";
import { FlexCenter } from "app-styled/flex";
import { FC } from "react";
import styled, { css } from "styled-components";

interface SmylorPageBreakerProps {
    smylorBlue?: boolean;
};

const PageBreakerCenterWrapper = styled(FlexCenter)`
  ${({ theme }) => css`
    padding-top: ${theme["thm-space-xl"]};
    padding-bottom: ${theme["thm-space-lg"]};
    @media ${device.laptop} {
      padding-top: ${theme["thm-space-lg"]};
    }
  `};
`;
const PageBreakerWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]} 0;
  `};
`;

const PageBreaker = styled.div<SmylorPageBreakerProps>`
  ${({ theme, smylorBlue }) => css`
    background-color: ${smylorBlue ? theme["smylor-light-blue"] : theme["smylor-white"]};
    height: 5px;
    width: 50px;
  `};
`;

export const SmylorPageCenterBreaker: FC<SmylorPageBreakerProps> = ({ smylorBlue }) => {
    return <PageBreakerCenterWrapper><PageBreaker smylorBlue={smylorBlue} /></PageBreakerCenterWrapper>
}

export const SmylorPageBreaker: FC<SmylorPageBreakerProps> = ({ smylorBlue }) => {
    return <PageBreakerWrapper><PageBreaker smylorBlue={smylorBlue} /></PageBreakerWrapper>
}