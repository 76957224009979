import React, { memo } from "react";
import loadable from "@loadable/component";
import { Message } from "@localization";
import * as SC from "./signin.styled";

const SigninForm = loadable(
  () => import(/*webpackChunkName: "pkg_____signin"*/ "./singninForm")
);
const Footer = loadable(
  () => import(/*webpackChunkName: "pkg_____signin"*/ "../../modules/footer")
);

const SigninPage = () => {
  return (
    <>
      <SC.SigninWrapper>
        <SC.Welcome>
          <Message
            messageKey={"welcome_to_the_smylor"}
            defaultMessage={"Welcome to the smylor"}
          />
        </SC.Welcome>
        <SC.SigninContainer>
          <SC.SigninPageColumnBody>
            <SC.SigninFormTitle bold>
              <Message messageKey={"register"} defaultMessage={"Register"} />
            </SC.SigninFormTitle>
            <SC.SigninFormBody>
              <SigninForm />
            </SC.SigninFormBody>
          </SC.SigninPageColumnBody>
          <SC.BackImageSignin image={"signin"}></SC.BackImageSignin>
        </SC.SigninContainer>
      </SC.SigninWrapper>
      <SC.FooterContainer>
        <Footer />
      </SC.FooterContainer>
    </>
  );
};

export default memo(SigninPage);
