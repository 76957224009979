
import { SmylorLoader } from 'app-core-img';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrganizationId } from '../profile/profile.actions';
import { getDentistOranizationIDFromReducer } from '../profile/profile.selector';

const loaderStyle = {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
};

const DentistProfileNavigator = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const organizationID = useSelector(getDentistOranizationIDFromReducer);

    const dispatch = useDispatch();
    useEffect(() => {
        id && dispatch(getOrganizationId(id));
    }, []);

    useEffect(() => {
        organizationID && navigate(`/profile/${organizationID}`);
    }, [organizationID]);

    return (
        <div id="loader-booking" style={loaderStyle} >
            <video autoPlay={true} loop muted playsInline={true}>
                <source src={SmylorLoader} type="video/mp4" />
            </video>
        </div>
    )
}

export default DentistProfileNavigator;