import { CoreReducer } from "@core-interface";
import {
  SET_MAP_LOADED,
  SET_FILTER,
  SET_BREADCRUMB,
  SET_LOCALIZATION_DATA,
  SET_LOCALIZATION_LANGUAGE,
  SET_GEO_LOCATION,
  ADD_NOTIFICATION,
  SET_SETTINGS,
  SET_BUSSY,
} from "./core.actions";

const initState: CoreReducer = {
  mapLoaded: false,
  filter: {
    review: {
      practiceMin: 0,
      practiceMax: 0,
      treatmentMax: 0,
      treatmentMin: 0,
    },
    affordability: {
      priceMax: 0,
      priceMin: 0,
    },
    dentist: {
      distance: 0,
      nhsDoctor: null, 
      patientAcceptTypes: null 
    },
    searchText: null 
  },
  breadcrumb: [],
  localization: {
    selected: null,
    languages: {}
  },
  geoLocation: {},
  notificationMessage: null,
  settings: {},
  bussy: false
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_MAP_LOADED:
      return {
        ...state,
        mapLoaded: payload,
      };

    case SET_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...payload },
      };

    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumb: payload,
      };

    case SET_LOCALIZATION_DATA:
      return {
        ...state,
        localization: { ...state.localization, languages: payload },
      };

    case SET_LOCALIZATION_LANGUAGE:
      return {
        ...state,
        localization: { ...state.localization, selected: payload },
      };

    case SET_GEO_LOCATION:
      return {
        ...state,
        geoLocation: payload,
      }

    case ADD_NOTIFICATION:
      return {
        ...state,
        notificationMessage: payload,
      }

    case SET_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...payload },
      }

    case SET_BUSSY:
      return {
        ...state,
        bussy: payload,
      }

    default:
      break;

  }
  
  return state;
};

export default reducer;
