import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FlexCol, FlexRow } from "app-styled/flex";
import styled, { css } from "styled-components";

const IconWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-lg"]} 0;
    .fa-primary {
      fill: ${theme["smylor-blue-darken-D"]} !important;
      opacity: unset;
    }
    .fa-secondary {
      fill: ${theme["smylor-light-blueA"]} !important;
      opacity: unset;
    }
  `}
`;

const IconRenderWrapper = styled(FlexCol)`
  ${({ theme }) => css`
    justify-content: center;
    align-items: center;
    gap: ${theme["thm-space-lg"]};
  `}
`;

const SmylorIconRender = ({ children, iconData }: any) => {
  return (
    <IconRenderWrapper>
      <IconWrapper>
        <FontAwesomeIcon
          icon={iconData.icon}
          bounce={iconData.bounce}
          style={iconData.style}
          size={iconData.size}
        />
      </IconWrapper>
      {children}
    </IconRenderWrapper>
  )
}

export { SmylorIconRender };