import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { getLocalizationLanguage, getLocalizationList } from './localization.selector';
import { ignoreElements } from "rxjs";

interface MessageProps {
    messageKey: string;
    defaultMessage: string;
    args?: string[],
    isHtml?: boolean
};

export const Message = (props: MessageProps) => {

    const language = useSelector(getLocalizationLanguage);
    const languageList = useSelector(getLocalizationList);
    const localization = useLocalization();

    const renderMessage = useCallback(() => {
        let text = localization.toLanguageString(props.messageKey, props.defaultMessage);
        if (props.args && props.args.length > 0) {
            text = formatString(text, props.args);
        }
        return text
    }, [language, props.messageKey, JSON.stringify(languageList)]);

    return (
        props.isHtml ? <span dangerouslySetInnerHTML={{ __html: renderMessage() }}>
        </span> :
            <span>
                {renderMessage()}
            </span>
    );
};

export const formatString = (string, ...args) => {
    return string.replace(/{(\d+)}/g, (match, index) =>
        args[index] != null ? args[index] : match,
    );
};