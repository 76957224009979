import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, mergeMap } from "rxjs/operators";
import { httpPost } from "@core-http";
import { of } from "rxjs";
import { addNotification } from "@core-action";
import { NotificationType } from "@core-interface";
import {
  GET_DATA,
  SET_REFERRAL_DENTIST_DATA,
  setSearchData,
} from "./results.actions";

export const epicGetResults = (action$, state$) => {
  return action$.pipe(
    ofType(GET_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `dentist/SearchDentist`,
        data: payload,
      }).pipe(
        map(({ Data, Total }: any) =>
          setSearchData({ Data, page: payload.page, Total })
        )
      )
    )
  );
};

export const epicSetReferralDentist = (action$, state$) => {
  return action$.pipe(
    ofType(SET_REFERRAL_DENTIST_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `account/ReferralDentist`,
        data: payload,
      }).pipe(
        mergeMap(({ Success }: any) => {
          if (Success) {
            return of(
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_add_dentist_nearBy_you",
                message: `Successfully Add Dentist NearBy You`,
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_adding_the_dentist_nearBy_you",
                message: "Error Adding the Dentist NearBy You",
              })
            );
          }
        })
      )
    )
  );
};

const ResultEpic = combineEpics(epicGetResults, epicSetReferralDentist);

export default ResultEpic;
