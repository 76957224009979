import { ofType, combineEpics } from "redux-observable";
import { switchMap, mergeMap, map } from "rxjs/operators";
import { of } from "rxjs";
import { httpGet } from "@core-http";
import { addNotification } from "@core-action";
import { NotificationType } from "@core-interface";
import {
  GET_APOINTMENT_COMPLETE_DATA,
  GET_SHAREPOINT_APPOINMENT_DATA,
  setAppoinmentsCompleteData,
  setSharePointAppointmentData,
} from "./appointments.actions";

export const epicCompleteAppointmentsData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_APOINTMENT_COMPLETE_DATA),
    switchMap(({ payload }: any) =>
      httpGet({
        call: `treatmentrequests/Complete?treatmentRequestId=${payload}`,
      }).pipe(
        mergeMap(({ Success }: any) => {
          if (Success) {
            return of(
              setAppoinmentsCompleteData(payload),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_completed_treatment",
                message: "Successfully Completed Treatment",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_completing_treatment",
                message: "Error Completing Treatment",
              })
            );
          }
        })
      )
    )
  );
};

export const epicGetBookingData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_SHAREPOINT_APPOINMENT_DATA),
    switchMap(({ payload }: any) =>
      httpGet({
        call: `treatmentservice/GetAllBookings?clientId=${payload.clientId}&pageType=${payload.pageType}&treatmentType=${payload.treatmentType}&take=${payload.take}&page=${payload.page}`
      }).pipe(
        mergeMap(({ Success, Data, Total }: any) => {
          if (Success) {
            return of(setSharePointAppointmentData({ Data, Total }));
          } else {
            return of(setSharePointAppointmentData({}));
          }
        }),
      )
    )
  );
};

const AppointmentsEpic = combineEpics(epicCompleteAppointmentsData, epicGetBookingData);

export default AppointmentsEpic;
