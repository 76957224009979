
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React from 'react'
import { AcceptPopupBody, AcceptPopupWrapper, StyledAcceptPopup } from './popup.styled'

const SmylorPopup = ({ title, onClose, actionBar, children }) => {
    return (
        <StyledAcceptPopup title={title()} onClose={onClose} className="popup-custom-style">
            <AcceptPopupWrapper>
                <AcceptPopupBody>
                    {children}
                </AcceptPopupBody>
                <DialogActionsBar>
                    {actionBar()}
                </DialogActionsBar>
            </AcceptPopupWrapper>
        </StyledAcceptPopup>
    )
}

export default SmylorPopup