import loadable from "@loadable/component";
const LineLoader = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./lineLoader")
);
const MainSearch = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./mainSearch")
);
const EmergencyStatus = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./EmergencyStatus")
);
const EmergencyStatusIcon = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./EmergencyStatusIcon")
);
const ReviewStatus = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./ReviewStatus")
);
const NotificationMsg = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./NotificationMsg")
);
const DateDrawer = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./DateDrawer")
);
const DaySessionDrawer = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./DateSessionDrawer")
);
const SearchBox = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./searchBox")
);
const ShowMoreDataButton = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./ShowMoreDataButton")
);
const LoadingButton = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./LoadingButton")
);
const LoadingIndicator = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./LoadingIndicator")
);
const StyledRatingPrice = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./StyledPriceRating")
);
const SharePointStatusIcon = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./SharePointStatusIcon")
);
const SharepointStatusText = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./SharePointStatus")
);
const TreatmentReviewIcon = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./TreatmentIcon")
);
const TreatmentRatingDrawer = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./TreatmentRatingDrawer")
);
const ChoiseDentistDrawer = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./ChoiseDentistDrawer")
);
const StyledPRatingComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./StyledRatingComponent")
);
const RatingCountGenerator = loadable(
    () => import(/*webpackChunkName: "pkg_____shared"*/ "./RatingCountGenerator")
);

export {
    LineLoader,
    MainSearch,
    EmergencyStatus,
    EmergencyStatusIcon,
    ReviewStatus,
    NotificationMsg,
    DateDrawer,
    DaySessionDrawer,
    SearchBox,
    ShowMoreDataButton,
    StyledRatingPrice,
    SharePointStatusIcon,
    SharepointStatusText,
    TreatmentReviewIcon,
    TreatmentRatingDrawer,
    ChoiseDentistDrawer,
    LoadingIndicator,
    LoadingButton,
    StyledPRatingComponent,
    RatingCountGenerator
};