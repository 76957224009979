import { USER_ROLES } from '../constant/authTypes';
export interface AuthReducer {
    session?: Session;
    loading: boolean;
    validateEmailLoading?: boolean,
    loginStatus?: LOGIN_STATUS;
    emergencyResponse: any;
    successStatus: boolean;
    dentistCount: number;
    emailValidationDetails?: any;
    emailNotVerified?: boolean;
    notVerifiedEmail: string | undefined;
    linkgetLoading: boolean;
    verficationLinkGetStatus: boolean | undefined;
    authorizeUserFetching: boolean;
    fetchingUserDetails: boolean
    loginStatusCode: number | null;
};
export interface Session {
    accessToken?: string,
    id?: string,
    username?: string,
    role?: number,
    spDentistId?: string,
    userId?: string,
    sub: string,
    nickName: string,
    name: string,
    picture: string,
    updated_at: string,
    email: string,
    email_verified: boolean,
    latitude?: number,
    longitude?: number,
    loginType?: number,
    clientId?: number,
    bookingAppId? : number
};

export enum LOGIN_STATUS {
    NONE,
    INPROGRESS,
    LOGEDIN
};
export interface LoggedPatientData {
    Id: number,
    SpId: number,
    FirstName: string,
    LastName: string,
    Email: string,
    MobileNumber: number,
    Latitude: number,
    Longitude: number,
    Address: string,
    Location: string,
    RegularDentist: string,
    ProfileImage: string,
    EmailVerified: boolean,
    UserType: typeof USER_ROLES[keyof typeof USER_ROLES],
    AccessToken: string,
    ExpiresIn: number
};
export interface LoggedDentistData {
    Id: number,
    SpId: number,
    OrganizationId: string,
    LookupMultiOfferings: string,
    OrganizationLogo: string,
    Practice: string,
    LastName: string | null,
    FirstName: string,
    FullName: string,
    Longitude: number,
    Latitude: number,
    Email: string,
    ChatAppId: number,
    ClaimedOnPortal: boolean,
    BookingAppId: number,
    UserType: typeof USER_ROLES[keyof typeof USER_ROLES],
    EmailVerified: boolean,
    AccessToken: string,
    ExpiresIn: number
};
export interface LoggedAdminData {
    Id: string,
    Name: string | null,
    Email: string,
    ProfileImage: string,
    UserType: typeof USER_ROLES[keyof typeof USER_ROLES],
    AccessToken: string,
    ExpiresIn: number
};

export interface GetLoggedUser {
    Email: string;
    Password: string;
};

export interface LoggedDentistResponse {
    response : LoggedDentistData | null,
    status : number,
    email?: string
}
export interface LoggedPatientResponse {
    response : LoggedPatientData | null,
    status : number,
    email?: string
}
export interface LoggedAdminResponse {
    response : LoggedAdminData | null,
    status : number,
    email?: string
}