import styled, { css } from "styled-components";
import { device } from "@core-const";

export const TitleH1 = styled.h1`
  line-height: 1;
  color: #ffffff;
  ${({ theme }) => css`
    margin-bottom: ${theme["thm-space-lg"]};
    font-size: ${theme["thm-font-xl"]};
    font-family: ${theme["thm-font"]};
  `}

  @media ${device.tablet} {
    ${({ theme }) => css`
      margin-bottom: ${theme["thm-space-md"]};
      font-size: 50px;
    `}

    @media ${device.tablet} {
      ${({ theme }) => css`
        margin-bottom: ${theme["thm-space-md"]};
        font-size: 40px;
      `}
    }
  }
`;

export const TitleH2 = styled.h2`
  line-height: 1;

  font-size: 48px;
  ${({ theme, light }) => css`
    margin-bottom: ${theme["thm-space-md"]};
    font-family: ${theme["thm-font"]};
    color: ${light ? theme["thm-light"] : theme["thm-secondary"]};
  `}

  @media ${device.tablet} {
    ${({ theme }) => css`
      margin-bottom: ${theme["thm-space-md"]};
      font-size: 50px;
    `}

    @media ${device.tablet} {
      ${({ theme }) => css`
        margin-bottom: ${theme["thm-space-md"]};
        font-size: 40px;
      `}
    }
  }
`;

export const TitleH3 = styled.h2`
  line-height: 1;

  font-size: 48px;
  ${({ theme, light }) => css`
    margin-bottom: ${theme["thm-space-md"]};
    font-family: ${theme["thm-font"]};
    color: ${light ? theme["thm-light"] : theme["thm-secondary"]};
  `}

  @media ${device.tablet} {
    ${({ theme }) => css`
      margin-bottom: ${theme["thm-space-md"]};
      font-size: 50px;
    `}

    @media ${device.tablet} {
      ${({ theme }) => css`
        margin-bottom: ${theme["thm-space-md"]};
        font-size: 40px;
      `}
    }
  }
`;

export const TitleH4 = styled.h4`
  font-size: 28px;
  line-height: 1.3;
  ${({ theme }) => css`
    margin-bottom: ${theme["thm-space-md"]};
    font-size: ${theme["thm-font-lg"]};
    font-family: ${theme["thm-font"]};
    color: ${theme["thm-secondary"]};
  `}
`;

export const TitleH5 = styled.h5`
  font-size: 20px;
  line-height: 1.3;
  ${({ theme }) => css`
    margin-bottom: ${theme["thm-space-md"]};
    font-size: ${theme["thm-font-lg"]};
    font-family: ${theme["thm-font"]};
    color: ${theme["thm-secondary"]};
  `}
`;

export const Para = styled.p`
  ${({ theme }) => css`
    font-size: ${theme["thm-font-sm"]};
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme["thm-font-sm"]};
    font-style: italic;
  `}
`;
