import React from "react";
import Footer from "app-module/footer";
import * as SC from "./terms.styled";
import DeConditionContainer from "./deConditionContainer";
import EnConditionContainer from "./enConditionContainer";
import { useSelector } from "react-redux";
import { getLocalizationLanguage } from "app-module/localization/localization.selector";

const TermsPage = () => {
  const selectedLanguage = useSelector(getLocalizationLanguage);
  return (
    <SC.TermsWrapper>
      <SC.TermsContainer>
        {selectedLanguage === "De_ch" ? (
          <DeConditionContainer />
        ) : (
          <EnConditionContainer />
        )}
      </SC.TermsContainer>
      <Footer />
    </SC.TermsWrapper>
  );
};

export default TermsPage;
