import { device } from "@core-const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

export const StyledTreatmentTypeCircle = styled.div`
  width: 140px;
  height: 140px;
  cursor: pointer;

  ${({ theme }) => css`
    background-color: ${theme["smylor-white"]};
    padding: ${theme["thm-space-md"]};
    margin: ${theme["thm-space-md"]};

    @media ${device.laptop} {
      width: 110px;
      height: 110px;
    }
    @media ${device.tabletL} {
      width: 80px;
      height: 80px;
    }
  `}
`;

export const StyledTreatmentTypeCircleInner = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => css`
    border: 3px solid ${theme["smylor-pink"]};
    padding: ${theme["thm-space-lg"]};
    @media ${device.laptop} {
      border: 2px solid ${theme["smylor-pink"]};
      padding: ${theme["thm-space-rg"]};
    }
  `}
`;

export const TreatmentIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    color: #003265;
    .fa-secondary {
      fill: ${theme["smylor-pink"]} !important;
      opacity: unset;
    }
  `}
`;
