import React from 'react';
import * as SC from './privacy.styled';

const DeConditionContainer = () => {
    return (
        <SC.ConditionContainer>
            <SC.MainTitile>Datenschutzrichtlinie</SC.MainTitile>
            <SC.Titile>Einleitung</SC.Titile>
            <SC.ParaContainer>
                Der Datenschutz hat für Smylor (im Folgenden: "wir", "uns") einen besonders hohen Stellenwert. Wir sehen es als unsere vorrangige Aufgabe an, die Vertraulichkeit der uns von Ihnen zur Verfügung gestellten personenbezogenen Daten zu wahren und sie vor unberechtigtem Zugriff zu schützen. Daher wenden wir äußerste Sorgfalt und modernste Sicherheitsstandards an, um einen maximalen Schutz Ihrer persönlichen Daten zu gewährleisten.
                Mit den nachstehenden Informationen geben wir Ihnen einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten, die im Zusammenhang mit der Nutzung unserer über https://smylor.com/ zugänglichen Website (im Folgenden "Web") anfallen.
                Außerdem möchten wir Sie über Ihre datenschutzrechtlichen Rechte informieren. Wir verarbeiten Ihre personenbezogenen Daten stets im Einklang mit der Allgemeinen Datenschutzverordnung (nachfolgend "DSGVO"), dem Telekommunikations- und Telemediendatenschutzgesetz (nachfolgend "TTDSG") und allen anwendbaren landesspezifischen Datenschutzbestimmungen.
            </SC.ParaContainer>

            <SC.Titile>1 Verantwortlichkeit</SC.Titile>
            <SC.ParaSmallDetails>Der für die Verarbeitung Verantwortliche im Sinne der GDPR ist: Smylor Limited</SC.ParaSmallDetails>
            <SC.ParaSmallDetails>Adresse: 24 Rowan Park, Lismonaghan, Letterkenny, Co. Donegal, Irland F92 N7D1</SC.ParaSmallDetails>
            <SC.ParaSmallDetails>E-Mail: privacy@smylor.com</SC.ParaSmallDetails>
            <SC.ParaContainer>Website: https://smylor.com/</SC.ParaContainer>

            <SC.Titile>2 Datenschutzbeauftragter</SC.Titile>
            <SC.ParaSmallDetails>Sie können unseren Datenschutzbeauftragten wie folgt erreichen:</SC.ParaSmallDetails>
            <SC.ParaSmallDetails>Name: Patrick McGlinchey</SC.ParaSmallDetails>
            <SC.ParaContainer>Email: privacy@smylor.com</SC.ParaContainer>
            <SC.ParaContainer>Mit allen Fragen und Anregungen zum Datenschutz und zur Wahrnehmung Ihrer Rechte können Sie sich direkt an unseren Datenschutzbeauftragten wenden.
            </SC.ParaContainer>

            <SC.Titile>3 Begriffsbestimmung</SC.Titile>
            <SC.ParaSmallDetails>Diese Datenschutzrichtlinie basiert auf den Bestimmungen der GDPR. Zu Ihrer Erleichterung möchten wir einige wichtige Begriffe in diesem Zusammenhang erläutern:
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - Personenbezogene Daten: Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
            </SC.ParaContainer>
            <SC.ParaContainer>
                - Betroffene Person: Die betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
            </SC.ParaContainer>
            <SC.ParaContainer>
                - Verarbeitung: Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Speichern, die Organisation, das Ordnen, die Aufbewahrung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Benutzung, die Weitergabe durch Übermittlung, Verbreitung oder jede andere Form der Bereitstellung, den Abgleich oder die Verknüpfung sowie die Einschränkung, Löschung oder Vernichtung.
            </SC.ParaContainer>
            <SC.ParaContainer>
                - Empfänger: Ein Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, an die personenbezogene Daten weitergegeben werden, unabhängig davon, ob es sich um einen Dritten handelt oder nicht. Behörden, die personenbezogene Daten im Rahmen einer spezifischen Ermittlungsaufgabe nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erhalten können, gelten jedoch nicht als Empfänger.
            </SC.ParaContainer>
            <SC.ParaContainer>
                - Dritter: Ein Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem für die Verarbeitung Verantwortlichen, dem Auftragsverarbeiter und den Personen, die befugt sind, die personenbezogenen Daten unter der unmittelbaren Verantwortung des für die Verarbeitung Verantwortlichen oder des Auftragsverarbeiters zu verarbeiten.
            </SC.ParaContainer>
            <SC.ParaContainer>
                - Einwilligung: Die Einwilligung ist jede Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, die von der betroffenen Person für den konkreten Fall freiwillig, in Kenntnis der Sachlage und auf eindeutige Weise abgegeben wird und mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
            </SC.ParaContainer>

            <SC.Titile>4 Herkunft der personenbezogenen Daten</SC.Titile>
            <SC.ParaContainer>
                Wir können personenbezogene Daten auf die folgenden Arten erhalten:
            </SC.ParaContainer>

            <SC.Titile>4.1 Von Ihnen bereitgestellte Informationen</SC.Titile>
            <SC.ParaContainer>
                Sie haben die Möglichkeit, auf der Plattform Informationen über sich selbst anzugeben.
            </SC.ParaContainer>

            <SC.Titile>4.2 Automatisch gesammelte und generierte Daten</SC.Titile>
            <SC.ParaContainer>
                Wenn Sie unsere Website nutzen, erheben wir personenbezogene Daten über Sie.
            </SC.ParaContainer>

            <SC.Titile>
                5 Umfang, Zweck, Speicherdauer und ggf. Empfänger und Drittlandtransfer der jeweiligen Verarbeitung von personenbezogenen Daten
            </SC.Titile>

            <SC.Titile>
                5.1 Allgemeine Informationen
            </SC.Titile>
            <SC.ParaContainer>
                Im Folgenden geben wir Ihnen einen Überblick darüber, welche personenbezogenen Daten wir verarbeiten. Dazu erläutern wir, in welchem Umfang und zu welchen Zwecken. Darüber hinaus geben wir - soweit vorhanden - an, welche Drittanbieter wir nutzen, um Ihre Daten zu erhalten. Schließlich informieren wir Sie darüber, ob bei der jeweiligen Verarbeitung durch den Drittanbieter ein Drittstaatentransfer stattfindet.
                Die Angabe Ihrer persönlichen Daten ist stets freiwillig. Es kann jedoch sein, dass die jeweilige Funktionalität nur mit Ihren Angaben funktioniert.
                Ohne Ihre Einwilligung geben wir Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, dass dies gesetzlich erlaubt ist (z.B. weil es für die Vertragserfüllung notwendig ist).

            </SC.ParaContainer>

            <SC.Titile>
                5.2 Datenübermittlung in Drittländer
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wenn wir personenbezogene Daten zur Verarbeitung in ein Drittland übermitteln, stellen wir die Einhaltung der Art. 44 ff. GDPR. Das bedeutet, dass wir vor jeder Übermittlung personenbezogener Daten an Dritte in ein Land außerhalb der Europäischen Union ("EU") oder des Europäischen Wirtschaftsraums ("EWR") prüfen, ob ein angemessenes Schutzniveau gewährleistet ist.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Ein angemessenes Schutzniveau kann u.a. dadurch gewährleistet werden, dass ein Angemessenheitsbeschluss der EU-Kommission vorliegt, dass wir mit dem Empfänger Standard-Datenschutzklauseln abgeschlossen und weitere zusätzliche Maßnahmen ergriffen haben oder dass die Drittlandübermittlung aufgrund anderer in Art. 46 ff. GDPR. Beruht die Datenübermittlung auf Art. 46, 47 oder 49 Abs. 1 DSGVO, können Sie von uns eine Kopie der Garantien für das Bestehen eines angemessenen Datenschutzniveaus im Zusammenhang mit der Datenübermittlung oder einen Hinweis auf die Verfügbarkeit einer Kopie der Garantien erhalten. Kopien dieser Garantien können bei uns angefordert werden.
            </SC.ParaContainer>

            <SC.Titile>
                5.3 Löschung von Daten
            </SC.Titile>
            <SC.ParaContainer>
                Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Bestimmungen gelöscht, sobald ihre für die Verarbeitung zulässigen Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht mehr benötigt werden). Werden die Daten nicht gelöscht, weil sie für andere, gesetzlich zulässige Zwecke erforderlich sind, wird ihre Verarbeitung auf diese Zwecke beschränkt. Das heißt, die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Dies gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
            </SC.ParaContainer>

            <SC.Titile>
                5.4 Sicherheitsmaßnahmen
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wir treffen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau gemäß den gesetzlichen Vorgaben zu gewährleisten, wobei wir den Stand der Technik, die Implementierungskosten und die Art, den Umfang, die Umstände und die Zwecke der Verarbeitung sowie die unterschiedlichen Eintrittswahrscheinlichkeiten und das Ausmaß der Gefährdung der Rechte und Freiheiten natürlicher Personen berücksichtigen.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                Zu den Maßnahmen gehören die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit der Daten durch die Kontrolle des physischen und elektronischen Zugangs zu den Daten sowie des Zugangs zu, der Eingabe von, der Offenlegung von, der Sicherstellung der Verfügbarkeit von und der Trennung von Daten, die Sie betreffen. Darüber hinaus haben wir Verfahren eingerichtet, um die Ausübung der Rechte der betroffenen Personen, die Löschung von Daten und die Reaktion auf Datenkompromittierungen zu gewährleisten.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Darüber hinaus berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung oder Auswahl von Hardware, Software und Prozessen nach dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen. Weitere Informationen finden Sie hier:Data Security & Safety | Smylor-Dental Treatment Marketplaces.
            </SC.ParaContainer>

            <SC.Titile>
                5.5 Weitergabe von personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kann es vorkommen, dass die Daten an andere Stellen, Unternehmen, rechtlich selbständige Organisationseinheiten oder Personen übermittelt oder Ihnen gegenüber offengelegt werden. Empfänger dieser Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten sein, die in eine Web-App integriert werden. In diesen Fällen halten wir uns an die gesetzlichen Vorgaben und schließen mit den Empfängern der Daten entsprechende Verträge oder Vereinbarungen ab, die dem Schutz Ihrer Daten dienen.
            </SC.ParaContainer>

            <SC.Titile>
                6 Die Verarbeitung Ihrer personenbezogenen Daten
            </SC.Titile>
            <SC.Titile>
                6.1.1 Bereitstellung der Website
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wenn Sie unsere Website besuchen, werden automatisch Daten verarbeitet, die Ihr Browser an unseren Server übermittelt. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert (in sog. "Server-Logfiles"). Erfasst werden können die folgenden Daten:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Browsertyp und -version
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - verwendetes Betriebssystem
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Referrer URL (die zuvor besuchte Seite)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Hostname des zugreifenden Rechners
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Datum und Uhrzeit der Serveranfrage
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - IP-Adresse
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                Als Hosting-Provider nutzen wir die TelemaxX Telekommunikation GmbH, deren Server sich für unsere Zwecke in Deutschland und anderen EU-Ländern befinden.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Für unsere Zwecke in der Schweiz nutzen wir außerdem die Datasource AG, Bösch 69, CH-6331 Hünenberg in der Schweiz.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf Ihre Person. Zu den von uns verfolgten Zwecken gehören insbesondere:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - die Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - die Aufklärung von Missbrauchs- oder Betrugshandlungen,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - die Problemanalyse im Netz, und
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - die Bewertung der Systemsicherheit und -stabilität.
            </SC.ParaContainer>

            <SC.Titile>
                6.1.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaContainer>
                Die Rechtsgrundlage für die Datenverarbeitung ist unser berechtigtes Interesse im Sinne von Art. 6 (1) (f) GDPR. Wir haben ein überwiegendes berechtigtes Interesse daran, unseren Dienst technisch einwandfrei anbieten zu können.
            </SC.ParaContainer>

            <SC.Titile>
                6.1.4 Speicherdauer
            </SC.Titile>
            <SC.ParaContainer>
                Die Logfiles werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauch oder Betrug) für maximal 7 Tage gespeichert und dann gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, werden bis zur endgültigen Klärung des Sachverhalts aufbewahrt.
            </SC.ParaContainer>

            <SC.Titile>
                6.1.5 Empfänger von personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Wir setzen die folgenden Dienstleister ein: Smylor Data Sub-Processors
            </SC.ParaContainer>

            <SC.Titile>
                6.2 Registrierung in der Web-App/ Anlegen eines Benutzerkontos
            </SC.Titile>

            <SC.Titile>
                6.2.1 Umfang der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Um unsere Web-App nutzen zu können, müssen Sie sich zunächst registrieren. Dazu verarbeiten wir die folgenden personenbezogenen Daten:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - IP-Adresse (anonymisiert)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Benutzername (frei wählbar)
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - E-Mail Adresse
            </SC.ParaContainer>

            <SC.Titile>
                6.2.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaContainer>
                Der Zweck der Verarbeitung ist die Durchführung der Authentifizierung und die Verwaltung Ihres Benutzerkontos.
            </SC.ParaContainer>

            <SC.Titile>
                6.2.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaContainer>
                Die Rechtsgrundlage für die Datenverarbeitung ist die Erfüllung des mit Ihnen geschlossenen Vertrages im Sinne von Art. 6 (1) (b) GDPR.
                Bei der Nutzung des Single-Sign-On-Verfahrens mit einem Drittdienst ist die Rechtsgrundlage Ihre Einwilligung im Sinne von Art. 6 (1) (a) GDPR.
            </SC.ParaContainer>

            <SC.Titile>
                6.2.4 Speicherdauer
            </SC.Titile>
            <SC.ParaContainer>
                Wir löschen Ihre personenbezogenen Daten, die wir im Zusammenhang mit der Registrierung für die Web-App erheben, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist spätestens dann der Fall, wenn Sie Ihren Account gelöscht haben und ein Jahr verstrichen ist.
            </SC.ParaContainer>

            <SC.Titile>
                6.2.5 Empfänger der personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Wir verwenden die folgenden Empfänger: Smylor Data Sub-Processors
            </SC.ParaContainer>

            <SC.Titile>
                6.3 Nutzung der Plattform
            </SC.Titile>

            <SC.Titile>
                6.3.1 Umfang der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wir können Ihnen die Vorteile unserer Plattform zur Verfügung stellen, wenn bestimmte erforderliche personenbezogene Daten erhoben werden. Dazu gehören die folgenden personenbezogenen Daten:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - IP-Adresse (anonymisiert)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Datum und Uhrzeit des Abrufs sowie die Menge der übertragenen Daten und die Meldung, ob der Datenaustausch vollständig war.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Zeitzone
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Informationen zum Absturz der Web-App
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - Browsertyp und Betriebssystem
            </SC.ParaContainer>

            <SC.Titile>
                6.3.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Zu den Zwecken, die wir verfolgen, gehören insbesondere:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Technischer Betrieb der Website
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Sicherstellung eines reibungslosen Verbindungsaufbaus der Web-App,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Untersuchung von Missbrauchs- oder Betrugshandlungen,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Problemanalysen im Netz, sowie
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - die Bewertung der Systemsicherheit und -stabilität.
            </SC.ParaContainer>

            <SC.Titile>
                6.3.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaContainer>
                Die Rechtsgrundlage für die Datenverarbeitung ist unser berechtigtes Interesse im Sinne von Art. 6 (1) (f) GDPR. Wir haben ein überwiegendes berechtigtes Interesse daran, unseren Dienst technisch einwandfrei anbieten zu können.
            </SC.ParaContainer>

            <SC.Titile>
                6.3.4 Speicherdauer
            </SC.Titile>
            <SC.ParaContainer>
                Wir löschen Ihre personenbezogenen Daten, die wir im Zusammenhang mit der Nutzung erhoben haben, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist spätestens dann der Fall, wenn Sie Ihr Konto gelöscht haben und ein Jahr verstrichen ist.
            </SC.ParaContainer>

            <SC.Titile>
                6.3.5 Empfänger von personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Wir nutzen die folgenden Anbieter: Smylor Data Sub-Processors
            </SC.ParaContainer>

            <SC.Titile>
                6.4 Einen Termin buchen
            </SC.Titile>
            <SC.Titile>
                6.4.1 Umfang der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Sie haben die Möglichkeit, über unseren Dienst einen Termin zu buchen. Dazu verarbeiten wir die folgenden personenbezogenen Daten:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Vor- und Nachname (falls von Ihnen angegeben)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Geschlecht
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Geburtsdatum
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Telefon-Nummer
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - E-Mail Adresse
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Datum und Uhrzeit der Anfrage
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Behandlung
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Name des Arztes
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - Inhalt der Mitteilung
            </SC.ParaContainer>

            <SC.Titile>
                6.4.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaContainer>
                Wir verarbeiten Ihre Daten, um die Buchung eines Termins bei Ihrem Zahnarzt zu ermöglichen.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wenn Ihre Anfrage auf vorvertraglichen Maßnahmen oder einem bestehenden Vertrag mit uns beruht, ist die Rechtsgrundlage die Erfüllung des Vertrages und die Durchführung vorvertraglicher Maßnahmen gemäß Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Erfolgt Ihre Anfrage unabhängig von vorvertraglichen Maßnahmen oder bestehenden Verträgen mit uns, sind unsere überwiegenden berechtigten Interessen gemäß Art. 6 (1) (f) GDPR die Rechtsgrundlage dar. Wir haben ein überwiegendes berechtigtes Interesse daran, den Nutzern unserer Anwendung eine Möglichkeit zu geben, mit uns in Kontakt zu treten.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.4 Speicherdauer
            </SC.Titile>
            <SC.ParaContainer>
                Wir löschen Ihre personenbezogenen Daten, sobald sie für die Erreichung des Zwecks, zu dem sie erhoben wurden, nicht mehr erforderlich sind. Im Rahmen von Kontaktanfragen ist dies in der Regel dann der Fall, wenn sich aus den Umständen entnehmen lässt, dass die konkrete Angelegenheit abschließend bearbeitet wurde.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.5 Empfänger der personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Um Ihre Buchung zu ermöglichen, erhält der jeweilige Zahnarzt, bei dem Sie einen Termin vereinbart haben, Ihre oben genannten personenbezogenen Daten, um den Termin zu vereinbaren.
                Die Empfänger finden Sie hier: Smylor Data Sub-Processors
            </SC.ParaContainer>

            <SC.Titile>
                6.4.6 Support und Kontaktmöglichkeiten
            </SC.Titile>

            <SC.Titile>
                6.4.6.1 Umfang der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Sie haben die Möglichkeit, uns per E-Mail (privacy@smylor.com) zu kontaktieren.Im Rahmen der Kontaktaufnahme mit Ihnen und der Beantwortung Ihrer Anfrage verarbeiten wir folgende personenbezogene Daten:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Vor- und Nachname (falls von Ihnen angegeben)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - E-Mail Adresse
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Datum und Uhrzeit der Anfrage
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - Inhalt der Mitteilung
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaContainer>
                Wir verarbeiten Ihre Daten, um Ihre Anfrage und andere sich daraus ergebende Angelegenheiten zu beantworten.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.6.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wenn Ihre Anfrage auf vorvertraglichen Maßnahmen oder einem bestehenden Vertrag mit uns beruht, ist die Rechtsgrundlage die Erfüllung des Vertrages und die Durchführung vorvertraglicher Maßnahmen gemäß Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Erfolgt Ihre Anfrage unabhängig von vorvertraglichen Maßnahmen oder bestehenden Verträgen mit uns, sind unsere überwiegenden berechtigten Interessen gemäß Art. 6 (1) (f) GDPR die Rechtsgrundlage dar. Wir haben ein überwiegendes berechtigtes Interesse daran, den Nutzern unserer Anwendung eine Möglichkeit zu geben, mit uns in Kontakt zu treten.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.6.4 Speicherdauer
            </SC.Titile>
            <SC.ParaContainer>
                Wir löschen Ihre personenbezogenen Daten, sobald sie für die Erreichung des Zwecks, für den sie erhoben wurden, nicht mehr erforderlich sind. Im Rahmen von Kontaktanfragen ist dies in der Regel dann der Fall, wenn sich aus den Umständen entnehmen lässt, dass die konkrete Angelegenheit abschließend bearbeitet wurde.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.6.5 Empfänger der personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Die Empfänger finden Sie hier: Smylor Data Sub-Processors
            </SC.ParaContainer>

            <SC.Titile>
                66.4.7 Chatbot.4.7 Chatbot
            </SC.Titile>

            <SC.Titile>
                6.4.7.1 Umfang der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Sie haben die Möglichkeit, mit uns oder einem bei Smylor registrierten Zahnarzt über einen Chatbot auf unserer/unseren Website(s) zu kommunizieren.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Im Rahmen der Unterstützung der Chat-Konversation zwischen Ihnen und uns bzw. einem bei Smylor registrierten Zahnarzt können wir die folgenden personenbezogenen Daten verarbeiten:
            </SC.ParaContainer>
            <SC.ParaSmallDetails>
                - Vor- und Nachname (falls von Ihnen angegeben)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Geburtsdatum
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - E-Mail Adresse
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Datum und Uhrzeit der Anfrage
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - IP-Adresse (anonymisiert)
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - Inhalt der Mitteilung (einschließlich potenzieller persönlicher
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaContainer>
                Wir verarbeiten Ihre Daten, um Ihre Anfrage und andere sich daraus ergebende Angelegenheiten zu beantworten. Darüber hinaus können wir die von Ihnen zur Verfügung gestellten Daten zur automatischen Erstellung eines Profils verwenden, sofern Sie dem zustimmen.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.7.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wenn Ihre Anfrage auf vorvertraglichen Maßnahmen oder einem bestehenden Vertrag mit uns beruht, ist die Rechtsgrundlage die Erfüllung des Vertrages und die Durchführung vorvertraglicher Maßnahmen gemäß Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                Erfolgt Ihre Anfrage unabhängig von vorvertraglichen Maßnahmen oder bestehenden Verträgen mit uns, sind unsere überwiegenden berechtigten Interessen gemäß Art. 6 (1) (f) GDPR die Rechtsgrundlage dar. Wir haben ein überwiegendes berechtigtes Interesse daran, den Nutzern unserer Anwendung eine Möglichkeit zu bieten, mit uns in Kontakt zu treten.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Wenn Sie sich nicht als Smylor-Nutzer angemeldet haben, aber am Ende des Gesprächs in eine Registrierung einwilligen, ist unsere Rechtsgrundlage Art. 6 (1) a GDPR.
            </SC.ParaContainer>

            <SC.Titile>
                6.4.7.4 Speicherdauer
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wir löschen Ihre personenbezogenen Daten, sobald sie für die Erreichung des Zweckes, zu dem sie erhoben wurden, nicht mehr erforderlich sind. Im Rahmen von Chat-Konversationen ist dies in der Regel dann der Fall, wenn sich aus den Umständen entnehmen lässt, dass der jeweilige Sachverhalt abschließend bearbeitet wurde.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Nach Beendigung einer Konversation, wenn Sie nicht eingeloggt sind oder einer Registrierung bei Smylor zugestimmt haben, werden alle Konversationen als anonym behandelt und innerhalb von 7 Tagen gelöscht.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.5 Empfänger der personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Die Empfänger finden Sie hier: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8 Registrierung von Zahnarztpraxen
            </SC.Titile>
            <SC.Titile>
                6.4.8.1 Umfang der Verarbeitung
            </SC.Titile>
            <SC.ParaSmallDetails>
                Sie haben die Möglichkeit, mit uns per E-Mail Kontakt aufzunehmen (privacy@smylor.com). Im Rahmen Ihrer Anmeldung bei uns verarbeiten wir folgende personenbezogene Daten:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Vor- und Nachname (falls von Ihnen angegeben)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - E-Mail Adresse
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                - Datum und Uhrzeit der Anfrage
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                - Inhalt der Mitteilung
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.2 Zweck der Verarbeitung
            </SC.Titile>
            <SC.ParaContainer>
                Wir verarbeiten Ihre öffentlich zugängliche E-Mail-Adresse, um Sie im Rahmen des Registrierungsprozesses als rechtmäßige Zahnarztpraxis zu identifizieren. Diese Public Domain-E-Mail-Adresse wird für die Zwei-Faktor-Authentifizierung im Rahmen der Validierung des Registrierungsprozesses verwendet.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaSmallDetails>
                Wenn Ihre Anfrage auf vorvertraglichen Maßnahmen oder einem bestehenden Vertrag mit uns beruht, ist die Rechtsgrundlage die Erfüllung des Vertrages und die Durchführung vorvertraglicher Maßnahmen gemäß Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Erfolgt Ihre Anfrage unabhängig von vorvertraglichen Maßnahmen oder bestehenden Verträgen mit uns, sind unsere überwiegenden berechtigten Interessen gemäß Art. 6 (1) (f) GDPR die Rechtsgrundlage dar. Wir haben ein überwiegendes berechtigtes Interesse daran, den Nutzern unserer Anwendung eine Möglichkeit zu geben, mit uns in Kontakt zu treten.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.4 Speicherdauer
            </SC.Titile>
            <SC.ParaContainer>
                Wir löschen Ihre personenbezogenen Daten, sobald sie für die Erreichung des Zwecks, zu dem sie erhoben wurden, nicht mehr erforderlich sind. Im Rahmen von Kontaktanfragen ist dies in der Regel dann der Fall, wenn sich aus den Umständen entnehmen lässt, dass die konkrete Angelegenheit abschließend bearbeitet wurde.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.5 Empfänger der personenbezogenen Daten
            </SC.Titile>
            <SC.ParaContainer>
                Die Empfänger finden Sie hier: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                7 Ihre Rechte
            </SC.Titile>
            <SC.ParaContainer>
                In diesem Abschnitt informieren wir Sie über die Rechte, die Sie in Bezug auf die Verarbeitung Ihrer Daten haben. Den genauen Umfang des jeweils genannten Rechts entnehmen Sie bitte dem entsprechenden Artikel der Datenschutzgrundverordnung (DSGVO). Anfragen von Betroffenen sind grundsätzlich an uns oder unseren Datenschutzbeauftragten per E-Mail an privacy@smylor.com zu richten.
            </SC.ParaContainer>
            <SC.Titile>
                7.1 Recht auf Bestätigung
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.
            </SC.ParaContainer>
            <SC.Titile>
                7.2 Auskunft (Art. 15 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, von uns jederzeit unentgeltlich Auskunft über die über Sie gespeicherten personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.
            </SC.ParaContainer>
            <SC.Titile>
                7.3 Berichtigung (Art. 16 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, die Berichtigung Sie betreffender unzutreffender personenbezogener Daten zu verlangen. Sie haben auch das Recht zu verlangen, dass unvollständige personenbezogene Daten unter Berücksichtigung der Zwecke der Verarbeitung vervollständigt werden.
            </SC.ParaContainer>
            <SC.Titile>
                7.4 Löschung (Art. 17 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, die unverzügliche Löschung der Sie betreffenden personenbezogenen Daten zu verlangen, wenn einer der gesetzlich vorgesehenen Gründe vorliegt und soweit die Verarbeitung oder Speicherung nicht erforderlich ist.
            </SC.ParaContainer>
            <SC.Titile>
                7.5 Einschränkung der Verarbeitung (Art. 18 DS-GVO)7.5 Restriction of processing (Art. 18 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen erfüllt ist.
            </SC.ParaContainer>
            <SC.Titile>
                7.6 Datenübertragbarkeit (Art. 20 GDPR)
            </SC.Titile>
            <SC.ParaSmallDetails>
                Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Ferner haben Sie das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäß Art. 6 (1) (a) GDPR oder Art. 9 (2) (a) GDPR oder auf einen Vertrag gemäß Art. 6 (1) (b) GDPR beruht. Art. 6 (1) (b) GDPR und die Verarbeitung erfolgt mit Hilfe automatisierter Verfahren, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Darüber hinaus haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO das Recht, die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen zu übermitteln, soweit dies technisch machbar ist und sofern hierdurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
            </SC.ParaContainer>
            <SC.Titile>
                7.7 Widerspruch (Art. 21 GDPR)
            </SC.Titile>
            <SC.ParaSmallDetails>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund einer im öffentlichen Interesse liegenden Verarbeitung erfolgt, gemäß Art. 6 (1) (e) GDPR oder auf der Grundlage unseres berechtigten Interesses gemäß Art. 6 (1) (f) GDPR erfolgt.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                Wenn Sie widersprechen, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </SC.ParaContainer>
            <SC.Titile>
                7.8 Widerruf der datenschutzrechtlichen Einwilligung 7.8 Revocation of consent under data protection law
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, Ihre Einwilligung zur Verarbeitung von personenbezogenen Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.
            </SC.ParaContainer>
            <SC.Titile>
                7.9 Beschwerde bei einer Aufsichtsbehörde
            </SC.Titile>
            <SC.ParaContainer>
                Sie haben das Recht, sich bei einer für den Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung von personenbezogenen Daten zu beschweren.
            </SC.ParaContainer>
            <SC.Titile>
                8 Aktualität und Änderungen der Datenschutzerklärung
            </SC.Titile>
            <SC.ParaContainer>
                Diese Datenschutzerklärung ist aktuell gültig und hat den folgenden Stand: Juni 2023.
            </SC.ParaContainer>
        </SC.ConditionContainer>
    )
}

export default DeConditionContainer