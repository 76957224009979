import styled, { css } from "styled-components";
import { Title, ItemTitle } from "app-styled-components";
import { device } from "@core-const";
import { TitleH2, TitleH3, Label } from "app-styled/title";
import { FlexRow, FlexCol } from "app-styled/flex";
import { LinkButtonSmall, LinkButtonXSmall } from "app-styled/link";

export const ForgetPasswordFormContainer = styled(FlexCol)`
  ${({ theme }) => css`
    justify-content: space-between;
    gap: ${theme["thm-space-xl"]};

    @media ${device.tabletL} {
      gap: 0;
    }
    @media ${device.tablet} {
      padding: ${theme["thm-space-lg"]};
    }
  `}
`;

export const ForgetPasswordWrapper = styled(FlexCol)`
  margin-top: 80px;
  align-items: center;
  flex-grow: 1;
  ${({ theme }) => css`
    background-color: ${theme["thm-form"]};
    @media ${device.tablet} {
      margin-top: 147px;
    }
  `}
`;

export const ForgetPasswordContainer = styled(FlexRow)`
  overflow: hidden;
  ${({ theme }) => css`
    margin: ${theme["thm-space-xl"]};
    margin-top: 0px;
    background-color: ${theme["thm-light"]};
    border-radius: 10px;
    box-shadow: ${theme["smylor-pink"]} 0px 1px 2px 0px;
    @media ${device.tabletL} {
      flex-direction: column-reverse;
    }
    @media ${device.mobileL} {
      width: calc(100% - 32px);
      margin: ${theme["thm-space-lg"]};
    }
  `}
`;

export const Welcome = styled(TitleH2)`
  ${({ theme }) => css`
    margin: ${theme["thm-space-xl"]};
    text-align: center;
    @media ${device.mobileL} {
      margin: ${theme["thm-space-lg"]};
    }
  `}
`;

export const ForgetPasswordPageColumnBody = styled(FlexCol)`
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 600px;
  @media ${device.laptopL} {
    width: 500px;
  }

  @media ${device.tablet} {
    width: 400px;
  }

  @media ${device.mobileL} {
    width: 100%;
  }

  @media ${device.mobileS} {
    max-width: 300px;
  }
`;

export const ForgetPasswordFormTitle = styled(TitleH3)`
  ${({ theme }) => css`
    color: ${theme["smylor-dark-blue"]};
    background-color: ${theme["header-blue"]};
    padding: ${theme["thm-space-lg"]} ${theme["thm-space-xl"]};

    @media ${device.tablet} {
      font-size: 28px;
      padding: 24px ${theme["thm-space-lg"]};
    }
  `}
`;

export const BackImageForgetPassword = styled.div`
  width: 600px;
  height: auto;
  min-height: 500px;
  background-size: cover;

  ${({ theme, image }) => css`
    background-image: url("/assets/img/${image}.png");
  `}

  @media ${device.laptopL} {
    width: 500px;
  }

  @media ${device.laptop} {
    width: 400px;
    background-position-x: -50px;
  }

  @media ${device.tabletL} {
    display: none;
  }
`;

export const ButtonContainer = styled(FlexRow)`
  ${({ theme }) => css`
    justify-content: flex-end;
    padding-bottom: ${theme["thm-space-lg"]};
    @media ${device.tablet} {
    }

    @media ${device.mobileL} {
      margin-top: ${theme["thm-space-lg"]};
    }
  `}

  button {
    z-index: auto;
  }
`;

export const ForgetPasswordFormBody = styled.div`
  ${({ theme }) => css`
    padding: ${theme["padding-container"]};
    @media ${device.tablet} {
      padding: 0;
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    @media ${device.mobileS} {
    }
  `}
`;
export const LoadingWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 26px;
    right: -10px;
    /* padding-right: ${theme["padding-container"]}; */
    @media ${device.mobileL} {
      /* padding: 0 ${theme["thm-space-md"]}; */
    }
  `}
`;

export const FooterContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  /* width: calc(100% - 80px); */
  @media ${device.tablet} {
    margin: 0rem 1rem;
  }
  @media ${device.mobileL} {
    margin: 0rem 0.75rem;
  }
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme["thm-space-xl"]};
    @media ${device.mobileL} {
      margin: 0;
      padding-bottom: ${theme["thm-space-xl"]};
    }
  `}
`;
