import React from "react";
import styled, { css } from "styled-components";
import { FlexCol } from "app-styled/flex";
import SharepointCardTitle from "./titleWrapper";
import SharePointStatusIcon from "app-core/styled/components/shared/SharePointStatusIcon";
import { ReqTimeRender } from "../smylor/reqTimeRender";

export const CardWrapper = styled(FlexCol)`
  /* width: 100%; */
  ${({ theme }) => css`
    box-shadow: ${theme["smylor-light-blueA"]} 0 0 2px,
      ${theme["smylor-light-blueA"]} 0 6px 12px -8px;
    border-radius: ${theme["thm-space-md"]};
    margin-bottom: ${theme["thm-space-xl"]};
    gap: ${theme["thm-space-md"]};
  `}
`;

export const SharepointCardContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 20px;
  `}

`;
export const SharepointCardFooter = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-top: 1px solid ${theme["smylor-light-blueA"]};
    padding: ${theme["thm-space-lg"]} 20px;
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 14px;
    bottom: 60px;
  `}
`;

const SharepointCard = ({ children, status, reqTime }) => {

  return (
    <CardWrapper>
      <SharepointCardTitle status={status} />
      <SharepointCardContent>{children}
        <IconContainer>
        <SharePointStatusIcon status={status} />
        </IconContainer>
      </SharepointCardContent>
      <SharepointCardFooter>{ReqTimeRender(reqTime)}</SharepointCardFooter>
    </CardWrapper>
  );
};

export { SharepointCard };


