import { ofType, combineEpics } from "redux-observable";
import { switchMap, mergeMap, map } from "rxjs/operators";
import { of } from "rxjs";
import { httpGet, httpPost } from "@core-http";
import {
  UPDATE_EMERGENCY_REQUEST_DATA,
  GET_PATIENT_TREATMENT_REQUESTS_DATA,
  ACCEPT_SELECTED_EMERGENCY_TIME_SLOT,
  GET_PATIENT_TREATMENT_REQUEST,
  UPDATE_TREATMENT_REQUEST_DATA,
  GET_TREATMENT_REQUEST_DATA,
  GET_TREATMENT_REQUESTS_DATA,
  SELECTED_TREATMENT_TIME_SLOT,
  setEmergencyRequestsData,
  setTreatmentRequestsData,
  setEmergencyRequestData,
  setTreatmentRequestData,
  setUpadatedEmergencyRequestData,
  setUpadatedTreatmentRequestData,
  setEmergencyPatientRequestsData,
  setTreatmentPatientRequestsData,
  setEmergencyPatientRequestData,
  setTreatmentPatientRequestData,
  setSelectedTimeSlotEmergencyRequestData,
  setSelectedTimeSlotTreatmentRequestData,
  setAcceptedEmergencyTimeSlotRequestData,
  setAcceptedTreatmentTimeSlotRequestData,
  setAcceptEmergencySelectedTimeSlot,
  SET_UPDATE_TREATMENT_STATUS,
  getTreatmentRequestStatus,
} from "./myProfile.actions";

import { setProfileAppointmentsData } from "./appointments/appointments.actions";

import { addNotification } from "@core-action";
import { NotificationType } from "@core-interface";
import { requestPageTypes, treatmentTypes } from "@core-const";

export const epicGetdenstistRequests = (action$, state$) => {
  return action$.pipe(
    ofType(GET_TREATMENT_REQUESTS_DATA),
    switchMap(
      ({ payload: { spDentistId, pageType, treatmentType, take, page } }) =>
        httpGet({
          call: `dentist/GetTreatmentRequests?dentistId=${spDentistId}&pageType=${pageType}&treatmentType=${treatmentType}&take=${take}&page=${page}`,
        }).pipe(
          mergeMap(({ Data, Total }: any) => {
            if (pageType == requestPageTypes.EmrgencyRequest) {
              if (treatmentType === treatmentTypes.EMERGENCYREQUEST) {
                return of(setEmergencyRequestsData({ Data, Total, page }));
              } else if (treatmentType === treatmentTypes.TREATMENTREQUEST) {
                return of(setTreatmentRequestsData({ Data, Total, page }));
              }
            } else if (pageType == requestPageTypes.Appointment) {
              return of(setProfileAppointmentsData({ Data, Total, page }));
            }
          })
        )
    )
  );
};

export const epicUpdateEmergencyRequestData = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_EMERGENCY_REQUEST_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `dentist/AcceptEmergencyRequest`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }) => {
          if (Success) {
            return of(
              setUpadatedEmergencyRequestData(payload),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_time_slot_is_added",
                message: "Successfully time slot is Added",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_adding_the_time_slot",
                message: "Error adding the time slot",
              })
            );
          }
        })
      )
    )
  );
};

// Get Treatment Patient Request Data

export const epicGetTreatmentRequests = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PATIENT_TREATMENT_REQUESTS_DATA),
    switchMap(({ payload: { email, pageType, treatmentType, take, page } }) =>
      httpGet({
        call: `patient/GetTreatmentRequestsByEmail?email=${email}&pageType=${pageType}&treatmentType=${treatmentType}&take=${take}&page=${page}`,
      }).pipe(
        mergeMap(({ Data, Total }: any) => {
          if (pageType == requestPageTypes.EmrgencyRequest) {
            if (treatmentType === treatmentTypes.EMERGENCYREQUEST) {
              return of(setEmergencyPatientRequestsData({ Data, Total, page }));
            } else if (treatmentType === treatmentTypes.TREATMENTREQUEST) {
              return of(setTreatmentPatientRequestsData({ Data, Total, page }));
            }
          } else if (pageType == requestPageTypes.Appointment) {
            return of(setProfileAppointmentsData({ Data, Total, page }));
          }
        })
      )
    )
  );
};

export const epicGetPatientTreatmentRequest = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PATIENT_TREATMENT_REQUEST),
    switchMap(({ payload: { culture, treatmentRequestId, treatmentType } }) =>
      httpGet({
        call: `patient/GetTreatmentRequest?Culture=${culture}&treatmentRequestId=${treatmentRequestId}`,
      }).pipe(
        mergeMap(({ Data }: any) => {
          if (treatmentType === treatmentTypes.EMERGENCYREQUEST) {
            return of(setEmergencyPatientRequestData(Data));
          } else if (treatmentType === treatmentTypes.TREATMENTREQUEST) {
            return of(setTreatmentPatientRequestData(Data));
          }
        })
      )
    )
  );
};

export const epicPatientEmergencySelectedData = (action$, state$) => {
  return action$.pipe(
    ofType(SELECTED_TREATMENT_TIME_SLOT),
    switchMap(({ payload }) =>
      httpPost({
        call: `patient/TreatmentAcceptance`,
        data: payload,
      }).pipe(
        mergeMap(({ Success }: any) => {
          if (Success) {
            if (payload.treatmentType === treatmentTypes.EMERGENCYREQUEST) {
              return of(
                setSelectedTimeSlotEmergencyRequestData(payload),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: payload.IsSelected
                    ? "successfully_selected"
                    : "successfully_unSelected",
                  message: payload.IsSelected
                    ? "Successfully Selected"
                    : "Successfully UnSelected",
                })
              );
            } else if (
              payload.treatmentType === treatmentTypes.TREATMENTREQUEST
            ) {
              return of(
                setSelectedTimeSlotTreatmentRequestData(payload),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: payload.IsSelected
                    ? "successfully_selected"
                    : "successfully_unSelected",
                  message: payload.IsSelected
                    ? "Successfully Selected"
                    : "Successfully UnSelected",
                })
              );
            }
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_selecting_the_time_slot",
                message: "Error Selecting the time slot",
              })
            );
          }
        })
      )
    )
  );
};

// Accept Reject Emergency time slot dentist
export const epicAcceptEmergencySelectedData = (action$, state$) => {
  return action$.pipe(
    ofType(ACCEPT_SELECTED_EMERGENCY_TIME_SLOT),
    switchMap(({ payload }) =>
      httpPost({
        call: `dentist/CompleteEmergencyRequest`,
        data: payload,
      }).pipe(
        mergeMap(({ Success }: any) => {
          if (Success) {
            if (payload.treatmentType === treatmentTypes.EMERGENCYREQUEST) {
              return of(
                setAcceptedEmergencyTimeSlotRequestData(payload),
                setAcceptEmergencySelectedTimeSlot(Success),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: payload.IsAccepted
                    ? "successfully_accepted"
                    : "successfully_rejected",
                  message: payload.IsAccepted
                    ? "Successfully Accepted"
                    : "Successfully Rejected",
                })
              );
            } else if (
              payload.treatmentType === treatmentTypes.TREATMENTREQUEST
            ) {
              return of(
                setAcceptedTreatmentTimeSlotRequestData(payload),
                setAcceptEmergencySelectedTimeSlot(Success),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: payload.IsAccepted
                    ? "successfully_accepted"
                    : "successfully_rejected",
                  message: payload.IsAccepted
                    ? "Successfully Accepted"
                    : "Successfully Rejected",
                })
              );
            }
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_selecting",
                message: "Error Selecting",
              })
            );
          }
        })
      )
    )
  );
};

// treatment Data

export const epicGetTreatmentRequestData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_TREATMENT_REQUEST_DATA),
    switchMap(({ payload: { treatmentRequestId, dentistId, treatmentType } }) =>
      httpGet({
        call: `treatmentrequests/GetTreatmentRequestDetail?treatmentRequestId=${treatmentRequestId}&dentistId=${dentistId}`,
      }).pipe(
        mergeMap(({ Data }: any) => {
          if (treatmentType === treatmentTypes.EMERGENCYREQUEST) {
            return of(setEmergencyRequestData(Data));
          } else if (treatmentType === treatmentTypes.TREATMENTREQUEST) {
            return of(setTreatmentRequestData(Data));
          }
        })
      )
    )
  );
};

export const epicUpdateTreatmentRequestData = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_TREATMENT_REQUEST_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `dentist/AcceptTreatmentRequest`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }: any) => {
          if (Success) {
            return of(
              setUpadatedTreatmentRequestData(payload),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_time_slot_is_added",
                message: "Successfully time slot is Added",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_adding_the_time_slot",
                message: "Error adding the time slot",
              })
            );
          }
        })
      )
    )
  );
};

export const epicUpdateTreatmentStatus = (action$, state$) => {
  return action$.pipe(
    ofType(SET_UPDATE_TREATMENT_STATUS),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentrequests/UpdateStatus?dentistRequestId=${payload}`,
        data: payload,
      }).pipe(
        map(({ Data }: any) => getTreatmentRequestStatus(Data))
      )
    )
  );
};

const MyProfileEpic = combineEpics(
  epicGetdenstistRequests,
  epicUpdateEmergencyRequestData,
  epicGetTreatmentRequests,
  epicGetPatientTreatmentRequest,
  epicPatientEmergencySelectedData,
  epicAcceptEmergencySelectedData,
  epicGetTreatmentRequestData,
  epicUpdateTreatmentRequestData,
  epicUpdateTreatmentStatus
);

export default MyProfileEpic;
