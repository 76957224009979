import styled, { css } from "styled-components";
import { device } from "@core-const";
import { FlexRow, FlexCol, FlexCenter } from "app-styled/flex";
import { LinkSmall } from "app-styled/link";

export const FooterWrapper = styled(FlexCol)`
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  align-items: center;
  ${({ theme, noMargin }) => css`
    margin: ${noMargin && '0px'};
    background-color: ${theme["smylor-black"]};
    color: ${theme["smylor-white"]};
    @media ${device.tablet} {
      padding-bottom: 0;
      margin-left: -1rem;
      margin-right: -1rem;
      margin: ${noMargin && '0px'};
    }
    margin: ${noMargin && '0px'};

    @media ${device.mobileL} {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      margin: ${noMargin && '0px'};  
    }
  `}
`;

export const ContactContainer = styled(FlexRow)`
  ${({ theme }) => css`
    justify-content: space-between;
    padding: ${theme["thm-space-xl"]} 150px;
    max-width: 1440px;
    width: 100%;
    @media ${device.laptop} {
      margin: 0;
      padding: ${theme["thm-space-xl"]};
    }

    @media ${device.tablet} {
      padding: ${theme["thm-space-lg"]} ${theme["thm-space-lg"]};
      flex-direction: column;
      align-items: self-start;
    }
  `}
`;

export const FooterBreaker = styled(FlexRow)`
  ${({ theme }) => css`
    margin: 0 150px;
    max-width: calc(1440px - 300px);
    width: 100%;
    @media ${device.laptop} {
      max-width: calc(1024px - 60px);    
    }

  border-bottom: 1px solid ${theme["smylor-dark-grey"]};
  `}
`;

export const CopyrightsContainer = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme["smylor-normal-grey"]};
    padding: ${theme["thm-space-xl"]} 150px;
    padding-top: ${theme["thm-space-lg"]};
    margin-bottom: 0;
    width: 100%;
    max-width: 1440px;
    @media ${device.laptop} {
      padding: ${theme["thm-space-xl"]};
    }
    @media ${device.tablet} {
      gap: ${theme["thm-space-lg"]};
      font-size: 12px;
    }
    @media ${device.mobileL} {
      font-size: 12px;
      margin: ${theme["thm-space-md"]} 0;
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const SmylorLogo = styled.div`
  width: 380px;
  height: 130px;
  @media ${device.laptop} {
    width: 235px;
    height: 80px;
  }
  @media ${device.tabletL} {
    width: 100px;
    height: 40px;
  }
  @media ${device.tablet} {
    width: 235px;
    height: 80px;
  }
  ${({ theme }) => css``};
`;

export const ContactItem = styled(FlexRow)`
  justify-content: center;

  ${({ theme }) => css`
    @media ${device.tablet} {
      padding: ${theme["thm-space-lg"]} ${theme["thm-space-xl"]};
    }
    @media ${device.mobileL} {
      padding: ${theme["thm-space-lg"]} ${theme["thm-space-md"]};
    }
  `}
`;

export const ContactItemInfoContainer = styled(FlexCol)`
  align-items: self-start;
  justify-content: flex-start;

  ${({ theme }) => css``};
`;

export const ContactLabel = styled(FlexCenter)`
  font-weight: 500;
  font-size: 18px;
  ${({ theme }) => css`
    padding-bottom: ${theme["thm-space-rg"]};
  `}
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const ContactValue = styled(FlexRow)`
  font-size: 16px;

  ${({ theme }) => css`
    color: ${theme["smylor-normal-grey"]};

    @media ${device.tablet} {
      font-size: 14px;
    }
  `}
`;

export const LeftContainer = styled(FlexRow)`
  align-items: center;
`;

export const RightContainer = styled(FlexRow)`
  margin-left: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${({ theme }) => css`
    gap: ${theme["thm-space-lg"]};
    @media ${device.mobileL} {
      padding-top: ${theme["thm-space-md"]};
      margin-left: unset;
    }
  `};
`;

export const Separator = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme["margin-labelSmall"]};
  `}
`;

export const FooterLink = styled(LinkSmall)`
  ${({ theme }) => css`
    color: ${theme["smylor-normal-grey"]};
    :hover {
      /* color: ${theme["color-black"]}; */
    }
  `};
  font-size: inherit;
  font-weight: unset;
`;
