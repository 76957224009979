import { ResultReducer } from "@core-interface";
import {
  SET_DATA,
  GET_DATA,
  SET_BOOKNOW_TREATMENT_DATA,
} from "./results.actions";

const initState: ResultReducer = {
  list: [],
  loading: false,
  booknowPopup: {},
  total: 0,
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_DATA:
      return {
        ...state,
        list:
          payload.page === 1 ? payload.Data : [...state.list, ...payload.Data],
        total: payload.Total,
        loading: false,
      };
    case SET_BOOKNOW_TREATMENT_DATA:
      return {
        ...state,
        booknowPopup: payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
