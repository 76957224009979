import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { setActionBussy } from "./actions";
import { catchError, switchMap, tap } from "rxjs/operators";

const httpPostV2 = ({ call, data }) => {
  return httpCallv2("POST", call, data);
};

const httpGetV2 = ({ call }) => {
  return httpCallv2("GET", call);
};

const httpDeleteV2 = ({ call }) => {
  return httpCallv2("DELETE", call);
};

const httpPutV2 = ({ call, data }) => {
  return httpCallv2("PUT", call, data);
};

const httpCallv2 = (type, call, data = null) => {
  setActionBussy(true);

  let request_url = process.env.REACT_APP_SERVER_PATH;
  let headers: any = {
    "Access-Control-Allow-Origin": "*",
    // 'Access-Control-Allow-Origin': 'http://localhost:1096/api/',
    "Content-type": "application/json",
    "Access-Control-Max-Age": 7200,
  };

  let jsonData = null;
  if (data) {
    jsonData = JSON.stringify(data);
  }

  request_url = `${request_url}/${call}`;

  headers = {
    ...headers,
  };

  return ajax({
    url: request_url,
    crossDomain: true,
    method: type,
    responseType: "json",
    timeout: 0,
    headers,
    body: data ? jsonData : null,
    createXHR: function () {
      return new XMLHttpRequest();
    },
  }).pipe(
    tap((result) => setActionBussy(false)),
    switchMap((data) => of({ response: data.response, status: data.status })),
    catchError((error) => of({ response: error.response, status: error.status }))
  );
};

const apiStatus = {
  success: 200,
};
export { httpPostV2, httpGetV2, httpDeleteV2, httpPutV2, apiStatus };
