import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, groupBy, mergeMap } from 'rxjs/operators';
import { httpPost } from '@core-http';
import { of } from 'rxjs'
import { addNotification } from '@core-action';
import { NotificationType } from '@core-interface';
import {
    GET_CHECK_DENTIST_DATA,
    REGISTER_DENTIST_DATA,
    registerDentistDataSuccess,
    setChecDentistData,
} from './register.action';

export const epicRegisterDentist = (action$, state$) => {
    return action$.pipe(
      ofType(REGISTER_DENTIST_DATA),
      switchMap(({ payload }: any) =>
        httpPost({
          call: `account/UserSignup`,
          data: payload
        }).pipe(mergeMap(({ Success }: any) => {
          if (Success) {
            return of(
              // addNotification({ type: NotificationType.SUCCESS, messageKey: 'successfully_dentist_dccount_is_created', message: `Successfully Dentist Account is Created` }),
              registerDentistDataSuccess(true)
            );
          } else {
            return of(addNotification({ type: NotificationType.ERROR, messageKey: 'error_creating_dentist_account', message: 'Error Creating Dentist Account' }),
            registerDentistDataSuccess(false)
            );
          }
        }),
        )
      )
    );
  };

export const epicChackDentistData = (action$, state$) => {
    return action$.pipe(
        ofType(GET_CHECK_DENTIST_DATA),
        switchMap(({ payload }: any) =>
            httpPost({
                call: `dentist/CheckDentistDetails`,
                data: payload
            }).pipe(map(({ Success, Data }: any) => {
                if (Success) {
                    return (setChecDentistData(Data));
                } else {
                    console.log('Check Dentsit Availability false');
                    return (setChecDentistData({}))
                }
            }))
        )
    );
};

const RegisterEpic = combineEpics(
    epicRegisterDentist,
    epicChackDentistData
);

export default RegisterEpic;