import { device } from "@core-const";
import { Message } from "@localization";
import { DateDrawer } from "app-core/styled/components/shared";
import { FlexCol, FlexRow } from "app-styled/flex";
import React from "react";
import styled, { css } from "styled-components";

interface StartEndTimeRenderProps {
    duration: { stTime: string; edTime: string };
}

export const DurationWrapper = styled(FlexRow)`
  /* width: 100%; */
  ${({ theme }) => css`
    gap: ${theme["thm-space-md"]};
    flex-wrap: wrap;
    font-weight: 400;
  `}
`;

const StartEndTimeRender = ({ duration }: StartEndTimeRenderProps) => {
    return (
        <FlexCol>
            <DurationWrapper>
                <Message messageKey={"appoinment_date"} defaultMessage={"Appoinment Date"} /> :
                <DateDrawer date={duration.stTime} type="date" />
                <span>
                    <DateDrawer date={duration.stTime} type="time" />
                    &nbsp;
                    -
                    &nbsp;
                    <DateDrawer date={duration.edTime} type="time" />
                </span>
            </DurationWrapper>
        </FlexCol>
    );
};

export default StartEndTimeRender;
