import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, groupBy, mergeMap } from 'rxjs/operators';
import { httpGet, httpPost} from '@core-http';
import {
    GET_SERVICES_DATA,
    setProfileData
} from './services.actions';

export const epicGetServicesDetails = (action$, state$) => {
  return action$.pipe(
    ofType(GET_SERVICES_DATA),
    switchMap(({ payload }) => 
    httpGet({
        call: `treatmentservice/GetTreatmentsByPagePath?Culture=${payload.Culture}&PagePath=${payload.Path}`
      }).pipe(map(({Data}:any) => setProfileData(Data)))
    )
  );
};

const ServicesEpic = combineEpics(
    epicGetServicesDetails
);

export default ServicesEpic;
