
import loadable from "@loadable/component";

const AdminReviewListViewComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./adminReviews")
);
const AdminReviewComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./adminReview")
);


const AdminRoutes = () => {
  const routes = [
    {
      path: 'reviews',
      element: < AdminReviewListViewComponent />,
    },
    {
      path: 'reviews/:id',
      element: <AdminReviewComponent />,
    }
  ];
  return routes;
};

export default AdminRoutes;