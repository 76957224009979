import { DropDownList } from "@progress/kendo-react-dropdowns";
import styled, { css } from "styled-components";

export const SmylorDropDownList = styled(DropDownList)`
  background-color: inherit !important;
  width: auto;
  border: 1px solid !important;

  ${({ theme, colorType, full }) => css`
    border-color: ${colorType === "blue" && theme["smylor-blue"]} !important;
    border-color: ${colorType === "light-blue" && theme["smylor-light-blue"]} !important;
    border-color: ${colorType === "pink" && theme["smylor-pink"]} !important;
    color: ${colorType === "blue" && theme["smylor-blue"]} !important;
    color: ${colorType === "pink" && theme["smylor-pink"]}!important;
    padding: ${theme["thm-space-md"]} !important;
    width: ${full ? '100%' : 'auto'};
    :hover {
      background-color: inherit !important;
      border-color: ${colorType === "light-blue" && theme["smylor-light-blue"]} !important;
      border-color: ${colorType === "pink" && theme["smylor-pink"]} !important;
    }
    :focus{
        background-color: inherit !important;
        box-shadow: 0 0 0 2px ${colorType === "blue" && theme["smylor-blue"]} !important;
        box-shadow: 0 0 0 2px ${colorType === "pink" && theme["smylor-pink"]} !important;
        box-shadow: 0 0 0 1px ${colorType === "light-blue" && theme["smylor-light-blue"]} !important;
    }
    .k-dropdownlist:focus-within {
        background-color: inherit !important;
        box-shadow: 0 0 0 0.05rem ${colorType === "blue" && theme["smylor-blue"]} !important;
        box-shadow: 0 0 0 2px ${colorType === "light-blue" && theme["smylor-light-blue"]} !important;
        box-shadow: 0 0 0 0.05rem ${colorType === "pink" && theme["smylor-pink"]} !important;
    }
  `};
`;

// .k-input-inner{
//     width: 90px;
//     @media ${device.tablet} {
//     padding: 0 0 0 12px;
//     width:36px;
//     }
//   };

//   .k-input-button{
//     padding-left: 0px;
//   };

//   @media ${device.tablet} {
//     .k-input-button{
//       padding: 0px;
//     };
//     .k-picker{
//       border:none;
//     };
//     .k-picker-solid.k-hover, .k-picker-solid:hover{
//       background-color: inherit;
//       border-color: inherit;
//     };
//     .k-picker-solid.k-focus, .k-picker-solid:focus{
//       box-shadow: none;
//     };
//   }
