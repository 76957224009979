import React, { memo } from "react";
import loadable from "@loadable/component";
import { Message } from "@localization";

import * as SC from "./forgetPassword.styled";

const ForgetPasswordForm = loadable(
  () => import(/*webpackChunkName: "pkg_____signin"*/ "./forgetPasswordForm")
);
const Footer = loadable(
  () => import(/*webpackChunkName: "pkg_____signin"*/ "../../modules/footer")
);

const ForgetPasswordPage = () => {
  return (
    <>
      <SC.ForgetPasswordWrapper>
        <SC.Welcome>
          <Message
            messageKey={"welcome_to_the_smylor"}
            defaultMessage={"Welcome to the smylor"}
          />
        </SC.Welcome>
        <SC.ForgetPasswordContainer>
          <SC.ForgetPasswordPageColumnBody>
            <SC.ForgetPasswordFormTitle bold>
              <Message messageKey={"forget_password"} defaultMessage={"Forget Password"} />
            </SC.ForgetPasswordFormTitle>
            <SC.ForgetPasswordFormBody>
              <ForgetPasswordForm />
            </SC.ForgetPasswordFormBody>
          </SC.ForgetPasswordPageColumnBody>
          <SC.BackImageForgetPassword image={"signin"}></SC.BackImageForgetPassword>
        </SC.ForgetPasswordContainer>
      </SC.ForgetPasswordWrapper>
      <SC.FooterContainer>
        <Footer />
      </SC.FooterContainer>
    </>
  );
};

export default memo(ForgetPasswordPage);
