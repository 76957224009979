import { ReviewReducer } from "@core-interface";
import {
    GET_REVIEW_DATA,
    SET_REVIEW_DATA,
    UPDATE_SUBMIT_DENTIST_REVIEW_DATA,
} from './reviewCommon.actions';

const initState: ReviewReducer = {
    reviewData: [],
    loading: false
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_REVIEW_DATA:
            return {
                ...state,
                loading: true
            }
        case SET_REVIEW_DATA:
            return {
                ...state,
                reviewData: payload,
                loading: false
            };
        case UPDATE_SUBMIT_DENTIST_REVIEW_DATA:
            return {
                ...state,
                reviewData: { ...state.reviewData, ...payload },
            };
        default:
            break;
    }
    return state;
}

export default reducer;