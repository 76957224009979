import { RegisterReducer } from "@core-interface";
import {
    SET_CHECK_DENTIST_DATA,
    REGISTER_DENTIST_DATA,
    REMOVE_CHECK_DENTIST_DATA,
    GET_CHECK_DENTIST_DATA,
    REGISTER_DENTIST_DATA_SUCCESS,
    REGISTER_DENTIST_SUCCESS_RESET
} from './register.action';

const initState: RegisterReducer = {
    loading: false,
    dentistCreateSuccess: false,
    registerData: {},
    checkDentistLoading: {}
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REGISTER_DENTIST_DATA:
            return {
                ...state,
                loading: true,
            };
        case REGISTER_DENTIST_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                dentistCreateSuccess: payload
            };
        case REGISTER_DENTIST_SUCCESS_RESET:
            return {
                ...state,
                dentistCreateSuccess: false
            };
        case GET_CHECK_DENTIST_DATA:
            return {
                ...state,
                checkDentistLoading: { [payload.Key]: true }
            };
        case SET_CHECK_DENTIST_DATA:
            return {
                ...state,
                registerData: payload,
                checkDentistLoading: {}
            };
        case REMOVE_CHECK_DENTIST_DATA:
            return { ...initState };
        default:
            break;
    }
    return state;
}

export default reducer;