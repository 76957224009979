import loadable from '@loadable/component';
const DentistListSearch = loadable(
    () => import(/*webpackChunkName: "pkg_____common"*/ "./dentistListSearch")
);
const Loading = loadable(
    () => import(/*webpackChunkName: "pkg_____common"*/ "./loading")
);
const StyledRating = loadable(
    () => import(/*webpackChunkName: "pkg_____common"*/ "./rating")
);
const LocationSearch = loadable(
    () => import(/*webpackChunkName: "pkg_____common"*/ "./locationSearch")
);
const PhotoDrawer = loadable(
    () => import(/*webpackChunkName: "pkg_____common"*/ "./photoDrawer")
);
const Image = loadable(
    () => import(/*webpackChunkName: "pkg_____common"*/ "./lazyLoadImage")
);

export { DentistListSearch, Loading, StyledRating, LocationSearch, Image, PhotoDrawer }