import { ShadeColor, device } from "@core-const";
import { SmylorButtonProps } from "@core-interface";
import styled, { css } from "styled-components";


export const SmylorButton = styled.button<SmylorButtonProps>`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  border: 0;
  font-weight: 500;
  transition: 0.3s;
  text-transform: uppercase;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
  border-radius: 8px;
  text-decoration: unset;
  line-height: 2;
  height: 50px;
  
  ${({ theme, colorType }) => css`
    background-color: ${colorType === "light" && theme["smylor-white"]};
    background-color: ${colorType === "blue" && theme["smylor-blue"]};
    background-color: ${colorType === "pink" && theme["smylor-pink"]};
    background-color: ${colorType === "blue-a" && theme["smylor-light-blueA"]};
    background-color: ${colorType === "pink-a" && theme["smylor-pink-A"]};
    color: ${colorType === "light" ? theme["thm-base"] : theme["smylor-white"]};
    font-size: ${theme["thm-font-sm"]};
    padding: 0 ${theme["thm-space-lg"]};
  `};

  &::before {
    content: "";
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 0;
    border-radius: 0;
    z-index: -1;
    transition: 0.3s;
    ${({ colorType, theme }) => css`
      background-color: ${colorType === "light" && "rgba(0, 0, 0, 0.1)"};
      background-color: ${colorType === "blue" && theme["smylor-pink"]};
      background-color: ${colorType === "blue-a" && theme["smylor-pink-A"]};
      background-color: ${colorType === "pink" && theme["smylor-blue"]};
      background-color: ${colorType === "pink-a" && theme["smylor-light-blueA"]};
    `}
  }

  &::hover {
    color: #fff !important;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
    outline: none;
    ${({ colorType, theme }) => css`
      background-color: ${colorType === "light" && "rgba(0, 0, 0, 0.1)"};
      background-color: ${colorType === "blue" &&
    theme["smylor-pink"]} !important;
      background-color: ${colorType === "blue-a" &&
    theme["smylor-pink-A"]} !important;
      background-color: ${colorType === "pink" && theme["smylor-blue"]};
      background-color: ${colorType === "pink-a" && theme["smylor-light-blueA"]};
    `}
  }
    
  &:hover:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  svg {
    ${({ theme }) => css`
      padding-left: ${theme["thm-space-sm"]};
      padding-right: ${theme["thm-space-md"]};
    `}
  }
  ${({ outline, theme }) => css`
    border: ${outline && "1px solid"};
    background-color: ${outline && theme["smylor-white"]};
    color: ${outline === "blue" && theme["smylor-blue"]};
    border-color: ${outline === "blue" && theme["smylor-blue"]};
    border-color: ${outline === "pink" && theme["smylor-pink"]};
    color: ${outline === "pink" && theme["smylor-pink"]};
    &::before {
      background-color: ${outline === "pink" &&
    ShadeColor(theme["smylor-pink"], 150)};
      background-color: ${outline === "blue" &&
    ShadeColor(theme["smylor-blue"], 150)};
    }
    &::hover {
      background-color: ${outline === "pink" &&
    ShadeColor(theme["smylor-pink"], 150)};
      background-color: ${outline === "blue" &&
    ShadeColor(theme["smylor-blue"], 150)};
    }
    @media ${device.tabletL} {
      height: 40px;
      font-size: 12px;
    }
  `}
`;
export const SmylorLinkButton = styled.a<SmylorButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  border: 0;
  font-weight: 500;
  transition: 0.3s;
  text-transform: uppercase;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
  border-radius: 8px;
  text-decoration: unset;
  line-height: 2;
  height: 50px;
  
  ${({ theme, colorType }) => css`
    background-color: ${colorType === "light" && theme["smylor-white"]};
    background-color: ${colorType === "blue" && theme["smylor-blue"]};
    background-color: ${colorType === "pink" && theme["smylor-pink"]};
    background-color: ${colorType === "blue-a" && theme["smylor-light-blueA"]};
    background-color: ${colorType === "pink-a" && theme["smylor-pink-A"]};
    color: ${colorType === "light" ? theme["thm-base"] : theme["smylor-white"]};
    font-size: ${theme["thm-font-sm"]};
    padding: 0 ${theme["thm-space-lg"]};
  `};

  &::before {
    content: "";
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 0;
    border-radius: 0;
    z-index: -1;
    transition: 0.3s;
    ${({ colorType, theme }) => css`
      background-color: ${colorType === "light" && "rgba(0, 0, 0, 0.1)"};
      background-color: ${colorType === "blue" && theme["smylor-pink"]};
      background-color: ${colorType === "blue-a" && theme["smylor-pink-A"]};
      background-color: ${colorType === "pink" && theme["smylor-blue"]};
      background-color: ${colorType === "pink-a" && theme["smylor-light-blueA"]};
    `}
  }

  &::hover {
    color: #fff;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
    outline: none;
    ${({ colorType, theme }) => css`
      background-color: ${colorType === "light" && "rgba(0, 0, 0, 0.1)"};
      background-color: ${colorType === "blue" &&
    theme["smylor-pink"]} !important;
      background-color: ${colorType === "blue-a" &&
    theme["smylor-pink-A"]} !important;
      background-color: ${colorType === "pink" && theme["smylor-blue"]};
      background-color: ${colorType === "pink-a" && theme["smylor-light-blueA"]};
    `}
  }

  &:hover:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  svg {
    ${({ theme }) => css`
      padding-left: ${theme["thm-space-sm"]};
      padding-right: ${theme["thm-space-md"]};
    `}
  }
  ${({ outline, theme }) => css`
    border: ${outline && "1px solid"};
    background-color: ${outline && theme["smylor-white"]};
    color: ${outline === "blue" && theme["smylor-blue"]};
    border-color: ${outline === "blue" && theme["smylor-blue"]};
    border-color: ${outline === "pink" && theme["smylor-pink"]};
    color: ${outline === "pink" && theme["smylor-pink"]};
    &::before {
      background-color: ${outline === "pink" &&
    ShadeColor(theme["smylor-pink"], 150)};
      background-color: ${outline === "blue" &&
    ShadeColor(theme["smylor-blue"], 150)};
    }
    &::hover {
      background-color: ${outline === "pink" &&
    ShadeColor(theme["smylor-pink"], 150)};
      background-color: ${outline === "blue" &&
    ShadeColor(theme["smylor-blue"], 150)};
    }
    @media ${device.tabletL} {
      height: 40px;
      font-size: 12px;
    }
  `}
`;

export const SmylorSmallButton = styled(SmylorButton)`
  ${({ theme }) => css`
    padding: 0px ${theme["thm-space-lg"]};
    font-size: 12px;
    height: 40px;
    @media ${device.mobileL} {
      font-size: "thm-font-regular";
    }
  `}
`;

export const SmylorTextButton = styled(SmylorButton)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]} 24px;
    height: auto;
    background-color: inherit;
    color: ${theme["smylor-blue-darken-D"]};
    box-shadow: none;
    @media ${device.mobileL} {
      font-size: "thm-font-regular";
    }
  `}
  ${({ theme, light }) => light && css`
    color: ${theme["smylor-blue-darken-D"]};
    :hover{
      background-color: ${theme["smylor-light-blueD"]};
    }
    @media ${device.mobileL} {
      font-size: "thm-font-regular";
    }
  `}
  ${({ theme, dark }) => dark && css`
    color: ${theme["smylor-pink"]};
    :hover{
      background-color: ${theme["smylor-pink-E"]};
    }
    @media ${device.mobileL} {
      font-size: "thm-font-regular";
    }
  `}
  ${({ theme, full }) => full && css`
    width: 100%;
    background-color: ${theme["smylor-light-blueD"]};
  `}
`;



