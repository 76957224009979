import {dataModel} from '@core-interface';

import {
    Category1,
    Category2,
    Category3,
    Category4,
    Category5,
    Category6,
    Category7,
    Category8,
  } from '../../core/styled/imgs';
import {
  DentalBleaching,
  DentalBraces,
  DentalCheckup,
  DentalEmergencey,
  DentalHygiene,
  DentalImplants,
  DentalInvisalign,
  DentalVeneers,
  } from '../../core/styled/imgs';


export const categoryList : dataModel[] = [
    { id : 'emergency', label: "Emergency", img: Category1, desc: 'This Emergency Category' },
    { id : 'hygiene', label: "Hygiene", img: Category2, desc: 'This Hygiene Category' },
    { id : 'bleaching', label: "Bleaching", img: Category3, desc: 'This Bleaching Category' },
    { id : 'invisalign', label: "Invisalign", img: Category4, desc: 'This Invisalign Category' },
    { id : 'braces', label: "Braces", img: Category5, desc: 'This Braces Category' },
    { id : 'veneers', label: "Veneers", img: Category6, desc: 'This Veneers Category' },
    { id : 'implants', label: "Implants", img: Category7, desc: 'This Implants Category' },
    { id : 'checkup', label: "Checkup", img: Category8, desc: 'This Checkup Category' },
    ];
export const categoryPageList : any = [
    { id : 'emergency', label: "Emergency", img: DentalEmergencey, desc: 'This Emergency Category' },
    { id : 'hygiene', label: "Hygiene", img: DentalHygiene, desc: 'This Hygiene Category' },
    { id : 'bleaching', label: "Bleaching", img: DentalBleaching, desc: 'This Bleaching Category' },
    { id : 'invisalign', label: "Invisalign", img: DentalInvisalign, desc: 'This Invisalign Category' },
    { id : 'braces', label: "Braces", img: DentalBraces, desc: 'This Braces Category' },
    { id : 'veneers', label: "Veneers", img: DentalVeneers, desc: 'This Veneers Category' },
    { id : 'implants', label: "Implants", img: DentalImplants, desc: 'This Implants Category' },
    { id : 'checkup', label: "Checkup", img: DentalCheckup, desc: 'This Checkup Category' },
    ];
