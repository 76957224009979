import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, groupBy, mergeMap } from 'rxjs/operators';
import { httpGet, httpPost } from '@core-http';
import {
  UPDATE_PROFILE_DATA,
  GET_PROFILE_DATA,
  GET_REVIEW_PROFILE_DATA,
  setProfileData,
  setReviewProfileData,
  setUpdatedProfileData,
  GET_ORGANIZATION_ID,
  setOrganizationId,
  GET_PRACTICENAME_AVAILABILITY,
  setPracticeNameAvailability,
  GET_DENTIST_PROFILE_DATA,
} from './profile.actions';

export const epicGetResults = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PROFILE_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `dentist/getdentist`,
        data: payload
      }).pipe(map((result: any) => setProfileData(result)))
    )
  );
};

export const epicGetDentistProfileResults = (action$, state$) => {
  return action$.pipe(
    ofType(GET_DENTIST_PROFILE_DATA),
    switchMap(({ payload }) =>
      httpGet({
        call: `dentist/profile`,
      }).pipe(map((result: any) => setProfileData(result)))
    )
  );
};

export const epicGetReviewProfileData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_REVIEW_PROFILE_DATA),
    switchMap(({ payload }) =>
      httpGet({
        call: `TreatmentReview/GetReviews?dentistID=${payload}`
      }).pipe(map(({ Data }: any) => setReviewProfileData(Data)))
    )
  );
};

export const epicUpadateProfileResults = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_PROFILE_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `dentist/UpdateDentist`,
        data: payload
      }).pipe(map((result: any) => setUpdatedProfileData(payload)))
    )
  );
};

// get 

export const epicGetOrganizationId = (action$, state$) => {
  return action$.pipe(
    ofType(GET_ORGANIZATION_ID),
    switchMap(({ payload }) =>
      httpGet({
        call: `dentist/GetDentistByChatAppId?chatAppId=${payload}`,
      }).pipe(map((result: any) => setOrganizationId(result.Data)))
    )
  );
};

export const epicGetPracticeName = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PRACTICENAME_AVAILABILITY),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `dentist/ValidatePracticeName`,
        data: payload
      }).pipe(map(({ Data }: any) => setPracticeNameAvailability(Data)))
    )
  );
};

const ProfileEpic = combineEpics(
  epicGetResults,
  epicUpadateProfileResults,
  epicGetReviewProfileData,
  epicGetOrganizationId,
  epicGetPracticeName,
  epicGetDentistProfileResults
);

export default ProfileEpic;
