import React from 'react';
import * as SC from './terms.styled';

const EnConditionContainer = () => {
    return (
        <SC.ConditionContainer>
            <SC.MainTitile>Website Terms of Use</SC.MainTitile>
            <SC.Titile>BEFORE USING THIS WEBSITE, PLEASE READ THE TERMS OF USE SET FORTH BELOW.</SC.Titile>
            <SC.ParaContainer>
                These Terms of Use constitute a legally binding agreement made by and between Smylor Limited, a company registered in Ireland with the company number 630618, with its registered office at 24 Rowan Park, Lismonaghan, Letterkenny, Co. Donegal, Ireland F92 N7D1 and you, whether personally or on behalf of an entity ("you"). These Terms of Use govern your use of https://smylor.com so please read them carefully. Other terms and conditions apply to the purchase of our products.
            </SC.ParaContainer>
            <SC.ParaContainer>
                BY CLICKING THE "I AGREE" BUTTON OR ACCESSING OR USING ANY PART OF THIS WEBSITE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO COMPLY BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO COMPLY, DO NOT ACCESS OR USE THIS WEBSITE.
            </SC.ParaContainer>
            <SC.ParaContainer>
                INTERNET TECHNOLOGY AND THE APPLICABLE LAWS, RULES AND REGULATIONS CHANGE FREQUENTLY. ACCORDINGLY, WE RESERVE THE RIGHT TO MAKE CHANGES TO THESE TERMS OF USE AT ANY TIME. YOUR CONTINUED USE OF THE WEBSITE CONSTITUTES ASSENT TO ANY NEW PROVISIONS OF THIS TERMS OF USE THAT MAY BE POSTED.
            </SC.ParaContainer>
            <SC.Titile>1. NO REPRESENTATIONS OR WARRANTIES</SC.Titile>
            <SC.ParaContainer>
                The services provided on this website and the content, information, documents, graphics and images published at this website could include inaccuracies, typographical errors or other errors. We make no commitment, however, to update what is contained in this website. Furthermore, we reserve the right to temporarily, or permanently, modify, alter, discontinue or delete the same without prior notice.
            </SC.ParaContainer>
            <SC.ParaContainer>
                TO THE EXTENT PERMITTED BY LAW, THE SERVICES AND INFORMATION ON THIS WEBSITE ARE PROVIDED "AS IS," AND WITHOUT WARRANTY, AND ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, ARE HEREBY DISCLAIMED, AND YOU HEREBY WAIVE ALL SUCH WARRANTIES. THE INFORMATION CONTAINED ON OR PROVIDED THROUGH THIS WEBSITE IS INTENDED FOR GENERAL CONSUMER UNDERSTANDING AND EDUCATION. ANY ACCESS TO THIS SITE IS VOLUNTARY. WE WILL REGARD ALL ACCESS AS VOLUNTARY AND AT THE SOLE RISK OF THE USER. WE DO NOT REPRESENT OR WARRANT THAT THE WEBSITE OR ITS USE (I) WILL BE UNINTERRUPTED, (II) WILL BE FREE OF DEFECTS, INACCURACIES OR ERRORS, (III) WILL MEET YOUR REQUIREMENTS, OR (IV) WILL OPERATE IN THE CONFIGURATION OR WITH OTHER HARDWARE OR SOFTWARE YOU USE. YOU ARE RESPONSIBLE FOR ENSURING THAT ALL PERSONS WHO ACCESS OUR WEBSITE THROUGH YOUR INTERNET CONNECTION ARE AWARE OF THESE TERMS OF USE AND OTHER APPLICABLE TERMS AND CONDITIONS, AND THAT THEY COMPLY WITH THEM.
            </SC.ParaContainer>
            <SC.Titile>2. USE FOR CONSUMER AND EDUCATIONAL PURPOSES ONLY</SC.Titile>
            <SC.ParaContainer>
                This site and its services are (i) for consumer educational use and (ii) to facilitate the purchase of our products, only. Nothing contained in this site is or should be considered, or used as a substitute for, medical advice, diagnosis or treatment. This site and its services do not constitute the practice of any medical, nursing or other professional health care advice, diagnosis or treatment. We advise users to always seek the advice of a physician or other qualified health care provider with any questions regarding personal health or medical conditions. Never disregard, avoid or delay in obtaining medical advice from your doctor or other qualified health care provider because of something you have read on this website. If you have or suspect that you have a medical problem or condition, please contact a qualified health care professional immediately.
            </SC.ParaContainer>
            <SC.Titile>3. PRIVACY POLICY</SC.Titile>
            <SC.ParaContainer>
            The information that we obtain through your use of our website, is subject to our Privacy Policy. Our Privacy Policy contains terms and conditions that govern our collection and use of the information you provide use and our respective rights relative to that information. Please review our Privacy Policy before you use our website.
            </SC.ParaContainer>
            
            <SC.Titile>4 Origin of the personal data</SC.Titile>
            <SC.ParaContainer>
                We may obtain personal information in the following ways:
            </SC.ParaContainer>
            <SC.Titile>4.1 Information provided by you</SC.Titile>
            <SC.ParaContainer>
                You have the possibility to provide information about yourself on the platform.
            </SC.ParaContainer>
            <SC.Titile>4.2 Automatically collected and generated data</SC.Titile>
            <SC.ParaContainer>
                When you use our website, we collect personal data about you.
            </SC.ParaContainer>
            <SC.Titile>4. SECURITY </SC.Titile>
            <SC.ParaContainer>
            You may have an account and password. You are responsible for maintaining the confidentiality of your password and account information and are fully responsible for all conduct carried out under this password and account. We are not liable for any loss of confidentiality or for any damages arising from your failure to comply with this term. You will promptly report any unauthorised use of your password to us.
            </SC.ParaContainer>
            <SC.Titile>
            5. WE ARE NOT RESPONSIBLE FOR ANY CONTENT ON ANY WEBSITES WE LINK TO
            </SC.Titile>
            <SC.ParaContainer>
            Where our website contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.
            </SC.ParaContainer>
            <SC.ParaContainer>
            We have no control over the contents of those sites or resources.
            </SC.ParaContainer>
            <SC.ParaContainer>
            This website may include information and materials uploaded by other users of the site, including to bulletin boards and chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.
            </SC.ParaContainer>
            <SC.Titile>
            6. INTELLECTUAL PROPERTY; LIMITED USE OF CONTENT
            </SC.Titile>
            <SC.ParaContainer>
            As far as we are aware, we are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
            </SC.ParaContainer>
            <SC.ParaContainer>
            You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.
            </SC.ParaContainer>
            <SC.ParaContainer>
            You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
            </SC.ParaContainer>
            <SC.ParaContainer>
            Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.
            </SC.ParaContainer>
            <SC.ParaContainer>
            You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.
            </SC.ParaContainer>
            <SC.ParaContainer>
            If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
            </SC.ParaContainer>
            <SC.Titile>
            7. OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
            </SC.Titile>
            <SC.ParaContainer>
            Whether you are a consumer or a business user:
            </SC.ParaContainer>
            <SC.ParaContainer>
            We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
            </SC.ParaContainer>
            <SC.ParaContainer>
            Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any products to you, which will be set out separately in our [Purchase of Goods Terms & Conditions].
            </SC.ParaContainer>
            <SC.ParaContainer>
            If you are a business user:
            </SC.ParaContainer>
            <SC.ParaContainer>
            We exclude all implied conditions, warranties, representations or other terms that may apply to our website or any content on it.
            </SC.ParaContainer>
            <SC.ParaContainer>
            We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
            </SC.ParaContainer>
            <SC.ParaContainer>
            use of, or inability to use, our website; oruse of or reliance on any content displayed on our website.
            </SC.ParaContainer>
            <SC.ParaContainer>
            In particular, we will not be liable for:
            </SC.ParaContainer>
            <SC.Titile>
                5.5 Transfer of personal data
            </SC.Titile>
            <SC.ParaContainer>
                During our processing of personal data, it may happen that the data is transferred to other bodies, companies,
                legally independent organizational units, or persons or that it is disclosed to you. Recipients of this data
                may include, for example, service providers commissioned with IT tasks or providers of services and content
                that are integrated into a web app. In such cases, we comply with the legal requirements and conclude
                corresponding contracts or agreements with the recipients of the data that serve to protect your data.
            </SC.ParaContainer>
            <SC.Titile>
                6 The processing of your personal data
            </SC.Titile>
            <SC.Titile>
                6.1.1 Provision of the Website
            </SC.Titile>
            <SC.ParaSmallDetails>
                When you visit our website, data is automatically processed that your browser transmits to our server.
                This general data and information is stored in the server's log files (in so-called "server log files").
                The following can be collected:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Browser type and version
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Operating system used
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Referrer URL (previously visited website)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Host name of the accessing computer
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the server request
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                As a hosting provider we use TelemaxX Telekommunikation GmbH, whose servers are located in Germany for
                our German and other EU country purposes.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                We also use Datasource AG, Bösch 69, CH-6331 Hünenberg in Switzerland for our Swiss country purposes.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.2 Purpose of the processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                When using this general data and information, we do not draw any conclusions about you.
                The purposes pursued by us include in particular:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • the guarantee of a smooth connection set-up of the website,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • the clarification of acts of abuse or fraud,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • problem analysis in the network, and
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • the evaluation of system security and stability.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.3 Legal basis
            </SC.Titile>
            <SC.ParaContainer>
                The legal basis for data processing is our legitimate interest within the meaning of Art.
                6 (1) (f) GDPR. We have an overriding legitimate interest in being able to offer our service in a
                technically flawless manner.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                The log files are stored for security reasons (e.g., for the clarification of abuse or fraud) for a
                maximum of 7 days and then deleted. Data whose further retention is required for evidentiary purposes
                will be retained until the matter has been finally clarified.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                We use the following service providers: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.2 Registration in the web app/ Creation of a user account
            </SC.Titile>
            <SC.Titile>
                6.2.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                To use our web app, you must first register. Therefore, we process the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address (anonymized)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Username (freely selectable)
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • E-mail address
            </SC.ParaContainer>
            <SC.Titile>
                6.2.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                The purpose of the processing is to perform authentication and manage your user account.
            </SC.ParaContainer>
            <SC.Titile>
                6.2.3 Legal basis
            </SC.Titile>
            <SC.ParaContainer>
                The legal basis for the data processing is the fulfillment of the contract concluded with you within
                the meaning of Art. 6 (1) (b) GDPR. When using the single sign-on procedure with a third-party service,
                the legal basis is your consent within the meaning of Art. 6 (1) (a) GDPR.
            </SC.ParaContainer>
            <SC.Titile>
                6.2.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data that we collect in connection with the registration of the web app as soon as it is no longer required to achieve the purpose for which it was collected. This is the case at the latest when you have deleted your account and one year has passed.
            </SC.ParaContainer>
            <SC.Titile>
                6.2.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                We use the following recipients: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.3 Use of the platform
            </SC.Titile>
            <SC.Titile>
                6.3.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                We can provide you with the benefits of our platform if certain required personal data is collected. This includes the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address (anonymized)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the retrieval and the amount of data transferred and the message whether the data exchange was complete.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Time zone
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Web app crash information
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Browser type and operating system
            </SC.ParaContainer>
            <SC.Titile>
                6.3.2 Purpose of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                The purposes we pursue include, in particular:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Technical operation of the website
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Ensuring smooth connection establishment of the web app,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Investigation of acts of abuse or fraud,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Problem analyses in the network, as well as
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • the evaluation of system security and stability.
            </SC.ParaContainer>
            <SC.Titile>
                6.3.3 Legal basis
            </SC.Titile>
            <SC.ParaContainer>
                The legal basis for data processing is our legitimate interest within the meaning of Art. 6 (1) (f) GDPR. We have an overriding legitimate interest in being able to offer our service in a technically flawless manner.
            </SC.ParaContainer>
            <SC.Titile>
                6.3.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data that we collect in connection with use as soon as it is no longer necessary to achieve the purpose for which it was collected. This is the case at the latest when you have deleted your account and one year has passed.
            </SC.ParaContainer>
            <SC.Titile>
                6.3.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                We use the following providers: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4 Book an appointment
            </SC.Titile>
            <SC.Titile>
                6.4.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the possibility to book an appointment via our service. Therefore, we process the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Gender
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date of Birth
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Telephone Number
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Treatment
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Practioner Name
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content
            </SC.ParaContainer>
            <SC.Titile>
                6.4.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your data to enable the booking of an appointment with your dentist.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.3 Legal basis
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which they were collected. In the context of contact inquiries, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                To enable your booking, the respective dentist, you made an appointment with, receives your personal data as stated above, to fix the appointment.
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6 Support and contact options
            </SC.Titile>
            <SC.Titile>
                6.4.6.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                In the context of contacting, you and answering your inquiry, we process the following personal data: You have the possibility to contact us via e-mail (privacy@smylor.com).
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your data to respond to your inquiry and other matters arising from it.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.3 Legal basis
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which it was collected. In the context of contact inquiries, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7 Chatbot
            </SC.Titile>
            <SC.Titile>
                6.4.7.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the possibility to converse with us or a Smylor registered dentist via a chatbot on our website(s).
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                In the context of supporting the chat conversation between you and us, or a Smylor registered dentist, we may process the following personal data:
            </SC.ParaContainer>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date of Birth
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address (anonymized)
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content (including potential personal dental health information, which we will request additional permission to process)
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your data to respond to your inquiry and other matters arising from it. Additionally, if you consent to, we can use your provided data to create a profile automatically.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.3 Legal basis
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If you have not logged in as a Smylor user, but at the end of the conversation you consent to register, our legal basis is Art. 6 (1) a GDPR.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.4 Storage period
            </SC.Titile>
            <SC.ParaSmallDetails>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which it was collected. In the context of chat conversations, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                At the end of a conversation, if you have not logged in or agreed to register with Smylor, any conversations will be treated as anonymous and will be deleted within 7 days.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8 Dentist Practice Registration
            </SC.Titile>
            <SC.Titile>
                6.4.8.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the possibility to contact us via e-mail (privacy@smylor.com). In the context of you registering with us we process the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your public domain email address to identify you as a legitimate dental practice as part of the registration process. This public domain email address will be used for two factor authentication as part of validating the registration process.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which it was collected. In the context of contact inquiries, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                7 Your rights
            </SC.Titile>
            <SC.ParaContainer>
                In this section, we inform you about the rights you have regarding the processing of your data. The exact scope of the right mentioned in each case can be found in the corresponding article of the General Data Protection Regulation (GDPR). Data subject inquiries should generally be addressed to us or our data protection officer via e-mail to privacy@smylor.com.
            </SC.ParaContainer>
            <SC.Titile>
                7.1 Right to confirmation
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to request confirmation from us as to whether personal data concerning you is being processed.
            </SC.ParaContainer>
            <SC.Titile>
                7.2 Information (Art. 15 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to receive information from us at any time free of charge about the personal data stored about you, as well as a copy of this data in accordance with the statutory provisions.
            </SC.ParaContainer>
            <SC.Titile>
                7.3 Rectification (Art. 16 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to request the rectification of inaccurate personal data concerning you. You also have the right to request that incomplete personal data be completed, considering the purposes of the processing.
            </SC.ParaContainer>
            <SC.Titile>
                7.4 Erasure (Art. 17 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to demand that personal data concerning you be deleted without delay if one of the reasons provided for by law applies and insofar as the processing or storage is not necessary.
            </SC.ParaContainer>
            <SC.Titile>
                7.5 Restriction of processing (Art. 18 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to request that we restrict processing if one of the legal requirements is met.
            </SC.ParaContainer>
            <SC.Titile>
                7.6 Data portability (Art. 20 GDPR)
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the right to receive the personal data concerning you that you have provided to us in a structured, common, and machine-readable format. Furthermore, you have the right to transfer this data to another controller without hindrance by us, to whom the personal data was provided, provided that the processing is based on consent pursuant to Art. 6 (1) (a) GDPR or Art. 9 (2) (a) GDPR or on a contract pursuant to Art. 6 (1) (b) GDPR. Art. 6 (1) (b) GDPR and the processing is carried out with the aid of automated procedures, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in us.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                In addition, when exercising your right to data portability pursuant to Article 20 (1) GDPR, you have the right to have the personal data transferred directly from one controller to another controller, to the extent that this is technically feasible and provided that this does not adversely affect the rights and freedoms of other individuals.
            </SC.ParaContainer>
            <SC.Titile>
                7.7 Objection (Art. 21 GDPR)
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the right to object at any time, on grounds relating to your situation, to the processing of personal data concerning you which is carried out on the basis of data processing in the public interest pursuant to Art. 6 (1) (e) GDPR or on the basis of our legitimate interest pursuant to Art. 6 (1) (f) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of legal claims.
            </SC.ParaContainer>
            <SC.Titile>
                7.8 Revocation of consent under data protection law
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to revoke your consent to the processing of personal data at any time with effect for the future.
            </SC.ParaContainer>
            <SC.Titile>
                7.9 Complaint to a supervisory authority
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to lodge a complaint about our processing of personal data with a supervisory authority responsible for data protection.
            </SC.ParaContainer>
            <SC.Titile>
                8 Up-to-datedness and changes of the privacy policy
            </SC.Titile>
            <SC.ParaContainer>
                This privacy notice is currently valid and has the following status: June 2023.
            </SC.ParaContainer>
        </SC.ConditionContainer>
    )
}

export default EnConditionContainer