import React from 'react';
import Footer from 'app-module/footer';
import * as SC from './privacy.styled';
import { getLocalizationLanguage } from 'app-module/localization/localization.selector';
import { useSelector } from 'react-redux';
import DeConditionContainer from './deConditionContainer';
import EnConditionContainer from './enConditionContainer';

const PrivacyPage = () => {
    const selectedLanguage = useSelector(getLocalizationLanguage);
    return (
        <SC.PrivacyWrapper>
            <SC.PrivacyContainer>
                {selectedLanguage === 'De_ch' ?
                    <DeConditionContainer /> :
                    <EnConditionContainer />
                }
            </SC.PrivacyContainer>
            <Footer />
        </SC.PrivacyWrapper>
    )
}

export default PrivacyPage