import styled, { css } from 'styled-components';
import { Button } from "@progress/kendo-react-buttons";

export const SubmitButton = styled(Button)`
    margin-right: 1rem;
    ${({theme})=> css`
        background-color: ${theme['color-backgroundDark']}; 
        color: ${theme['color-light']};
        font-family: ${theme['font-bold']};
        &:hover{
        background-color: ${theme['color-fontDark']} !important;
        }
    `}
`;
