import { treatmentTypes } from "@core-const";
import { AppointmentsReducer } from "@core-interface";
import {
  GET_PATIENT_TREATMENT_REQUESTS_DATA,
  GET_TREATMENT_REQUESTS_DATA,
} from "../myProfile.actions";
import {
  SET_APOINTMENT_DATA,
  SET_APOINTMENT_COMPLETE_DATA,
  SET_SHAREPOINT_APPOINMENT_DATA,
  GET_SHAREPOINT_APPOINMENT_DATA,
  SET_SELECTED_VIEW
} from "./appointments.actions";

const initState: AppointmentsReducer = {
  appointmentsData: [],
  sharePointDataLoading: false,
  sharePointAppointmentsData: [],
  total: 0,
  selectedView: 0,
  sharePointTotal: 0,
  loading: false,
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SHAREPOINT_APPOINMENT_DATA:
      return {
        ...state,
        sharePointDataLoading: true,
      }
    case GET_TREATMENT_REQUESTS_DATA:
      return {
        ...state,
        loading: payload.treatmentType === treatmentTypes.APPOINMENTS && true,
      };

    case GET_PATIENT_TREATMENT_REQUESTS_DATA:
      return {
        ...state,
        loading: payload.treatmentType === treatmentTypes.APPOINMENTS && true,
      };

    case SET_APOINTMENT_DATA:
      return {
        ...state,
        appointmentsData:
          payload.page === 1
            ? payload.Data
            : [...state.appointmentsData, ...payload.Data],
        total: payload.Total,
        loading: false,
      };

    case SET_APOINTMENT_COMPLETE_DATA:
      return {
        ...state,
        appointmentsData: state.appointmentsData.map((data: any) => {
          if (data.TreatmentRequestId == payload) {
            return { ...data, Status: 6 };
          } else {
            return data;
          }
        }),
      };
    case SET_SHAREPOINT_APPOINMENT_DATA:
      return {
        ...state,
        sharePointAppointmentsData: payload?.Data ? [...state.sharePointAppointmentsData, ...payload.Data] : state.sharePointAppointmentsData,
        sharePointDataLoading: false,
        sharePointTotal: payload?.Total,
      };
    case SET_SELECTED_VIEW:
      return {
        ...state,
        selectedView: payload
      };
    default:
      break;
  }
  return state;
};

export default reducer;
