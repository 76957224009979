import { AuthReducer, LOGIN_STATUS } from '@core-interface';
import {
    SET_TOKEN,
    SET_USER,
    NEW_USER,
    SET_LOGING_STATUS,
    SET_EMERGENCY_USER,
    SET_SUCCESS_STATUS,
    GET_SUCCESS_STATUS,
    SET_DENTIST_COUNT,
    SET_VALIDATE_EMAIL,
    GET_VALIDATE_EMAIL,
    SET_EMAIL_VALIDATION_LINK,
    GET_EMAIL_VALIDATION_LINK,
    RESET_EMAIL_VERIFY_DETAILS,
    SUCCESS_STATUS_RESET,
    GET_DENTIST_DATA,
    GET_PATIENT_DATA,
    SET_PATIENT_DATA,
    SET_DENTIST_DATA,
    RESET_LOGIN_MODULE,
    GET_AUTHORIZE,
    GET_ADMIN_DATA,
    SET_ADMIN_DATA,
} from './auth.action';
import { setSession } from '@core-const';
import { StatusCode, USER_ROLES } from 'app-core/constant/authTypes';

const initState: AuthReducer = {
    session: null,
    loading: false,
    validateEmailLoading: false,
    loginStatus: LOGIN_STATUS.NONE,
    emergencyResponse: [],
    successStatus: false,
    dentistCount: 0,
    emailValidationDetails: {},
    emailNotVerified: false,
    notVerifiedEmail: undefined,
    linkgetLoading: false,
    verficationLinkGetStatus: undefined,
    authorizeUserFetching: false,
    fetchingUserDetails: false,
    loginStatusCode: null
};

const profilePhotoSetter = (picture: string): string => {
    if (picture) {
        return picture.split(',')[0];
    }
};

const sessionCreator = (payload) => {

    if (payload.UserType === USER_ROLES.DENTIST || payload.UserType === USER_ROLES.PARTICIPANT) {
        return {
            id: payload.ChatAppId,
            email: payload.Email,
            username: payload.FirstName ?? payload.LastName,
            nickName: payload.FirstName ?? payload.LastName,
            picture: profilePhotoSetter(payload?.OrganizationLogo),
            emailVerified: true,
            role: payload.UserType,
            accessToken: payload.AccessToken,
            loginType: payload.UserType,
            userId: payload.OrganizationId,
            claimedOnPortal: payload.ClaimedOnPortal,
            spDentistId: payload.Id,
            // lookupMultiOfferings: payload.LookupMultiOfferings,
            longitude: payload.Longitude,
            latitude: payload.Latitude,
            clientId: payload.ChatAppId,
            bookingAppId: payload.BookingAppId,
            expiresIn: payload.ExpiresIn
        }
    } else if (payload.UserType === USER_ROLES.ADMIN) {
        return {
            id: payload.Id,
            username: payload.Name,
            nickName: payload.Name,
            picture: profilePhotoSetter(payload?.ProfileImage),
            emailVerified: true,
            role: payload.UserType,
            accessToken: payload.AccessToken,
            loginType: payload.UserType,
            email: payload.Email,
            expiresIn: payload.ExpiresIn
        }
    }
    else {
        return {
            id: payload.Id,
            email: payload.Email,
            username: payload.FirstName,
            nickName: payload.FirstName,
            picture: profilePhotoSetter(payload?.ProfileImage),
            emailVerified: true,
            mobileNumber: payload.MobileNumber,
            location: payload.Location,
            regularDentist: payload.RegularDentist,
            address: payload.Address,
            role: 2,
            accessToken: payload.AccessToken,
            loginType: payload.UserType,
            longitude: payload.Longitude,
            latitude: payload.Latitude,
            expiresIn: payload.ExpiresIn
        }
    }
}
const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_AUTHORIZE:
            return {
                ...state,
                authorizeUserFetching: true,
            };
        case GET_DENTIST_DATA:
        case GET_PATIENT_DATA:
        case GET_ADMIN_DATA:
            return {
                ...state,
                fetchingUserDetails: true,
            };
        case SET_PATIENT_DATA:
        case SET_DENTIST_DATA:
        case SET_ADMIN_DATA:

            if (payload?.response && payload.status === StatusCode.success) {
                setSession(sessionCreator(payload.response));
                return {
                    ...state,
                    session: sessionCreator(payload.response),
                    fetchingUserDetails: false,
                    emailNotVerified: false,
                    loginStatusCode: payload.status
                };
            } else if (payload.response === null && payload.status === StatusCode.notVerified) {
                return {
                    ...state,
                    notVerifiedEmail: payload.email,
                    fetchingUserDetails: false,
                    emailNotVerified: true,
                    loginStatusCode: payload.status
                };
            } else {
                return {
                    ...state,
                    loginStatusCode: payload.status,
                    fetchingUserDetails: false,
                };
            }

        case SET_TOKEN:

            if (payload?.response && payload.status === StatusCode.success) {
                setSession(sessionCreator(payload.response));
                return {
                    ...state,
                    session: sessionCreator(payload.response),
                    authorizeUserFetching: false,
                    emailNotVerified: false,
                    loginStatusCode: payload.status,
                };
            } else {
                return {
                    ...state,
                    loginStatusCode: payload.status,
                    authorizeUserFetching: false
                };
            }
        case RESET_LOGIN_MODULE:
            return {
                ...state,
                loginStatusCode: null,
                fetchingUserDetails: false,
            };
        case SET_USER:
            return {
                ...state,
            };
        case NEW_USER:
            return {
                ...state,
                loading: true,
            };
        case SET_LOGING_STATUS:
            return {
                ...state,
                loginStatus: payload,
            };
        case GET_SUCCESS_STATUS:
            return {
                ...state,
                loading: false,
                successStatus: payload,
            };
        case SET_SUCCESS_STATUS:
            return {
                ...state,
                loading: false,
                successStatus: payload,
            };
        case SET_DENTIST_COUNT:
            return {
                ...state,
                dentistCount: payload,
            };
        case SET_EMERGENCY_USER:
            return {
                ...state,
                emergencyResponse: [...state.emergencyResponse, payload],
                successStatus: payload.Success,
            };
        case GET_VALIDATE_EMAIL:
            return {
                ...state,
                validateEmailLoading: true,
            };
        case SET_VALIDATE_EMAIL:
            return {
                ...state,
                validateEmailLoading: false,
                emailValidationDetails: payload,
            };
        case GET_EMAIL_VALIDATION_LINK:
            return {
                ...state,
                linkgetLoading: true,
            };
        case SET_EMAIL_VALIDATION_LINK:
            return {
                ...state,
                linkgetLoading: false,
                verficationLinkGetStatus: payload,
            };
        case RESET_EMAIL_VERIFY_DETAILS:
            return {
                ...state,
                emailNotVerified: false,
                notVerifiedEmail: undefined,
                linkgetLoading: false,
                verficationLinkGetStatus: undefined,
            };
        case SUCCESS_STATUS_RESET:
            return {
                ...state,
                successStatus: payload,
                emailValidationDetails: {}
            };
        default:
            return { ...state };
    }
};

export default reducer;
