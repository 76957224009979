
import React from 'react';
import { SharepointStatus } from '@core-interface';
import {
    faCalendarPen,
    faCalendarStar,
    faCalendarClock,
    faHandshake,
    faClock,
    faCircleExclamationCheck,
    faCircleDollar,
    faTrashCan, faTrashCanXmark,
    faBan,
    faPen,
    faCalendarPlus,
    faCalendarXmark,
    faUserHeadset,
    faCalendarEdit,
    faCalendarCheck,
    faBagShopping,
    faCalendarExclamation,
    faCalendarTimes,
    faDoorClosed,
    faUserTimes,
    faCalendar
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from 'styled-components';

const StyledSharePointStatusIcon = styled.div`
    position: absolute; 
    top: 0px; 
    right: 6px;
    ${({ primaryColor }) =>
        primaryColor && css`
      .fa-primary {
        fill: ${primaryColor} !important;
        opacity: unset;
      }
    `}
    ${({ secondaryColor }) =>
        secondaryColor && css`
      .fa-secondary {
        fill: ${secondaryColor} !important;
        opacity: unset;
      }
    `}
`;

const SharePointStatusIcon = ({ status }) => {

    const getValue = (status) => {
        switch (status) {
            case SharepointStatus.CONSULT: return <StyledSharePointStatusIcon primaryColor={'#39A9E0'} secondaryColor={'#A51A22'}><FontAwesomeIcon icon={faUserHeadset} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.PENDING: return <StyledSharePointStatusIcon primaryColor={'#A51A22'} secondaryColor={'#FDD245'}><FontAwesomeIcon icon={faCalendarEdit} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.RESERVED: return <StyledSharePointStatusIcon primaryColor={'#A51A22'} secondaryColor={'#FDD245'}><FontAwesomeIcon icon={faCalendarPlus} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.BOOKED: return <StyledSharePointStatusIcon primaryColor={'#A51A22'} secondaryColor={'#FDD245'}><FontAwesomeIcon icon={faCalendarCheck} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.PAID: return <StyledSharePointStatusIcon primaryColor={'#A51A22'} secondaryColor={'#FDD245'}><FontAwesomeIcon icon={faBagShopping} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.CHECKING: return <StyledSharePointStatusIcon primaryColor={'#A51A22'} secondaryColor={'#FDD245'}><FontAwesomeIcon icon={faCalendarExclamation} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.CANCELLED: return <StyledSharePointStatusIcon primaryColor={'#8A3F7C'} secondaryColor={'#FDD245'}><FontAwesomeIcon icon={faCalendarTimes} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.CLOSED: return <StyledSharePointStatusIcon primaryColor={'#8A3F7C'} secondaryColor={'#FDD245'}> <FontAwesomeIcon icon={faDoorClosed} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.UNAVAILABLE: return <StyledSharePointStatusIcon primaryColor={'#39A9E0'} secondaryColor={'#8A3F7C'}><FontAwesomeIcon icon={faUserTimes} size={'3x'} /></StyledSharePointStatusIcon>;
            case SharepointStatus.OTHER: return <StyledSharePointStatusIcon primaryColor={'#39A9E0'} secondaryColor={'#B2C7D9'}><FontAwesomeIcon icon={faCalendar} size={'3x'} /></StyledSharePointStatusIcon>;
            default: return <div></div>
        };
    };

    return <div>{getValue(status)}</div>;
}

export default React.memo(SharePointStatusIcon);
