import { treatmentTypes } from "@core-const";
import { AdminReducer, MyProfileReducer } from "@core-interface";
import { EmergencyRequestStatus } from "@core-interface";
import { GET_ADMIN_REVIEWS_DATA, GET_ADMIN_REVIEW_DATA, SET_ADMIN_REVIEWS_DATA, SET_ADMIN_REVIEW_DATA, SET_ADMIN_SELECTED_STATUS, UPDATE_ADMIN_DENTIST_REVIEW_DATA } from "./admin.actions";

const initState: AdminReducer = {
  review: undefined,
  reviewsList: [],
  total: 0,
  loading: false,
  reviewLoading: false,
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_REVIEWS_DATA: {
      return {
        ...state,
        loading: true
      }
    }
    case SET_ADMIN_REVIEWS_DATA: {
      return {
        ...state,
        reviewsList: payload.Page === 1
          ? payload.Data
          : [...state.reviewsList, ...payload.Data],
        total: payload.Total,
        loading: false
      }
    }
    case GET_ADMIN_REVIEW_DATA: {
      return {
        ...state,
        reviewLoading: true,
        review: undefined
      }
    }
    case SET_ADMIN_REVIEW_DATA: {
      return {
        ...state,
        review: payload,
        reviewLoading: false
      }
    }
    case SET_ADMIN_REVIEW_DATA: {
      return {
        ...state,
        review: payload,
        reviewLoading: false
      }
    }
    case UPDATE_ADMIN_DENTIST_REVIEW_DATA: {
      return {
        ...state,
        review: { ...state.review, ...payload },
        reviewLoading: false
      }
    }
    case SET_ADMIN_SELECTED_STATUS: {
      return {
        ...state,
        selectedStatus: payload
      }
    }
    default:
      break;
  }
  return state;
};

export default reducer;
