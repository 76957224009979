import { Row, Col } from "reactstrap";
import { SubTitle } from 'app-styled-components';
import styled, { css } from "styled-components";
import { device } from "@core-const";

const Wrapper = styled(Row)`
    width: 100%;
    height: calc( 100vh - 71px);
    padding: 3rem 1rem;
    overflow-y: auto;
    box-sizing: border-box;
    overflow-x: hidden;
    ${({ theme }) => css`
        @media ${device.tablet} { 
            padding: 1rem 0.5rem;
        };
        @media ${device.mobileM} { 
            padding: .5rem 0rem;
        };
    `}
`;
const ProfilePageBodyContainer = styled(Col)`
    height: 100%;
    ${({ theme }) => css`
        @media ${device.tablet} { 
        };
        @media ${device.mobileM} { 
        };
    `}
`;

const ProfilePageContainer = ({ title, children }) => {
    return(    
    <Wrapper>
        <SubTitle bold>
            {title}
        </SubTitle>
        <ProfilePageBodyContainer md={12}>
            {children}
        </ProfilePageBodyContainer>
    </Wrapper>
    );
}

export default ProfilePageContainer;