import { ofType, combineEpics } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs'
import { addNotification } from '@core-action';
import { NotificationType } from '@core-interface';
import {
  UPDATE_USER_PASSWORD, updateUserPasswordSuccess
} from './resetPassword.action';
import { httpPostV2 } from 'app-core/httpCall/httpCallv2';
import { StatusCode } from 'app-core/constant/authTypes';

export const epicGetResetPassword = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_USER_PASSWORD),
    switchMap(({ payload }: any) =>
      httpPostV2({
        call: `user/reset/password`,
        data: payload
      }).pipe(mergeMap((data: any) => {
        if (data.status === StatusCode.success) {
          return of(
            updateUserPasswordSuccess(data.status),
            addNotification({
              type: NotificationType.SUCCESS,
              messageKey: 'successfully_update_password',
              message: `Successfully Password Updated`
            }),
          );
        } else if(data.status === StatusCode.invalid || data.status === StatusCode.notVerified){
          return of(
            updateUserPasswordSuccess(data.status),
            addNotification({
            type: NotificationType.ERROR,
            messageKey: 'error_updating_invalid_or_expired',
            message: 'Error!, Invalid or Expired Token',
          }),
          );
        } else {
          return of(
            updateUserPasswordSuccess(data.status),
            addNotification({
            type: NotificationType.ERROR,
            messageKey: 'error_updating_the_password',
            message: 'Error Updating the password'
          }),
          );
        }
      }),
      )
    )
  );
};

const ResetPasswordEpic = combineEpics(
  epicGetResetPassword
);

export default ResetPasswordEpic;