import React, { memo } from "react";
import loadable from "@loadable/component";
import { Message } from "@localization";

import * as SC from "./resetPassword.styled";

const ResetPasswordForm = loadable(
  () => import(/*webpackChunkName: "pkg_____signin"*/ "./resetPasswordForm")
);
const Footer = loadable(
  () => import(/*webpackChunkName: "pkg_____signin"*/ "../../modules/footer")
);

const ResetPasswordPage = () => {
  return (
    <>
      <SC.ResetPasswordWrapper>
        <SC.Welcome>
          <Message
            messageKey={"welcome_to_the_smylor"}
            defaultMessage={"Welcome to the smylor"}
          />
        </SC.Welcome>
        <SC.ResetPasswordContainer>
          <SC.ResetPasswordPageColumnBody>
            <SC.ResetPasswordFormTitle bold>
              <Message messageKey={"reset_password"} defaultMessage={"Reset Password"} />
            </SC.ResetPasswordFormTitle>
            <SC.ResetPasswordFormBody>
              <ResetPasswordForm />
            </SC.ResetPasswordFormBody>
          </SC.ResetPasswordPageColumnBody>
          <SC.BackImageResetPassword image={"regiter"}></SC.BackImageResetPassword>
        </SC.ResetPasswordContainer>
      </SC.ResetPasswordWrapper>
      <SC.FooterContainer>
        <Footer />
      </SC.FooterContainer>
    </>
  );
};

export default memo(ResetPasswordPage);
