export const reviewRatingTypes = [
    {
        ReviewTypeId: 1,
        Description: "Booking Process",
        ReviewId: "booking_process"
    },
    {
        ReviewTypeId: 2,
        Description: "Location & Access",
        ReviewId: "location_access"
    },
    {
        ReviewTypeId: 3,
        Description: "Treatment Registration",
        ReviewId: "welcome_briefing"
    },
    {
        ReviewTypeId: 4,
        Description: "Treatment Comfort",
        ReviewId: "treatment_experience"
    },
    {
        ReviewTypeId: 5,
        Description: "Pre-Treatment Advice",
        ReviewId: "post_treatment_advice"
    },
    {
        ReviewTypeId: 6,
        Description: "Treatment Affordability",
        ReviewId: "treatment_affordability"
    },
    {
        ReviewTypeId: 7,
        Description: "Treatment Payment Options",
        ReviewId: "treatment_payment_options"
    },
    {
        ReviewTypeId: 8,
        Description: "Treatment Follow Up",
        ReviewId: "treatment_follow_up"
    },
    {
        ReviewTypeId: 9,
        Description: "Treatment Result (Cosmetic Only)",
        ReviewId: "treatment_cosmetic_result"
    }
]