import { USER_ROLES } from "@core-const";
import { Message } from "@localization";

export const userRoleRender = (role: number) => {
    switch (role) {
      case USER_ROLES.DENTIST:
        return <Message messageKey={"dentist"} defaultMessage={"Dentist"} />;
      case USER_ROLES.PATIENT:
        return <Message messageKey={"user"} defaultMessage={"User"} />;
      case USER_ROLES.ADMIN:
        return (
          <Message messageKey={"login_module_admin"} defaultMessage={"Admin"} />
        );
      default:
        return <></>;
    }
  };