export enum NotificationType {
    SUCCESS,
    ERROR,
    WARNING,
    INFO,
    NONE
}

export interface NotificationMessage {
    type?:NotificationType,
    message?:string,
    messageKey?:string,
    messageargs?:string[],
    disabledAutoClose?:boolean,
    button?:string,
    buttonKey?: string, 
    buttonUrl?:string,
    newTab?: boolean,
}
export interface NotificationPopupTypes {
    notifyType:NotificationType;
    defaultMessage:string;
    messageKey:string;
    messageargs?:string[];
    showNotify: boolean;
    onOkayClicked?: ()=>void;
    onCancelClicked?: ()=>void;
}