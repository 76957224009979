import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, groupBy, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { httpGet, httpPost } from "@core-http";
import { GET_CREATE_DENTIST, SET_ADD_PATIENT_TREATMENT_REVIEW, SET_CREATE_DENTIST, SUBMIT_REVIEW_DATA, getAddPatientTreatmentReview, getCreateDentistData } from "./patientReview.actions";
import { addNotification } from "@core-action";
import { NotificationType } from "@core-interface";

export const epicSubmitTreatmentReviewData = (action$, state$) => {
  return action$.pipe(
    ofType(SUBMIT_REVIEW_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `TreatmentReview/CreateTreatmentReview`,
        data: payload,
      }).pipe(
        mergeMap(({ Success }: any) => {
          if (Success) {
            return of(
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_review_submitted",
                message: "Successfully Review Submitted",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_submitting_review",
                message: "Error Submitting Review",
              })
            );
          }
        })
      )
    )
  );
};

export const epicCreateDentistReviewForm = (action$, state$) => {
  return action$.pipe(
    ofType(SET_CREATE_DENTIST),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `dentist/CreateDentist`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }: any) => {
          if (Success) {
            return of(
              getCreateDentistData(Data),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_patient_review_dentist_added",
                message: "Successfully Dentist Created",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_patient_review_dentist_adding",
                message: "Error Creating Dentist",
              })
            );
          }
        })
      )
    )
  );
};

export const epicAddPatientTreatmetReview = (action$, state$) => {
  return action$.pipe(
    ofType(SET_ADD_PATIENT_TREATMENT_REVIEW),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `TreatmentReview/AddTreatmentReview`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }: any) => {
          if (Success) {
            return of(
              getAddPatientTreatmentReview(Data),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_review_submitted",
                message: "Successfully Review Submitted",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_submitting_review",
                message: "Error Submitting Review ",
              })
            );
          }
        })
      )
    )
  );
};

const TreatmentReviewEpic = combineEpics(
  epicSubmitTreatmentReviewData,
  epicCreateDentistReviewForm,
  epicAddPatientTreatmetReview
);

export default TreatmentReviewEpic;
