import { AddPatientReviewData, CreateDentitstData } from "@core-interface";

export const SUBMIT_REVIEW_DATA = "SUBMIT_REVIEW_DATA";
export const submitTreatmentReviewData = (payload) => ({
  type: SUBMIT_REVIEW_DATA,
  payload,
});

export const GET_CREATE_DENTIST = "GET_CREATE_DENTIST";
export const getCreateDentistData = (payload: any) => ({
  type: GET_CREATE_DENTIST,
  payload,
});

export const SET_CREATE_DENTIST = "SET_CREATE_DENTIST";
export const setCreateDentistData = (payload: CreateDentitstData) => ({
  type: SET_CREATE_DENTIST,
  payload,
});

export const GET_ADD_PATIENT_TREATMENT_REVIEW = "GET_ADD_PATIENT_TREATMENT_REVIEW";
export const getAddPatientTreatmentReview = (payload: any) => ({
  type: GET_ADD_PATIENT_TREATMENT_REVIEW,
  payload,
});

export const SET_ADD_PATIENT_TREATMENT_REVIEW = "SET_ADD_PATIENT_TREATMENT_REVIEW";
export const setAddPatientTreatmentReview = (payload: AddPatientReviewData) => ({
  type: SET_ADD_PATIENT_TREATMENT_REVIEW,
  payload,
});
export const RESET_REVIEW_DENTIST_DETAILS = "RESET_REVIEW_DENTIST_DETAILS";
export const resetReviewDentistDetails = () => ({ type: RESET_REVIEW_DENTIST_DETAILS });
