export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const updateUserPassword = (payload: {
  ResetToken: string;
  Password: string;
  ConfirmPassword: string;
  Culture: string
}) => ({ type: UPDATE_USER_PASSWORD, payload });

export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const updateUserPasswordSuccess = (payload: number) => ({
  type: UPDATE_USER_PASSWORD_SUCCESS,
  payload,
});
