import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { getLanguageFromReducer } from "app-core/styled/components/shared/mainSearch/mainSearch.selector";
import { getTreatmentServicesFromReducer } from "../profile/profile.selector";
import { getTreatmentServicesData } from "../profile/treatments/treatments.actions";
import { getProfileData } from "./services.actions";
import { getServicesDetailsFromReducer } from "./services.selector";
import * as SC from "./services.styled";

const ServicesMainDetails = loadable(
  () => import(/*webpackChunkName: "pkg_____services"*/ "./maindetails")
);
const ServicesSideBar = loadable(
  () => import(/*webpackChunkName: "pkg_____services"*/ "./servicesSidebar")
);
const Footer = loadable(
  () => import(/*webpackChunkName: "pkg_____services"*/ "../../modules/footer")
);

const ServicesPage = () => {
  let location = useLocation();
  const pathName = location.pathname.split("/")[2];

  const [autoSelectData, setAutoSelectData] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const dispatch = useDispatch();
  const treatmentServices = useSelector(getTreatmentServicesFromReducer);
  const selectedLanguage = useSelector(getLanguageFromReducer);
  const services = useSelector(getServicesDetailsFromReducer);

  useEffect(() => {
    dispatch(getTreatmentServicesData());
  }, []);

  useEffect(() => {
    selectedLanguage &&
      dispatch(
        getProfileData({
          Path: pathName,
          Culture: selectedLanguage,
        })
      );
  }, [
    JSON.stringify(selectedLanguage),
    JSON.stringify(serviceData),
    JSON.stringify(pathName),
  ]);

  useEffect(() => {
    if (treatmentServices.length > 0) {
      setAutoSelectData(
        treatmentServices.map(
          ({ Title, Image, ServiceId, Service_DE, Description, PagePath }) => ({
            Title,
            Image,
            ServiceId,
            Description,
            Service_DE,
            PagePath,
          })
        )
      );
    }
    setServiceData(services);
  }, [JSON.stringify(treatmentServices), JSON.stringify(services)]);

  return (
    <>
      <SC.CategoryWrapper>
        <SC.MainContainer>
          <SC.ServicesContainer>
            <SC.ServicesDescriptionInner md="9" sm="9" xl="10">
              {serviceData && (
                <ServicesMainDetails
                  serviceData={serviceData}
                  pathName={pathName}
                  filterData={autoSelectData}
                />
              )}
            </SC.ServicesDescriptionInner>
            <SC.ServicesTypeInner md="3" sm="3" xl="2">
              <ServicesSideBar
                sidebarData={autoSelectData}
                pathName={pathName}
              />
            </SC.ServicesTypeInner>
          </SC.ServicesContainer>
        </SC.MainContainer>
      </SC.CategoryWrapper>
      <SC.FooterContainer>
        <Footer />
      </SC.FooterContainer>
    </>
  );
};

export default ServicesPage;
