import React from 'react'
import loadable from "@loadable/component";
import * as SC from './prizing.styled';

const PrizingHeader = loadable(
    () => import(/*webpackChunkName: "pkg_____subscription"*/ "./prizingHeader")
  );
const PrizingBody = loadable(
    () => import(/*webpackChunkName: "pkg_____subscription"*/ "./prizingBody")
  );
const Footer = loadable(
    () => import(/*webpackChunkName: "pkg_____subscription"*/ "../../modules/footer")
  );

// import Footer from "../../modules/footer";
// import PrizingHeader from './prizingHeader';
// import PrizingBody from './prizingBody';

const PrizingPage = () => {
    return (
        <SC.PrizingWrapper>
            <SC.PrizingContainer>
                <PrizingHeader/>
                <PrizingBody/>
            </SC.PrizingContainer>
            <Footer />
        </SC.PrizingWrapper>
    )
}

export default PrizingPage
