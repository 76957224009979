import styled, { css } from "styled-components";
import { Page, PageContainerFullWidth } from "app-styled-components";
import { FlexRow } from "../profile/profile.styled";
import { device } from "@core-const";

const HomeWrapper = styled(Page)`
  overflow: hidden;
`;

const SectionMainWrapper = styled(PageContainerFullWidth)`
  display: flex;
  padding: 0px;
  align-items: center;
`;

const SectionMainContainer = styled(FlexRow)`
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 60px 150px;
  ${({ theme }) => css`
    @media ${device.laptop} {
      padding: 15px 42px;
    }
  `}
`;

export { HomeWrapper, SectionMainWrapper, SectionMainContainer };
