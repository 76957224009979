import moment from 'moment';
import { Message } from '@localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface durationCalculatorProps{
    duration: {stTime: string, edTime: string};
    durationType: 'days' | 'hours'|  'minutes'
}

export const DurationCalculator = ({duration, durationType}: durationCalculatorProps )=>{
    const TimeDiffernce = moment(duration.edTime).diff(moment(duration.stTime), durationType);
    const durationTypeTextRender = () => {
        switch (durationType) {
            case 'days':
                return  <Message messageKey={"time_slot_card_minutes"} defaultMessage={"Days"} />
            case 'hours':
                return  <Message messageKey={"time_slot_card_minutes"} defaultMessage={"Hours"} />
            case 'minutes':
                return  <Message messageKey={"time_slot_card_minutes"} defaultMessage={"Min"} />
        
            default:
                break;
        }
    }

    return(
        <>
        <FontAwesomeIcon icon={faClock as IconProp} size='xl'/>
        &nbsp;&nbsp;
        {TimeDiffernce}
        &nbsp;
        {durationTypeTextRender()}
        </>
    )
}