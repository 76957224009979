import { device } from "@core-const";
import { Message } from "@localization";
import { Image } from "app-styled/components";
import { FlexCenter, FlexCol } from "app-styled/flex";
import { FC } from "react";
import styled, { css } from "styled-components";

interface TreatmentProps {
  Description: string;
  Image: string;
  PagePath: string;
  ServiceId: string;
  Service_DE: string;
  Title: string;
}

interface SmylorTreatmentGuideCardProps {
  data: TreatmentProps;
  onClick: Function;
}

const TreatmentGuideCardWrapper = styled(FlexCenter)`
  ${({ theme }) => css`
    width: 215px;
    height: 180px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: ${theme["thm-space-lg"]};
    background: ${theme["smylor-white"]};
    border-radius: ${theme["thm-space-rg"]};
    cursor: pointer;
    :hover {
      background: ${theme["smylor-blue"]};
      color: ${theme["smylor-white"]};
    }
    @media ${device.tablet} {
      width: 155px;
      height: 130px;
    }
  `};
`;

const TreatmentGuideCardContainer = styled(FlexCol)`
  text-align: center;
  align-items: center;
  justify-content: center;
`;
const TreatmentGuideCardTextContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
const TreatmentImage = styled(Image)`
  width: 100px;
  height: 100px;
  @media ${device.tablet} {
    width: 80px;
    height: 80px;
  }
`;

export const SmylorTreatmentGuideCard: FC<SmylorTreatmentGuideCardProps> = ({
  data,
  onClick,
}) => {
  return (
    <TreatmentGuideCardWrapper onClick={() => onClick(data.ServiceId)}>
      <TreatmentGuideCardContainer>
        <TreatmentImage src={data.Image} alt={data.Title} />
        <TreatmentGuideCardTextContainer>
          <Message messageKey={data.Title} defaultMessage={""} />
        </TreatmentGuideCardTextContainer>
      </TreatmentGuideCardContainer>
    </TreatmentGuideCardWrapper>
  );
};
