import { useRoutes, Navigate, useSearchParams, useLocation } from 'react-router-dom';
import HomeComponent from '../../pages/home'
import ListPage from '../../pages/list';
import ProfilePage from '../../pages/profile';
import RegisterPage from '../../pages/register';
import SigninPage from '../../pages/signin';
import MyProfilePage from '../../pages/myProfile';
import ServicesPage from '../../pages/services';
import PrizingPage from '../../pages/pricing';
import MainHeader from './mainHeader';
import { Session } from '@core-interface';
import { USER_ROLES, getSession, loginUrl } from '@core-const';
import BookingNavigator from '../../pages/booking';
import OpeningHoursNavigator from '../../pages/openingHours';
import PrivacyPage from '../../pages/privacy';
import TermsPage from '../../pages/terms';
import ResetPasswordPage from '../../pages/resetPassword';
import ForgetPasswordPage from '../../pages/forgetPassword';
import MyProfileRoutes from '../../pages/myProfile/myProfile.router';
import AdminRoutes from '../../pages/admin/admin.router';
import AdminPage from '../../pages/admin';
import DentistProfileNavigator from 'src/pages/viewDentistProfile';

const MainRoutes = () => {
    const session: Session = getSession();
    let { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    let ProfileElement = <MainHeader bgColor={true} showPreHeader={true}><MyProfilePage /></MainHeader>;
    if (!session) {
        pathname.startsWith('/myprofile') && localStorage.setItem('redirectUrl', pathname)
        ProfileElement = <Navigate to={'/'} replace />
    };

    let AdminElement;
    if (session && session.role === USER_ROLES.ADMIN) {
        AdminElement = <MainHeader bgColor={false} showPreHeader={true}><AdminPage /></MainHeader>;
    } else AdminElement = <Navigate to={'/'} replace />;

    const routes = useRoutes([
        {
            path: '/',
            element: <MainHeader bgColor={false} showPreHeader={true}><HomeComponent /></MainHeader>,
        },
        {
            path: '/list',
            element: <MainHeader bgColor={false} showPreHeader={true}><ListPage /></MainHeader>,
        },
        {
            path: '/profile/:id',
            element: <MainHeader bgColor={false} showPreHeader={true}><ProfilePage /></MainHeader>,
        },
        {
            path: '/myprofile',
            element: ProfileElement,
            children: MyProfileRoutes(),
        },
        {
            path: '/services/:id',
            element: <MainHeader bgColor={true} showPreHeader={true}><ServicesPage /></MainHeader>,
        },
        {
            path: '/register',
            element: <MainHeader bgColor={true} showPreHeader={true}><RegisterPage /></MainHeader>,
        },
        {
            path: '/signup',
            element: <MainHeader bgColor={true} showPreHeader={true}><SigninPage /></MainHeader>,
        },
        {
            path: '/pricing',
            element: <MainHeader bgColor={true} showPreHeader={true}><PrizingPage /></MainHeader>,
        },
        {
            path: '/booking/:id',
            element: <MainHeader bgColor={true} showPreHeader={true}><BookingNavigator /></MainHeader>,
        },
        {
            path: '/viewprofile/:id',
            element: <MainHeader bgColor={true} showPreHeader={true}><DentistProfileNavigator /></MainHeader>,
        },
        {
            path: '/viewprofile/:id',
            element: <MainHeader bgColor={true} showPreHeader={false}><DentistProfileNavigator /></MainHeader>,
        },
        {
            path: '/openingHours/:id',
            element: <MainHeader bgColor={true} showPreHeader={true}><OpeningHoursNavigator /></MainHeader>,
        },
        {
            path: '/privacy',
            element: <MainHeader bgColor={true} showPreHeader={true}><PrivacyPage /></MainHeader>,
        },
        {
            path: '/terms',
            element: <MainHeader bgColor={true} showPreHeader={true}><TermsPage /></MainHeader>,
        },
        {
            path: '/forgetpassword',
            element: <MainHeader bgColor={true} showPreHeader={true}><ForgetPasswordPage /></MainHeader>,
        },
        {
            path: '/resetpassword/',
            element: <MainHeader bgColor={true} showPreHeader={true}><ResetPasswordPage /></MainHeader>,
        },
        {
            path: '/admin/login',
            element: <MainHeader bgColor={false} showPreHeader={true}><HomeComponent /></MainHeader>,
        },
        {
            path: '/admin',
            element: AdminElement,
            children: AdminRoutes(),
        },
        {
            path: '*',
            element: <Navigate to='/' replace />,
        },
    ])
    return routes;
}

export default MainRoutes;