import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, groupBy, mergeMap, tap } from "rxjs/operators";
import { httpGet, httpPost } from "@core-http";
import { httpGetV2, httpPostV2 } from "app-core/httpCall/httpCallv2";
import { of } from "rxjs";
import { addNotification } from "@core-action";
import {
  LoggedAdminResponse,
  LoggedDentistResponse,
  LoggedPatientResponse,
  NotificationType,
} from "@core-interface";

import {
  GET_AUTHORIZE,
  GET_EMERGENCY_USER,
  SET_TREATMENT_TYPE,
  setToken,
  NEW_USER,
  setUser,
  setEmergencyUser,
  getSuccessStatus,
  GET_DENTIST_COUNT,
  setDentistCount,
  GET_VALIDATE_EMAIL,
  setValidateEmail,
  setEmailValidationLink,
  GET_EMAIL_VALIDATION_LINK,
  GET_PATIENT_DATA,
  GET_DENTIST_DATA,
  setPatientData,
  setDentistData,
  GET_ADMIN_DATA,
  setAdminData,
} from "./auth.action";
import { StatusCode } from "app-core/constant/authTypes";

export const epicNewUser = (action$, state$) => {
  return action$.pipe(
    ofType(NEW_USER),
    switchMap(({ payload }) =>
      httpPost({
        call: `account/UserSignup`,
        data: payload,
      }).pipe(
        mergeMap(({ Success }: any) => {
          if (Success) {
            return of(getSuccessStatus(Success));
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_creating_the_ccount",
                message: "Error Creating the Account",
              })
            );
          }
        })
      )
    )
  );
};

export const epicEmergencyUser = (action$, state$) => {
  return action$.pipe(
    ofType(GET_EMERGENCY_USER),
    switchMap(({ payload }) =>
      httpPost({
        call: `emergency/AddEmergencyRequest`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Message }: any) => {
          if (Success) {
            return of(
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey:
                  "successfully_send_emergency_request_number_of_dentists",
                message: `Successfully send Emergency Request to {0} Dentists.`,
                messageargs: [Message],
                disabledAutoClose: true,
                buttonKey: "view_here",
                button: "View Here",
                buttonUrl: "myprofile/emergencyPatientRequests?userType=2",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_sending_the_emergency_equest",
                message: "Error Sending the Emergency Request",
              })
            );
          }
        })
      )
    )
  );
};

export const epicTreatmentType = (action$, state$) => {
  return action$.pipe(
    ofType(SET_TREATMENT_TYPE),
    switchMap(({ payload: data }: any) =>
      httpPost({
        call: `treatmentrequests/HandleTreatmentRequest?language=${data?.Culture}`,
        data: { ...data },
      }).pipe(
        mergeMap(({ Success, Data: { DentistCount } }: any) => {
          let successKey,
            successMessage,
            errorKey,
            messageargs,
            errorMessage,
            disabledAutoClose,
            buttonKey,
            button,
            buttonUrl;

          if (data.TreatmentType === 1) {
            successKey =
              "successfully_send_emergency_request_number_of_dentists";
            successMessage = `Successfully send Emergency Request to {0} Dentists.`;
            errorKey = "error_sending_the_emergency_equest";
            errorMessage = "Error Sending the Emergency Request";
            messageargs = [DentistCount];
            disabledAutoClose = true;
            buttonKey = "view_here";
            button = "View Here";
            buttonUrl = "myprofile/emergencyPatientRequests?userType=2";
          } else if (data.TreatmentType === 2) {
            disabledAutoClose = true;
            button = "View Here";
            buttonUrl = "myprofile/treatmentPatientRequests?userType=2";

            if (data.DentistId) {
              successKey = "successfully_treatment_type_request_added";
              successMessage = `Successfully Treatment Type Request Added.`;
              messageargs = [];
            } else {
              successKey = "successfully_globle_treatment_type_request_added";
              successMessage = `Successfully Treatment Type Request Send To {0} Dentists.`;
              messageargs = [DentistCount];
            }
            errorKey = "error_sending_treatment_type_request";
            errorMessage = "Error Sending Treatment Type Request.";
          }
          if (Success) {
            return of(
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: successKey,
                message: successMessage,
                messageargs,
                disabledAutoClose,
                buttonKey,
                button,
                buttonUrl,
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: errorKey,
                message: errorMessage,
                messageargs,
              })
            );
          }
        })
      )
    )
  );
};

export const epicDentistDistanceCount = (action$, state$) => {
  return action$.pipe(
    ofType(GET_DENTIST_COUNT),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `dentist/GetCount`,
        data: payload,
      }).pipe(map(({ Data }: any) => setDentistCount(Data)))
    )
  );
};

// Email validation api
export const epicValidateEmail = (action$, state$) => {
  return action$.pipe(
    ofType(GET_VALIDATE_EMAIL),
    switchMap(({ payload }: any) =>
      httpGet({
        call: `account/ValidateEmail?email=${payload}`,
      }).pipe(map((result: any) => setValidateEmail(result)))
    )
  );
};

export const epicGetVerificationLink = (action$, state$) => {
  return action$.pipe(
    ofType(GET_EMAIL_VALIDATION_LINK),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `account/resend/VerificationEmail`,
        data: payload,
      }).pipe(
        mergeMap((data: boolean) => {
          if (data) {
            return of(setEmailValidationLink(data));
          } else {
            return of(setEmailValidationLink(data));
          }
        })
      )
    )
  );
};

export const epicGetLoggedPatient = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PATIENT_DATA),
    switchMap(({ payload }: any) =>
      httpPostV2({
        call: `user/patient/signin`,
        data: payload,
      }).pipe(
        mergeMap((data: LoggedPatientResponse) => {
          if (data.status === StatusCode.success) {
            return of(setPatientData(data));
          } else if (data.status === StatusCode.notVerified) {
            return of(setPatientData({ ...data, email: payload.Email }));
          } else {
            return of(setPatientData(data));
          }
        }))
    )
  );
};

export const epicGetLoggedDentist = (action$, state$) => {
  return action$.pipe(
    ofType(GET_DENTIST_DATA),
    switchMap(({ payload }: any) =>
      httpPostV2({
        call: `user/dentist/signin`,
        data: payload,
      }).pipe(
        mergeMap((data: LoggedDentistResponse) => {
          if (data.status === StatusCode.success) {
            return of(setDentistData(data));
          } else if (data.status === StatusCode.notVerified) {
            return of(setDentistData({ ...data, email: payload.Email }));
          } else {
            return of(setDentistData(data));
          }
        }))
    )
  );
};

export const epicGetLoggedAdmin = (action$, state$) => {
  return action$.pipe(
    ofType(GET_ADMIN_DATA),
    switchMap(({ payload }: any) =>
      httpPostV2({
        call: `user/admin/signin`,
        data: payload,
      }).pipe(
        mergeMap((data: LoggedAdminResponse) => {
          return of(setAdminData(data));
        }))
    )
  );
};

export const epicGetSocialLoginResults = (action$, state$) => {
  return action$.pipe(
    ofType(GET_AUTHORIZE),
    switchMap(({ payload }: any) =>
      httpGetV2({
        call: `user/social/signin/${payload}`
      }).pipe(map((result: any) => setToken(result)))
    )
  );
};

const AuthEpic = combineEpics(
  epicNewUser,
  epicEmergencyUser,
  epicTreatmentType,
  epicDentistDistanceCount,
  epicValidateEmail,
  epicGetVerificationLink,
  epicGetLoggedPatient,
  epicGetLoggedDentist,
  epicGetLoggedAdmin,
  epicGetSocialLoginResults
);

export default AuthEpic;
