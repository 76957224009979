import { AdminGetReview, AdminListReview } from "@core-interface";

export const GET_ADMIN_REVIEWS_DATA = 'GET_ADMIN_REVIEWS_DATA';
export const getAdminReviewsData = (payload: AdminGetReview) => ({ type: GET_ADMIN_REVIEWS_DATA, payload });

export const SET_ADMIN_REVIEWS_DATA = 'SET_ADMIN_REVIEWS_DATA';
export const setAdminReviewsData = (payload: {Data:AdminListReview[], Total: number, Page: number}) => ({ type: SET_ADMIN_REVIEWS_DATA, payload });

export const GET_ADMIN_REVIEW_DATA = 'GET_ADMIN_REVIEW_DATA';
export const getAdminReviewData = (payload: string) => ({ type: GET_ADMIN_REVIEW_DATA, payload });

export const SET_ADMIN_REVIEW_DATA = 'SET_ADMIN_REVIEW_DATA';
export const setAdminReviewData = (payload: any) => ({ type: SET_ADMIN_REVIEW_DATA, payload });

export const SUBMIT_ADMIN_REVIEW_DATA = "SUBMIT_ADMIN_REVIEW_DATA";
export const submitAdminReviewData = (payload) => ({ type: SUBMIT_ADMIN_REVIEW_DATA, payload });

export const UPDATE_ADMIN_DENTIST_REVIEW_DATA = "UPDATE_ADMIN_DENTIST_REVIEW_DATA";
export const updateAdminReviewData = (payload) => ({ type: UPDATE_ADMIN_DENTIST_REVIEW_DATA, payload });

export const SET_ADMIN_SELECTED_STATUS = "SET_ADMIN_SELECTED_STATUS";
export const setAdminSelectedStatus = (payload: number) => ({ type: SET_ADMIN_SELECTED_STATUS, payload });


