import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, groupBy, mergeMap } from 'rxjs/operators';
import { httpGet } from '@core-http';
import {
  GET_LOCALIZATION_DATA,
  setLocalizationData
} from './core.actions';

export const epicGetLocalization = (action$, state$) => {
  return action$.pipe(
    ofType(GET_LOCALIZATION_DATA),
    switchMap(( ) =>
      httpGet({
        call: `language/getall`,
      }).pipe(map((result: any) => setLocalizationData(result.Data)))
    )
  );
};

const CoreEpic = combineEpics(
     epicGetLocalization,
);

export default CoreEpic;