import { AppointmentViewReducer } from "@core-interface";
import {
    GET_APPOINTMENT_DATA,
    SET_APPOINTMENT_DATA,
    UPDATE_APPOINTMENT_STATUS,
    UPDATE_APPOINTMENT_STATUS_SUCCESS
} from "./appointmentCommon.actions";

const initState: AppointmentViewReducer = {
    appointmentData: undefined,
    loading: false,
    loadingAppintmentStatus: false,
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_APPOINTMENT_DATA:
            return {
                ...state,
                appointmentData: undefined,
                loading: true
            }
        case SET_APPOINTMENT_DATA:
            return {
                ...state,
                appointmentData: payload,
                loading: false
            };
        case UPDATE_APPOINTMENT_STATUS:
            return {
                ...state,
                loadingAppintmentStatus: true,
            };
        case UPDATE_APPOINTMENT_STATUS_SUCCESS:
            return {
                ...state,
                loadingAppintmentStatus: false,
                appointmentData: {...state.appointmentData, StatusLevel: payload.appoinmentStatus }
            };
        default:
            break;
    }
    return state;
}

export default reducer;