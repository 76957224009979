import styled, { css } from 'styled-components';
import {
  PageContainerFullWidth,
} from '../../core/styled/components/containers';
import { FlexCenter } from 'app-styled/flex';

const MainWrapper = styled(FlexCenter)`
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MainContainer = styled(PageContainerFullWidth)`
    // max-width: 1519px;
    width:100%;
    margin:0;
    padding:0;
    min-height: 100vh;
`;

export { MainWrapper, MainContainer };
