import styled, { css } from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  ${({ theme }) => css`
    padding: ${theme['padding-container']};
  `}

  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}
`;
