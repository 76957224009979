import { treatmentTypes } from "@core-const";
import { MyProfileReducer } from "@core-interface";
import { EmergencyRequestStatus } from "@core-interface";
import {
  SET_EMERGENCY_REQUESTS_DATA,
  SET_EMERGENCY_REQUEST_DATA,
  SET_PATIENT_EMERGENCY_REQUESTS_DATA,
  SET_SELECTED_EMERGENCY_TIME_SLOT,
  SET_PATIENT_EMERGENCY_REQUEST,
  SET_UPDATE_EMERGENCY_REQUEST_DATA,
  SET_ACCEPTED_EMERGENCY_TIME_SLOT,
  SET_ACCEPTED_TREATMENT_TIME_SLOT,
  SET_ACCEPT_SELECTED_EMERGENCY_TIME_SLOT,
  SET_TREATMENT_REQUESTS_DATA,
  SET_TREATMENT_REQUEST_DATA,
  SET_UPDATE_TREATMENT_REQUEST_DATA,
  SET_PATIENT_TREATMENT_REQUESTS_DATA,
  SET_PATIENT_TREATMENT_REQUEST,
  SET_SELECTED_TREATMENT_TIME_SLOT,
  GET_TREATMENT_REQUESTS_DATA,
  GET_PATIENT_TREATMENT_REQUESTS_DATA,
} from "./myProfile.actions";

const initState: MyProfileReducer = {
  emergencyRequest: {},
  emergencyRequestList: [],
  emergencyPatientRequest: [],
  emergencypatientRequestList: [],
  emergencyAcceptedSelectedTimeSlotData: {},
  treatmnetRequest: {},
  treatmnetRequestList: [],
  treatmnetAcceptedSelectedTimeSlotData: [],
  treatmentPatientRequest: {},
  treatmentPatientRequestList: [],
  total: 0,
  loading: false
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TREATMENT_REQUESTS_DATA : 

      if(payload.treatmentType === treatmentTypes.EMERGENCYREQUEST){
        return {
          ...state,
          loading: true
        }
      } else if (payload.treatmentType === treatmentTypes.TREATMENTREQUEST){
        return {
          ...state,
          loading: true
        }
      } else {
        return {
          ...state
        }
      };

    case SET_EMERGENCY_REQUESTS_DATA:
      return {
        ...state,
        emergencyRequestList:
          payload.page === 1
            ? payload.Data
            : [...state.emergencyRequestList, ...payload.Data],
        total: payload.Total,
        loading: false
      };
    
    case SET_EMERGENCY_REQUEST_DATA:
      return {
        ...state,
        emergencyRequest: payload,
      };
    case GET_PATIENT_TREATMENT_REQUESTS_DATA:

      if(payload.treatmentType === treatmentTypes.EMERGENCYREQUEST){
        return {
          ...state,
          loading: true
        }
      } else if (payload.treatmentType === treatmentTypes.TREATMENTREQUEST){
        return {
          ...state,
          loading: true
        }
      } else {
        return {
          ...state
        }
      };

    case SET_PATIENT_EMERGENCY_REQUESTS_DATA:
      return {
        ...state,
        emergencypatientRequestList:
          payload.page === 1
            ? payload.Data
            : [...state.emergencypatientRequestList, ...payload.Data],
        total: payload.Total,
        loading: false
      };
    case SET_PATIENT_EMERGENCY_REQUEST:
      return {
        ...state,
        emergencyPatientRequest: payload,
      };
    case SET_UPDATE_EMERGENCY_REQUEST_DATA:
      return {
        ...state,
        emergencyRequest: {
          ...state.emergencyRequest,
          AvailableTimes: [
            ...state.emergencyRequest.AvailableTimes,
            ...payload.IsoAvailableTimes,
          ],
        },
      };
    case SET_SELECTED_EMERGENCY_TIME_SLOT:
      const Avaiablity = state.emergencyPatientRequest.Avaiablity.map(
        (item: any) => {
          if (item.DentistId === payload.DentistId) {
            const AvailableSlots = item.AvailableSlots.map((slot: any) => {
              if (slot.FreeSlotId === payload.FreeSlotId) {
                return { ...slot, IsSelected: slot.IsSelected ? false : true };
              } else return { ...slot, IsSelected: false };
            });
            return { ...item, AvailableSlots };
          } else return item;
        }
      );
      return {
        ...state,
        emergencyPatientRequest: {
          ...state.emergencyPatientRequest,
          Avaiablity,
        },
      };
    case SET_SELECTED_TREATMENT_TIME_SLOT:
      const avaiablityTreatment = state.treatmentPatientRequest.Avaiablity.map(
        (item: any) => {
          if (item.DentistId === payload.DentistId) {
            const AvailableSlots = item.AvailableSlots.map((slot: any) => {
              if (slot.FreeSlotId === payload.FreeSlotId) {
                return { ...slot, IsSelected: slot.IsSelected ? false : true };
              } else return { ...slot, IsSelected: false };
            });
            return { ...item, AvailableSlots };
          } else return item;
        }
      );
      return {
        ...state,
        treatmentPatientRequest: {
          ...state.treatmentPatientRequest,
          Avaiablity: avaiablityTreatment,
        },
      };

    case SET_ACCEPTED_EMERGENCY_TIME_SLOT:
      return {
        ...state,
        emergencyRequest: {
          ...state.emergencyRequest,
          Status: payload.IsAccepted
            ? EmergencyRequestStatus.ACCEPTED
            : EmergencyRequestStatus.REJECTED,
        },
      };
    case SET_ACCEPTED_TREATMENT_TIME_SLOT:
      return {
        ...state,
        treatmnetRequest: {
          ...state.treatmnetRequest,
          Status: payload.IsAccepted
            ? EmergencyRequestStatus.ACCEPTED
            : EmergencyRequestStatus.REJECTED,
        },
      };
    case SET_ACCEPT_SELECTED_EMERGENCY_TIME_SLOT:
      return {
        ...state,
        emergencyAcceptedSelectedTimeSlotData: payload,
      };
    case SET_TREATMENT_REQUESTS_DATA:
      return {
        ...state,
        treatmnetRequestList:
          payload.page === 1
            ? payload.Data
            : [...state.treatmnetRequestList, ...payload.Data],
        total: payload.Total,
        loading: false
      };
    case SET_TREATMENT_REQUEST_DATA:
      return {
        ...state,
        treatmnetRequest: payload,
      };
    case SET_UPDATE_TREATMENT_REQUEST_DATA:
      return {
        ...state,
        treatmnetAcceptedSelectedTimeSlotData: payload,
        treatmnetRequest: {
          ...state.treatmnetRequest,
          AvailableTimes: [
            ...state.treatmnetRequest.AvailableTimes,
            payload.AvailableTimes[0],
          ],
        },
      };
    case SET_PATIENT_TREATMENT_REQUESTS_DATA:
      return {
        ...state,
        treatmentPatientRequestList:
          payload.page === 1
            ? payload.Data
            : [...state.treatmentPatientRequestList, ...payload.Data],
        total: payload.Total,
        loading: false
      };
    case SET_PATIENT_TREATMENT_REQUEST:
      return {
        ...state,
        treatmentPatientRequest: payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
