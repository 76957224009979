import React, { FC, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import styled, { css } from "styled-components";
import { FlexCenter } from "app-styled/flex";

export const LoadingWrapper = styled(FlexCenter)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]};
    svg {
      padding-left: 0px;
      padding-right: 0px;
      margin-right: ${theme["thm-space-md"]};
    }
  `}
`;

const LoadingIndicator = () => {
  return (
    <LoadingWrapper className={"loading-wrapper"}>
      <FontAwesomeIcon icon={faSpinnerThird} className={"fa-spin"} />
    </LoadingWrapper>
  );
};

export default memo(LoadingIndicator);
