import { device } from "@core-const";
import styled, { css } from "styled-components";

export interface SmylorLabelProps {
    colorType?: 'pink' | 'blue' | 'light' | 'pink-a' | 'blue-a';
    // startIcon?: boolean;
    // endIcon?: boolean;
    // size?: 'sm' | 'md' | 'lg'
    // outline?: 'pink' | 'blue' | 'light'
}
export const SmylorLabel = styled.span`
  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]} ${theme["thm-space-lg"]};
    font-weight: 500;
    ${({ theme, colorType }) => css`
    border-radius: 6px;
    background-color: ${colorType === "light" && theme["smylor-white"]};
    background-color: ${colorType === "blue" && theme["smylor-light-blueC"]};
    background-color: ${colorType === "pink" && theme["smylor-pink-D"]};
    color: ${colorType === "blue" &&  theme["smylor-dark-blue"]};
    color: ${colorType === "pink" &&  theme["smylor-pink"]};
  `};
    @media ${device.mobileL} {
    }
  `}
`;
