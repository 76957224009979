import React, { FC, memo } from "react";
import { Image } from "app-styled/components";
import * as SC from './treatmentTypeCircle.styled';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface TreatmentTypeItemCircleProps {
    Description: string;
    Image: string;
    ServiceId: string;
    Service_DE: string;
    Title: string
}
interface TreatmentTypeCircleProps {
    item?: TreatmentTypeItemCircleProps;
    onClick: Function;
    onMouseMove: Function;
    icon?: IconProp
};

const TreatmentTypeCircle: FC<TreatmentTypeCircleProps> = ({ item, onClick, onMouseMove, icon }) => {
    return (
        <SC.StyledTreatmentTypeCircle className="k-avatar k-rounded-full" onClick={onClick}>
            <SC.StyledTreatmentTypeCircleInner className="k-avatar k-rounded-full">
                {icon ? <SC.TreatmentIcon icon={icon} /> :
                    <Image src={item.Image} alt={item.Title} height={'100%'} width={'100%'} />
                }
            </SC.StyledTreatmentTypeCircleInner>
        </SC.StyledTreatmentTypeCircle>
    )
}

export default memo(TreatmentTypeCircle);