export const GET_TREATMENT_SERVICES_DATA = 'GET_TREATMENT_SERVICES_DATA';
export const getTreatmentServicesData = () => ({ type: GET_TREATMENT_SERVICES_DATA});

export const SET_TREATMENT_SERVICES_DATA = 'SET_TREATMENT_SERVICES_DATA';
export const setTreatmentServicesData = payload => ({ type: SET_TREATMENT_SERVICES_DATA, payload });

export const GET_PROFILE_TREATMENT_SERVICES_DATA = 'GET_PROFILE_TREATMENT_SERVICES_DATA';
export const getProfileTreatmentServicesData = payload => ({ type: GET_PROFILE_TREATMENT_SERVICES_DATA, payload});

export const SET_PROFILE_TREATMENT_SERVICES_DATA = 'SET_PROFILE_TREATMENT_SERVICES_DATA';
export const setProfileTreatmentServicesData = payload => ({ type: SET_PROFILE_TREATMENT_SERVICES_DATA, payload });

export const SET_UPDATED_TREATMENT_SERVICES_DATA = 'SET_UPDATED_TREATMENT_SERVICES_DATA';
export const setUpdatedTreatmentServicesData = payload => ({ type: SET_UPDATED_TREATMENT_SERVICES_DATA, payload });

export const SET_DELETED_TREATMENT_SERVICES_DATA = 'SET_DELETED_TREATMENT_SERVICES_DATA';
export const setDeletedTreatmentServicesData = payload => ({ type: SET_DELETED_TREATMENT_SERVICES_DATA, payload });

export const CREATE_TREATMENT_SERVICES_DATA = 'CREATE_TREATMENT_SERVICES_DATA';
export const createTreatmentServicesData = payload => ({ type: CREATE_TREATMENT_SERVICES_DATA, payload});

export const GET_CREATE_TREATMENT_SERVICES_DATA = 'GET_CREATE_TREATMENT_SERVICES_DATA';
export const getCreateTreatmentServicesData = payload => ({ type: GET_CREATE_TREATMENT_SERVICES_DATA, payload});

export const UPDATE_TREATMENT_SERVICES_DATA = 'UPDATE_TREATMENT_SERVICES_DATA';
export const updateTreatmentServicesData = payload => ({ type: UPDATE_TREATMENT_SERVICES_DATA, payload});

export const DELETE_TREATMENT_SERVICES_DATA = 'DELETE_TREATMENT_SERVICES_DATA';
export const deleteTreatmentServicesData = payload => ({ type: DELETE_TREATMENT_SERVICES_DATA, payload});

export const RESET_TREATMENT_LOADING_STATUS = 'RESET_TREATMENT_LOADING_STATUS';
export const resetTreatmentLaoding = () => ({ type: RESET_TREATMENT_LOADING_STATUS});

