import { ForgetPasswordReducer } from "@core-interface";
import {
    GET_PASSWORD_RESET_LINK,
    SET_PASSWORD_RESET_LINK
} from './forgetPassword.action';

const initState: ForgetPasswordReducer = {
    loading: false,
    status: null
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PASSWORD_RESET_LINK:
            return {
                ...state,
                loading: true,
                status: null
            };
        case SET_PASSWORD_RESET_LINK:
            return {
                ...state,
                loading: false,
                status: payload
            };
        default:
            break;
    }
    return state;
}

export default reducer;