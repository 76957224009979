import { combineReducers } from 'redux';
import coreReducer from '../core/core.reducer';
import resultReducer from '../pages/list/results.reducer';
import profileReducer from '../pages/profile/profile.reducer';
import myProfileReducer from '../pages/myProfile/myProfile.reducer';
import authReducer from '../modules/auth/auth.reducer';
import appointmentsReducer from '../pages/myProfile/appointments/appointments.reducer';
import appointmentViewReducer from '../pages/myProfile/appoinmentCommon/appointmentCommon.reducer';
import reviewsReducer from '../pages/myProfile/reviews/reviews.reducer';
import patientProfileReducer from '../pages/myProfile/patientProfile/patientProfile.reducer';
import patientReviewReducer from '../pages/myProfile/patientReviewForm/patientReview.reducer';
import reviewReducer from '../pages/myProfile/reviewCommon/reviewCommon.reducer';
import servicesReducer from '../pages/services/services.reducer';
import registerReducer from '../pages/register/register.reducer';
import forgetPasswordReducer from '../pages/forgetPassword/forgetPassword.reducer';
import resetPasswordReducer from '../pages/resetPassword/resetPassword.reducer';
import adminReducer from '../pages/admin/admin.reducer';

const rootReducer: any = combineReducers({
    coreReducer,
    resultReducer,
    authReducer,
    profileReducer,
    myProfileReducer,
    appointmentsReducer,
    reviewsReducer,
    reviewReducer,
    patientProfileReducer,
    servicesReducer,
    registerReducer,
    patientReviewReducer,
    appointmentViewReducer,
    forgetPasswordReducer,
    resetPasswordReducer,
    adminReducer
});

export default rootReducer;
