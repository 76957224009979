import { rootState } from '@core-interface';
import { getSession as getSessionfromCookies } from '@core-const';

export const getSession = (state: rootState) => {

    if (state?.authReducer?.session) {
        return state?.authReducer?.session
    } else {
        return getSessionfromCookies()
    };
};

export const getEmergencyRequestResponse = (state: rootState) => state?.authReducer?.emergencyResponse;
export const getSuccessStatusFromReducer = (state: rootState) => state?.authReducer?.successStatus;
export const getDentistCountFromReducer = (state: rootState) => state?.authReducer?.dentistCount;
export const getEmailValidationDetailsFromReducer = (state: rootState) => state?.authReducer?.emailValidationDetails;
export const getAuthLoadingStatusFromReducer = (state: rootState) => state?.authReducer?.loading;
export const getValidateEmailLoadingStatusFromReducer = (state: rootState) => state?.authReducer?.validateEmailLoading;
export const getAuthEmailNotVerifiedStatusFromReducer = (state: rootState) => state?.authReducer?.emailNotVerified;
export const getAuthNotVerifiedEmailFromReducer = (state: rootState) => state?.authReducer?.notVerifiedEmail;
export const getValidationLinkLoadingStatusFromReducer = (state: rootState) => state?.authReducer?.linkgetLoading;
export const getValidationLinkStatusFromReducer = (state: rootState) => state?.authReducer?.verficationLinkGetStatus;
export const getSocialUserFetchingStatusFromReducer = (state: rootState) => state?.authReducer?.authorizeUserFetching; 
export const getLoggedUserFetchingStatusFromReducer = (state: rootState) => state?.authReducer?.fetchingUserDetails; 
export const getLoggedUserFetchedStatusCodeFromReducer = (state: rootState) => state?.authReducer?.loginStatusCode; 
