import { ProfileReducer } from "@core-interface";
import {
  SET_PROFILE_DATA,
  SET_UPDATED_PROFILE_DATA,
  SET_REVIEW_PROFILE_DATA,
  SET_ORGANIZATION_ID,
  UPDATE_PROFILE_DATA,
  GET_PRACTICENAME_AVAILABILITY,
  SET_PRACTICENAME_AVAILABILITY,
} from "./profile.actions";
import {
  SET_TREATMENT_SERVICES_DATA,
  SET_UPDATED_TREATMENT_SERVICES_DATA,
  SET_PROFILE_TREATMENT_SERVICES_DATA,
  SET_DELETED_TREATMENT_SERVICES_DATA,
  UPDATE_TREATMENT_SERVICES_DATA,
  RESET_TREATMENT_LOADING_STATUS,
  GET_CREATE_TREATMENT_SERVICES_DATA,
  CREATE_TREATMENT_SERVICES_DATA,
} from "./treatments/treatments.actions";

const initState: ProfileReducer = {
  organizationID: undefined,
  profileData: {},
  treatmentServices: [],
  selectedTreatmentServices: [],
  reviewProfileData: [],
  addTreatmentTypeLoading: false
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: { ...state.profileData, ...payload },
      };
    case UPDATE_TREATMENT_SERVICES_DATA:
    case CREATE_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        addTreatmentTypeLoading: true,
      };
    case SET_REVIEW_PROFILE_DATA:
      return {
        ...state,
        reviewProfileData: { ...state.reviewProfileData, ...payload },
      };
    case SET_UPDATED_PROFILE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          Data: { ...state.profileData.Data, ...payload },
        },
      };
    case SET_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        treatmentServices: payload,
      };
    case SET_PROFILE_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        selectedTreatmentServices: payload,
      };
    case GET_CREATE_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        addTreatmentTypeLoading: false,
        selectedTreatmentServices: [
          payload,
          ...state.selectedTreatmentServices
        ],
      };
    case SET_UPDATED_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        addTreatmentTypeLoading: false,

        selectedTreatmentServices: [
          ...state.selectedTreatmentServices.map((treamtent: any) => {
            if (treamtent.Id === payload.Id) {
              return payload
            } else return treamtent
          }
          ),
        ],
      };
    case SET_DELETED_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        selectedTreatmentServices: [
          ...state.selectedTreatmentServices.filter(
            (data: any) => data.Service_EN != payload.Service_EN
          ),
        ],
      };
    case GET_PRACTICENAME_AVAILABILITY:
      return {
        ...state,
        practiceNameExistLoading: true
      };
    case SET_PRACTICENAME_AVAILABILITY:
      return {
        ...state,
        practiceNameExistLoading: false,
        practiceNameExist: payload
      };
    case SET_DELETED_TREATMENT_SERVICES_DATA:
      return {
        ...state,
        selectedTreatmentServices: [
          ...state.selectedTreatmentServices.filter(
            (data: any) => data.Service_EN != payload.Service_EN
          ),
        ],
      };

    case SET_ORGANIZATION_ID:
      return {
        ...state,
        ...payload
      }
    case RESET_TREATMENT_LOADING_STATUS:
      return {
        ...state,
        addTreatmentTypeLoading: false
      }
    default:
      break;
  }
  return state;
};

export default reducer;
