import { memo, useEffect, useState } from "react";
import * as SC from "./footer.styled";
import { Message } from "@localization";
import { useSelector } from "react-redux";
import {
    getLocalizationLanguage,
    getLocalizationList,
} from "../localization/localization.selector";
import { Image } from "app-styled-components";

interface FooterProps {
    noMargin? : boolean
};

const Footer = ({noMargin}: FooterProps) => {

    const lang = useSelector(getLocalizationLanguage);
    const langList = useSelector(getLocalizationList);
    const [footerUrls, setfooterUrls] = useState({});
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        if (Object.keys(langList).length > 0) {
            setfooterUrls({
                privacy: langList[lang]?.privacy_url,
                terms: langList[lang]?.terms_url,
                help: langList[lang]?.help_url,
                cookies: langList[lang]?.cookies_url,
                legalNotice: langList[lang]?.legalNotice_url,
            });
            setImageSrc(langList[lang]?.imageSrc_logo);
        }
    }, [JSON.stringify(langList), lang]);

    return (
        <SC.FooterWrapper noMargin={noMargin}>
            <SC.ContactContainer>
                <SC.SmylorLogo>
                    <Image src={imageSrc} />
                </SC.SmylorLogo>
                <SC.ContactItem>
                    <SC.ContactItemInfoContainer>
                        <SC.ContactLabel>
                            <Message
                                messageKey={"our_address"}
                                defaultMessage={"Our Address"}
                            />
                        </SC.ContactLabel>
                        <SC.ContactValue>
                            <Message
                                messageKey={"address_main_line_company_name"}
                                defaultMessage={"Smylor Ltd"}
                            />
                        </SC.ContactValue>
                        <SC.ContactValue>
                            <Message
                                messageKey={"address_main_line"}
                                defaultMessage={"Letterkenny Institute of Tech"}
                            />
                        </SC.ContactValue>
                        <SC.ContactValue>
                            <Message
                                messageKey={"address_line_1"}
                                defaultMessage={"Letterkenny"}
                            />
                        </SC.ContactValue>
                        <SC.ContactValue>
                            <Message
                                messageKey={"address_line_2"}
                                defaultMessage={"Co.Donegal, F92 C5XK"}
                            />
                        </SC.ContactValue>
                        <SC.ContactValue>
                            <Message
                                messageKey={"address_main_3"}
                                defaultMessage={"Ireland"}
                            />
                        </SC.ContactValue>
                    </SC.ContactItemInfoContainer>
                </SC.ContactItem>
                <SC.ContactItem>
                    <SC.ContactItemInfoContainer>
                        <SC.ContactLabel>
                            <Message messageKey={"call_us"} defaultMessage={"Call Us"} />
                        </SC.ContactLabel>
                        <SC.ContactValue>
                            IE: &nbsp;
                            <Message
                                messageKey={"company_phone"}
                                defaultMessage={"+353 01 9079640"}
                            />
                        </SC.ContactValue>
                        <SC.ContactValue>
                            CH: &nbsp;
                            <Message
                                messageKey={"company_phone_ch"}
                                defaultMessage={"+41 43 505 12 55"}
                            />
                        </SC.ContactValue>
                    </SC.ContactItemInfoContainer>
                </SC.ContactItem>
                <SC.ContactItem>
                    <SC.ContactItemInfoContainer>
                        <SC.ContactLabel>
                            <Message messageKey={"our_mail"} defaultMessage={"Our Mail"} />
                        </SC.ContactLabel>
                        <SC.ContactValue>
                            <Message
                                messageKey={"company_email"}
                                defaultMessage={"info@smylor.com"}
                            />
                        </SC.ContactValue>
                    </SC.ContactItemInfoContainer>
                </SC.ContactItem>
            </SC.ContactContainer>
            <SC.FooterBreaker/>
            <SC.CopyrightsContainer>
                <SC.LeftContainer>
                    © Smylor 2023
                    <SC.Separator></SC.Separator>
                    <Message
                        messageKey={"all_rights_reserved"}
                        defaultMessage={"All Rights Reserved"}
                    />
                </SC.LeftContainer>
                <SC.RightContainer>
                    <SC.FooterLink href={'./privacy'}>
                        <Message messageKey={'privacy'} defaultMessage={'Privacy'} />
                    </SC.FooterLink>
                    <SC.Separator>|</SC.Separator>
                    <SC.FooterLink href={'./terms'}>
                        <Message messageKey={'terms'} defaultMessage={'Terms'} />
                    </SC.FooterLink>
                    <SC.Separator>|</SC.Separator>
                    <SC.FooterLink href={lang === "De_ch" ? process.env.REACT_APP_COOKIE_DE_URL : process.env.REACT_APP_COOKIE_EN_URL}>
                        <Message messageKey={'footer_url_cookies'} defaultMessage={'Cookies Policy'} />
                    </SC.FooterLink>
                    <SC.Separator>|</SC.Separator>
                    <SC.FooterLink href={Object.keys(footerUrls).length > 0 ? footerUrls['legalNotice'] : undefined}>
                        <Message messageKey={'footer_url_legal_notice'} defaultMessage={'Legal Notice'} />
                    </SC.FooterLink>
                </SC.RightContainer>
            </SC.CopyrightsContainer>
        </SC.FooterWrapper>
    );
};

export default memo(Footer);
