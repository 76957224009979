import styled, { css } from 'styled-components';

export default styled.img`
  width: 100%;
  height: 100%;
  background-size: 100%;
  ${({ width: contentWidth }) =>
    contentWidth &&
    css`
      height: auto;
      width: ${contentWidth};
    `}

  ${({ height: contentHeight }) =>
    contentHeight &&
    css`
      width: auto;
      height: ${contentHeight};
    `}
`;
