import { AdminNavigationType } from "@core-interface";
import { faFileCheck, faPowerOff } from "@fortawesome/pro-duotone-svg-icons";

export const AdminProfileNavigation =  (): Array<AdminNavigationType> => {

  return [
    {
      text: 'Reviews',
      id: 'reviews',
      url: 'reviews',
      icon: faFileCheck,
    },
    {
      text: 'Logout',
      id: 'logout',
      url: '',
      icon: faPowerOff,
    }
  ];
};
