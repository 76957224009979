import styled, { css } from 'styled-components';

export default styled.label`
  text-transform: capitalize;
  ${({ theme }) => css`
    color: ${theme['color-text']};
    font-size: ${theme['fontSize-elementLabel']};
    margin:0.25rem ${theme['margin-elementSmall']} 0.05rem;
  `}
`;
