import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, groupBy, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { httpGet, httpPost } from "@core-http";
import { addNotification } from "@core-action";
import { NotificationType } from "@core-interface";
import {
  GET_PATIENT_PROFILE_DATA,
  UPDATE_PATIENT_PROFILE_DATA,
  setPatientProfileData,
  setUpadatedPatientProfileData,
  GET_DENTIST_LIST_DATA,
  setDentistListData,
  setProfileImage,
  GET_PROFILE_IMAGE,
} from "./patientProfile.actions";

export const epicGetPatientProfieData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PATIENT_PROFILE_DATA),
    switchMap(({ payload }) =>
      httpGet({
        call: `patient/GetPatientDetails?id=${payload}`,
      }).pipe(map(({ Data }: any) => setPatientProfileData(Data)))
    )
  );
};

export const epicGetDentistListData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_DENTIST_LIST_DATA),
    switchMap(({ payload }) =>
      httpGet({
        call: `dentist/SearchByName?query=${payload}`,
      }).pipe(map(({ Data }: any) => setDentistListData(Data)))
    )
  );
};

export const epicUpdatePatientProfieData = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_PATIENT_PROFILE_DATA),
    switchMap(({ payload }) =>
      httpPost({
        call: `patient/UpdatePatientDetails`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }: any) => {
          if (Success) {
            return of(
              setUpadatedPatientProfileData(Data),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_profile_details_updated",
                message: "Successfully Profile Details Updated",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_updating_profile_details",
                message: "Error Updating Profile Details",
              })
            );
          }
        })
      )
    )
  );
};

export const epicUpdatePatientProfiePhoto= (action$, state$) => {
  return action$.pipe(
    ofType(GET_PROFILE_IMAGE),
    switchMap(({ payload }) =>
      httpPost({
        call: `patient/UploadProfileImage`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }: any) => {
          if (Success) {
            return of(
              setProfileImage(Data),
              addNotification({
                type: NotificationType.SUCCESS,
                messageKey: "successfully_patient_profile_image_updated",
                message: "Successfully Patient Profile Image Updated",
              })
            );
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_updating_patient_profile_image",
                message: "Error Updating Patient Profile Image",
              })
            );
          }
        })
      )
    )
  );
};

const PatientProfileEpic = combineEpics(
  epicGetPatientProfieData,
  epicUpdatePatientProfieData,
  epicGetDentistListData,
  epicUpdatePatientProfiePhoto
);

export default PatientProfileEpic;
