import React from 'react';
import { EmergencyRequestStatus } from '@core-interface';
import styled, { css } from "styled-components";
import { Message } from '@localization';
import { FlexRow } from 'app-styled/flex';
import { device } from '@core-const';

export const TitleContainer = styled(FlexRow)`

  ${({ theme }) => css`
    padding: ${theme["thm-space-"]} 20px;
    border-top-left-radius: ${theme["thm-space-md"]};
    border-top-right-radius: ${theme["thm-space-md"]};
    color: ${theme["smylor-white"]};
    font-weight: bold;
  `}/* @media ${device.tablet} {
    padding: 1rem 0.5rem;
  } ; */
`;
const colorList = [
    { color: '#fca103', lightColor: 'rgba(252,161,3, 0.1)' },
    { color: '#fca103', lightColor: 'rgba(252,161,3, 0.1)' },
    { color: '#02781e', lightColor: 'rgba(2,120,30, 0.1)' },
    { color: '#150b7d', lightColor: 'rgba(21,11,125, 0.1)' },
    { color: '#818182', lightColor: 'rgba(129, 129, 130, 0.1)' },
    { color: '#b8042e', lightColor: 'rgba(184, 4, 46, 0.1)' },
    { color: '#005963', lightColor: 'rgba(0, 89, 99, 0.1)' },
    { color: '#999999', lightColor: 'rgba(153, 153, 153, 0.1)' },
    { color: '#EE4B2B', lightColor: 'rgba(238, 75, 43, 0.1)' },
    { color: '#FF0000', lightColor: 'rgba(255, 0, 0, 0.1)' },
];

const SymlorCardTitle = ({ status }) => {

    const getValue = () => {
        switch (status) {
            case EmergencyRequestStatus.PENDING: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'pending'} defaultMessage={'Pending'} /></TitleContainer>;
            case EmergencyRequestStatus.ACCEPTED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'accepted'} defaultMessage={'Accepted'} /></TitleContainer>;
            case EmergencyRequestStatus.REPLIED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'replied'} defaultMessage={'Replied'} /></TitleContainer>;
            case EmergencyRequestStatus.EXPIRED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'expired'} defaultMessage={'Expired'} /></TitleContainer>;
            case EmergencyRequestStatus.REJECTED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'rejected'} defaultMessage={'Rejected'} /></TitleContainer>;
            case EmergencyRequestStatus.COMPLETED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'completed'} defaultMessage={'Completed'} /></TitleContainer>;
            case EmergencyRequestStatus.ALREADYTAKEN: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'already_taken'} defaultMessage={'Already Taken'} /></TitleContainer>;
            case EmergencyRequestStatus.PARTIALLYCOMPLETED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'partially_completed'} defaultMessage={'Partially Completed'} /></TitleContainer>;
            case EmergencyRequestStatus.NOTRESPONDED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'not_responded'} defaultMessage={'Not Responded'} /></TitleContainer>;
            default: return <TitleContainer><Message messageKey={'pending'} defaultMessage={'Pending'} /></TitleContainer>
        };
    };
    return getValue();
}

export default React.memo(SymlorCardTitle);
