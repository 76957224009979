import React from 'react';
import * as SC from './privacy.styled';

const EnConditionContainer = () => {
    return (
        <SC.ConditionContainer>
            <SC.MainTitile>Privacy Policy</SC.MainTitile>
            <SC.Titile>Introduction</SC.Titile>
            <SC.ParaContainer>
                Data protection has a particularly high priority for Smylor (hereinafter: "we", "us").
                We consider it our primary responsibility to maintain the confidentiality of the personal data you provide
                to us and to protect it from unauthorized access. Therefore, we use the utmost care and state-of-the-art
                security standards to ensure maximum protection of your personal data.With the information presented below,
                we provide you with an overview of the processing of your personal data that arises in connection with the
                use of our website, accessible via https://smylor.com/ (hereinafter "web").We also want to inform you
                about your rights under data protection laws. We always process your personal data in accordance with the
                General Data Protection Regulation (hereinafter "GDPR"), the Telecommunications and Telemedia Data
                Protection Act (hereinafter "TTDSG") and all applicable country-specific data protection regulations.
            </SC.ParaContainer>
            <SC.Titile>1 Responsibility</SC.Titile>
            <SC.ParaSmallDetails>The responsible controller in the context of GDPR is: Smylor Limited</SC.ParaSmallDetails>
            <SC.ParaSmallDetails>Address: 24 Rowan Park, Lismonaghan, Letterkenny, Co. Donegal, Ireland F92 N7D1</SC.ParaSmallDetails>
            <SC.ParaSmallDetails>E-mail: privacy@smylor.com</SC.ParaSmallDetails>
            <SC.ParaContainer>Website: https://smylor.com/</SC.ParaContainer>
            <SC.Titile>2 Data Protection Officer</SC.Titile>
            <SC.ParaSmallDetails>You can reach our data protection officer as follows:</SC.ParaSmallDetails>
            <SC.ParaSmallDetails>Name: Patrick McGlinchey</SC.ParaSmallDetails>
            <SC.ParaContainer>Email: privacy@smylor.com</SC.ParaContainer>
            <SC.ParaContainer>You can contact our data protection officer directly with all questions and suggestions
                regarding data protection and the exercise of your rights.
            </SC.ParaContainer>
            <SC.Titile>3 Definition</SC.Titile>
            <SC.ParaSmallDetails>This privacy policy is based on the terms of the GDPR. For your convenience,
                we would like to explain some important terms in this context:
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Personal Data: Personal data means any information relating to an identified or identifiable natural person.
                An identifiable natural person is one who can be identified, directly or indirectly, by reference to an
                identifier such as a name, an identification number, location data, an online identifier or to one or more
                factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of
                that natural person.
            </SC.ParaContainer>
            <SC.ParaContainer>
                • Data subject: The data subject is any identified or identifiable natural person whose personal data is
                processed by the controller.
            </SC.ParaContainer>
            <SC.ParaContainer>
                • Processing: Processing means any operation or set of operations which is performed upon personal data,
                whether or not by automatic means, such as collection, recording, organization, filing, storage, adaptation
                or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making
                available, alignment or combination, restriction, erasure or destruction.
            </SC.ParaContainer>
            <SC.ParaContainer>
                • Recipient: A recipient is a natural or legal person, public authority, agency, or other body to whom
                personal data are disclosed, whether or not a third party. However, authorities that may receive personal
                data in the context of a specific investigative task under Union or Member State law are not considered
                recipients.
            </SC.ParaContainer>
            <SC.ParaContainer>
                • Third Party: a third party is a natural or legal person, public authority, agency, or other body other than
                the Data Subject, the Controller, the Processor, and the persons authorized to process the Personal Data under
                the direct responsibility of the Controller or the Processor.
            </SC.ParaContainer>
            <SC.ParaContainer>
                • Consent: Consent is any expression of will in the form of a declaration or other unambiguous affirmative
                action made voluntarily by the data subject for the specific case in an informed manner and in an unambiguous
                manner, by which the data subject indicates that he or she consents to the processing of personal data
                relating to him or her.
            </SC.ParaContainer>
            <SC.Titile>4 Origin of the personal data</SC.Titile>
            <SC.ParaContainer>
                We may obtain personal information in the following ways:
            </SC.ParaContainer>
            <SC.Titile>4.1 Information provided by you</SC.Titile>
            <SC.ParaContainer>
                You have the possibility to provide information about yourself on the platform.
            </SC.ParaContainer>
            <SC.Titile>4.2 Automatically collected and generated data</SC.Titile>
            <SC.ParaContainer>
                When you use our website, we collect personal data about you.
            </SC.ParaContainer>
            <SC.Titile>5 Scope, purpose, storage period and, if applicable, recipients and third country transfer of the
                respective processing of personal data
            </SC.Titile>
            <SC.Titile>
                5.1 General information
            </SC.Titile>
            <SC.ParaContainer>
                In the following, we will give you an overview of which personal data we process. For this purpose,
                we explain to what extent and for what purposes. In addition, we indicate - if available - which
                third-party providers we use to receive your data. Finally, we inform you whether a third country transfer
                takes place in the respective processing by the third-party provider.
                The provision of your personal data is always voluntary. However, it may be that the respective
                functionality only works with your information.
                We will not disclose your personal data to third parties without your consent, unless this is
                permitted by law (e.g. because it is necessary for the performance of the contract).
            </SC.ParaContainer>
            <SC.Titile>
                5.2 Data transfers to third countries
            </SC.Titile>
            <SC.ParaContainer>
                If we transfer personal data to a third country for processing, we ensure compliance with Art. 44 et seq. GDPR.
                That means that before any transfer of personal data to third parties in a country outside the European Union
                ("EU") or the European Economic Area ("EEA"), we check whether an adequate level of protection is ensured.
                An adequate level of protection can be ensured, among other things, by the fact that an adequacy decision of the EU
                Commission is available, that we have concluded standard data protection clauses with the recipient and have taken
                further additional measures, or that the third-country transfer is permitted under other guarantees regulated in Art.
                46 et seq. GDPR. Where the data transfer is based on Art 46, 47 or 49 (1) GDPR, you may obtain from us a copy of the
                safeguards for the existence of an adequate level of data protection in relation to the data transfer or an indication
                of the availability of a copy of the safeguards. Copies of these guarantees can be requested from us.
            </SC.ParaContainer>
            <SC.Titile>
                5.3 Data deletion
            </SC.Titile>
            <SC.ParaContainer>
                The data processed by us will be deleted in accordance with the legal requirements as soon as their consents
                permitted for processing are revoked or other permissions cease to apply (e.g., if the purpose of processing
                this data has ceased to apply or they are not required for the purpose). If the data are not deleted because
                they are required for other and legally permissible purposes, their processing will be limited to these
                purposes. That means that the data will be blocked and not processed for other purposes. This applies, for
                example, to data that must be retained for reasons of commercial or tax law or whose storage is necessary for
                the assertion, exercise, or defense of legal claims or for the protection of the rights of another natural or
                legal person.
            </SC.ParaContainer>
            <SC.Titile>
                5.4 Security measures
            </SC.Titile>
            <SC.ParaContainer>
                We take appropriate technical and organizational measures to ensure a level of protection appropriate to the
                risk in accordance with the legal requirements, considering the state of the art, the implementation costs
                and the nature, scope, circumstances, and purposes of the processing, as well as the different probabilities
                of occurrence and the extent of the threat to the rights and freedoms of natural persons.
                The measures include safeguarding the confidentiality, integrity, and availability of data by controlling physical
                and electronic access to data as well as access to, input of, disclosure of, assurance of availability of,
                and segregation of data relating to you. Furthermore, we have established procedures to ensure the exercise
                of data subjects' rights, the deletion of data, and responses to data compromise.
                Furthermore, we already take the protection of personal data into account during the development
                or selection of hardware, software, and processes in accordance with the principle of data protection,
                through technology design and through data protection-friendly default settings. Further information
                can be found here: Data Security & Safety | Smylor-Dental Treatment Marketplaces.
            </SC.ParaContainer>
            <SC.Titile>
                5.5 Transfer of personal data
            </SC.Titile>
            <SC.ParaContainer>
                During our processing of personal data, it may happen that the data is transferred to other bodies, companies,
                legally independent organizational units, or persons or that it is disclosed to you. Recipients of this data
                may include, for example, service providers commissioned with IT tasks or providers of services and content
                that are integrated into a web app. In such cases, we comply with the legal requirements and conclude
                corresponding contracts or agreements with the recipients of the data that serve to protect your data.
            </SC.ParaContainer>
            <SC.Titile>
                6 The processing of your personal data
            </SC.Titile>
            <SC.Titile>
                6.1.1 Provision of the Website
            </SC.Titile>
            <SC.ParaSmallDetails>
                When you visit our website, data is automatically processed that your browser transmits to our server.
                This general data and information is stored in the server's log files (in so-called "server log files").
                The following can be collected:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Browser type and version
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Operating system used
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Referrer URL (previously visited website)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Host name of the accessing computer
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the server request
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                As a hosting provider we use TelemaxX Telekommunikation GmbH, whose servers are located in Germany for
                our German and other EU country purposes.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                We also use Datasource AG, Bösch 69, CH-6331 Hünenberg in Switzerland for our Swiss country purposes.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.2 Purpose of the processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                When using this general data and information, we do not draw any conclusions about you.
                The purposes pursued by us include in particular:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • the guarantee of a smooth connection set-up of the website,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • the clarification of acts of abuse or fraud,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • problem analysis in the network, and
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • the evaluation of system security and stability.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.3 Legal basis
            </SC.Titile>
            <SC.ParaContainer>
                The legal basis for data processing is our legitimate interest within the meaning of Art.
                6 (1) (f) GDPR. We have an overriding legitimate interest in being able to offer our service in a
                technically flawless manner.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                The log files are stored for security reasons (e.g., for the clarification of abuse or fraud) for a
                maximum of 7 days and then deleted. Data whose further retention is required for evidentiary purposes
                will be retained until the matter has been finally clarified.
            </SC.ParaContainer>
            <SC.Titile>
                6.1.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                We use the following service providers: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.2 Registration in the web app/ Creation of a user account
            </SC.Titile>
            <SC.Titile>
                6.2.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                To use our web app, you must first register. Therefore, we process the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address (anonymized)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Username (freely selectable)
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • E-mail address
            </SC.ParaContainer>
            <SC.Titile>
                6.2.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                The purpose of the processing is to perform authentication and manage your user account.
            </SC.ParaContainer>
            <SC.Titile>
                6.2.3 Legal basis
            </SC.Titile>
            <SC.ParaContainer>
                The legal basis for the data processing is the fulfillment of the contract concluded with you within
                the meaning of Art. 6 (1) (b) GDPR. When using the single sign-on procedure with a third-party service,
                the legal basis is your consent within the meaning of Art. 6 (1) (a) GDPR.
            </SC.ParaContainer>
            <SC.Titile>
                6.2.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data that we collect in connection with the registration of the web app as soon as it is no longer required to achieve the purpose for which it was collected. This is the case at the latest when you have deleted your account and one year has passed.
            </SC.ParaContainer>
            <SC.Titile>
                6.2.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                We use the following recipients: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.3 Use of the platform
            </SC.Titile>
            <SC.Titile>
                6.3.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                We can provide you with the benefits of our platform if certain required personal data is collected. This includes the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address (anonymized)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the retrieval and the amount of data transferred and the message whether the data exchange was complete.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Time zone
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Web app crash information
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Browser type and operating system
            </SC.ParaContainer>
            <SC.Titile>
                6.3.2 Purpose of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                The purposes we pursue include, in particular:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Technical operation of the website
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Ensuring smooth connection establishment of the web app,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Investigation of acts of abuse or fraud,
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Problem analyses in the network, as well as
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • the evaluation of system security and stability.
            </SC.ParaContainer>
            <SC.Titile>
                6.3.3 Legal basis
            </SC.Titile>
            <SC.ParaContainer>
                The legal basis for data processing is our legitimate interest within the meaning of Art. 6 (1) (f) GDPR. We have an overriding legitimate interest in being able to offer our service in a technically flawless manner.
            </SC.ParaContainer>
            <SC.Titile>
                6.3.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data that we collect in connection with use as soon as it is no longer necessary to achieve the purpose for which it was collected. This is the case at the latest when you have deleted your account and one year has passed.
            </SC.ParaContainer>
            <SC.Titile>
                6.3.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                We use the following providers: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4 Book an appointment
            </SC.Titile>
            <SC.Titile>
                6.4.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the possibility to book an appointment via our service. Therefore, we process the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Gender
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date of Birth
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Telephone Number
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Treatment
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Practioner Name
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content
            </SC.ParaContainer>
            <SC.Titile>
                6.4.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your data to enable the booking of an appointment with your dentist.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.3 Legal basis
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which they were collected. In the context of contact inquiries, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                To enable your booking, the respective dentist, you made an appointment with, receives your personal data as stated above, to fix the appointment.
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6 Support and contact options
            </SC.Titile>
            <SC.Titile>
                6.4.6.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                In the context of contacting, you and answering your inquiry, we process the following personal data: You have the possibility to contact us via e-mail (privacy@smylor.com).
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your data to respond to your inquiry and other matters arising from it.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.3 Legal basis
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which it was collected. In the context of contact inquiries, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.6.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7 Chatbot
            </SC.Titile>
            <SC.Titile>
                6.4.7.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the possibility to converse with us or a Smylor registered dentist via a chatbot on our website(s).
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                In the context of supporting the chat conversation between you and us, or a Smylor registered dentist, we may process the following personal data:
            </SC.ParaContainer>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date of Birth
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • IP address (anonymized)
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content (including potential personal dental health information, which we will request additional permission to process)
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your data to respond to your inquiry and other matters arising from it. Additionally, if you consent to, we can use your provided data to create a profile automatically.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.3 Legal basis
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If you have not logged in as a Smylor user, but at the end of the conversation you consent to register, our legal basis is Art. 6 (1) a GDPR.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.4 Storage period
            </SC.Titile>
            <SC.ParaSmallDetails>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which it was collected. In the context of chat conversations, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                At the end of a conversation, if you have not logged in or agreed to register with Smylor, any conversations will be treated as anonymous and will be deleted within 7 days.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.7.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8 Dentist Practice Registration
            </SC.Titile>
            <SC.Titile>
                6.4.8.1 Scope of processing
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the possibility to contact us via e-mail (privacy@smylor.com). In the context of you registering with us we process the following personal data:
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • First and last name (if stated by you)
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • E-Mail address
            </SC.ParaSmallDetails>
            <SC.ParaSmallDetails>
                • Date and time of the request
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                • Communication content
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.2 Purpose of processing
            </SC.Titile>
            <SC.ParaContainer>
                We process your public domain email address to identify you as a legitimate dental practice as part of the registration process. This public domain email address will be used for two factor authentication as part of validating the registration process.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.3 Rechtsgrundlage
            </SC.Titile>
            <SC.ParaSmallDetails>
                If your request is based on pre-contractual measures or an existing contract with us, the legal basis is the performance of the contract and the implementation of pre-contractual measures pursuant to Art. 6 (1) (b) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If your request is made independently of pre-contractual measures or existing contracts with us, our overriding legitimate interests pursuant to Art. 6 (1) (f) GDPR constitute the legal basis. We have an overriding legitimate interest in providing users of our application with a possibility by which they can contact us.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.4 Storage period
            </SC.Titile>
            <SC.ParaContainer>
                We delete your personal data as soon as it is no longer required to achieve the purpose for which it was collected. In the context of contact inquiries, this is generally the case when the circumstances indicate that the specific matter has been conclusively processed.
            </SC.ParaContainer>
            <SC.Titile>
                6.4.8.5 Recipients of personal data
            </SC.Titile>
            <SC.ParaContainer>
                Please find the recipients here: Smylor Data Sub-Processors
            </SC.ParaContainer>
            <SC.Titile>
                7 Your rights
            </SC.Titile>
            <SC.ParaContainer>
                In this section, we inform you about the rights you have regarding the processing of your data. The exact scope of the right mentioned in each case can be found in the corresponding article of the General Data Protection Regulation (GDPR). Data subject inquiries should generally be addressed to us or our data protection officer via e-mail to privacy@smylor.com.
            </SC.ParaContainer>
            <SC.Titile>
                7.1 Right to confirmation
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to request confirmation from us as to whether personal data concerning you is being processed.
            </SC.ParaContainer>
            <SC.Titile>
                7.2 Information (Art. 15 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to receive information from us at any time free of charge about the personal data stored about you, as well as a copy of this data in accordance with the statutory provisions.
            </SC.ParaContainer>
            <SC.Titile>
                7.3 Rectification (Art. 16 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to request the rectification of inaccurate personal data concerning you. You also have the right to request that incomplete personal data be completed, considering the purposes of the processing.
            </SC.ParaContainer>
            <SC.Titile>
                7.4 Erasure (Art. 17 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to demand that personal data concerning you be deleted without delay if one of the reasons provided for by law applies and insofar as the processing or storage is not necessary.
            </SC.ParaContainer>
            <SC.Titile>
                7.5 Restriction of processing (Art. 18 GDPR)
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to request that we restrict processing if one of the legal requirements is met.
            </SC.ParaContainer>
            <SC.Titile>
                7.6 Data portability (Art. 20 GDPR)
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the right to receive the personal data concerning you that you have provided to us in a structured, common, and machine-readable format. Furthermore, you have the right to transfer this data to another controller without hindrance by us, to whom the personal data was provided, provided that the processing is based on consent pursuant to Art. 6 (1) (a) GDPR or Art. 9 (2) (a) GDPR or on a contract pursuant to Art. 6 (1) (b) GDPR. Art. 6 (1) (b) GDPR and the processing is carried out with the aid of automated procedures, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in us.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                In addition, when exercising your right to data portability pursuant to Article 20 (1) GDPR, you have the right to have the personal data transferred directly from one controller to another controller, to the extent that this is technically feasible and provided that this does not adversely affect the rights and freedoms of other individuals.
            </SC.ParaContainer>
            <SC.Titile>
                7.7 Objection (Art. 21 GDPR)
            </SC.Titile>
            <SC.ParaSmallDetails>
                You have the right to object at any time, on grounds relating to your situation, to the processing of personal data concerning you which is carried out on the basis of data processing in the public interest pursuant to Art. 6 (1) (e) GDPR or on the basis of our legitimate interest pursuant to Art. 6 (1) (f) GDPR.
            </SC.ParaSmallDetails>
            <SC.ParaContainer>
                If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of legal claims.
            </SC.ParaContainer>
            <SC.Titile>
                7.8 Revocation of consent under data protection law
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to revoke your consent to the processing of personal data at any time with effect for the future.
            </SC.ParaContainer>
            <SC.Titile>
                7.9 Complaint to a supervisory authority
            </SC.Titile>
            <SC.ParaContainer>
                You have the right to lodge a complaint about our processing of personal data with a supervisory authority responsible for data protection.
            </SC.ParaContainer>
            <SC.Titile>
                8 Up-to-datedness and changes of the privacy policy
            </SC.Titile>
            <SC.ParaContainer>
                This privacy notice is currently valid and has the following status: June 2023.
            </SC.ParaContainer>
        </SC.ConditionContainer>
    )
}

export default EnConditionContainer