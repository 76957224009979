import { ofType, combineEpics } from "redux-observable";
import { switchMap, map } from "rxjs/operators";
import { httpGet, httpPost } from "@core-http";
import { GET_DENTIST_REVIEWS_DATA, GET_REVIEWS_DATA, setDentistProfileReviewsData, setProfileReviewsData } from "./reviews.actions";

export const epicGetDentistProfielReviewsData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_DENTIST_REVIEWS_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentreview/GetReviewSummeryForDentist`,
        data: payload
      }).pipe(
        map(({ Data, Total }: any) =>
        setDentistProfileReviewsData({ Data, Total, page : payload.PageNumber })
        )
      )
    )
  );
};

export const epicGetProfielReviewsData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_REVIEWS_DATA),
    switchMap(({ payload: { id, take, page } }) =>
      httpGet({
        call: `TreatmentReview/GetUserReviewSummery?${id}&take=${take}&page=${page}`,
      }).pipe(
        map(({ Data, Total }: any) =>
          setProfileReviewsData({ Data, Total, page })
        )
      )
    )
  );
};

const ProfielReviewsEpic = combineEpics(
  epicGetProfielReviewsData,
  epicGetDentistProfielReviewsData
  );

export default ProfielReviewsEpic;
