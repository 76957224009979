import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, mergeMap } from "rxjs/operators";
import { httpGet, httpPost } from "@core-http";
import {
  GET_TREATMENT_SERVICES_DATA,
  GET_PROFILE_TREATMENT_SERVICES_DATA,
  CREATE_TREATMENT_SERVICES_DATA,
  UPDATE_TREATMENT_SERVICES_DATA,
  DELETE_TREATMENT_SERVICES_DATA,
  setTreatmentServicesData,
  setUpdatedTreatmentServicesData,
  setProfileTreatmentServicesData,
  setDeletedTreatmentServicesData,
  resetTreatmentLaoding,
  getCreateTreatmentServicesData,
} from "./treatments.actions";
import { of } from "rxjs";
import { NotificationType } from "@core-interface";
import { addNotification } from "@core-action";

export const epicGetTreatmentServicesResults = (action$, state$) => {
  return action$.pipe(
    ofType(GET_TREATMENT_SERVICES_DATA),
    switchMap(() =>
      httpGet({
        call: `treatmentservice/GetAllTreatmentServices`,
      }).pipe(map(({ Data }: any) => setTreatmentServicesData(Data)))
    )
  );
};
export const epicGetSelectedTreatmentServices = (action$, state$) => {
  return action$.pipe(
    ofType(GET_PROFILE_TREATMENT_SERVICES_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentservice/GetTreatmentsByProfile`,
        data: payload,
      }).pipe(
        map(
          ({ Data, Success }: any) =>
            Success && setProfileTreatmentServicesData(Data)
        )
      )
    )
  );
};
export const epicCreateTreatmentServicesResults = (action$, state$) => {
  return action$.pipe(
    ofType(CREATE_TREATMENT_SERVICES_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentservice/CreateTreatmentService`,
        data: payload,
      }).pipe(mergeMap(({ Success, Data }: any) => {
        if (Success) {
          return of(
            getCreateTreatmentServicesData(Data),
            addNotification({
              type: NotificationType.SUCCESS,
              messageKey: "successfully_treatment_service_created",
              message: "Successfully treatment service created",
            })
          );
        } else {
          return of(
            resetTreatmentLaoding(),
            addNotification({
              type: NotificationType.ERROR,
              messageKey: "error_creating_treatment_service",
              message: "Error creating treatment service",
            })
          );
        }
      })
      )
    )
  );
};
export const epicUpdateTreatmentServicesResults = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_TREATMENT_SERVICES_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentservice/UpdateTreatmentService`,
        data: payload,
      }).pipe(mergeMap(({ Success, Data }: any) => {
        if (Success) {
          return of(
            setUpdatedTreatmentServicesData(payload),
            addNotification({
              type: NotificationType.SUCCESS,
              messageKey: "successfully_treatment_service_updated",
              message: "Successfully treatment service updated",
            })
          );
        } else {
          return of(
            resetTreatmentLaoding(),
            addNotification({
              type: NotificationType.ERROR,
              messageKey: "error_updating_treatment_service",
              message: "Error Updating treatment service",
            })
          );
        }
      })
      )
    )
  );
};
export const epicDeleteTreatmentServicesResults = (action$, state$) => {
  return action$.pipe(
    ofType(DELETE_TREATMENT_SERVICES_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentservice/DeleteTreatmentService`,
        data: payload,
      }).pipe(mergeMap(({ Success, Data }: any) => {
        if (Success) {
          return of(
            setDeletedTreatmentServicesData(payload),
            addNotification({
              type: NotificationType.SUCCESS,
              messageKey: "successfully_treatment_service_deleted",
              message: "Successfully treatment service deleted",
            })
          );
        } else {
          return of(
            addNotification({
              type: NotificationType.ERROR,
              messageKey: "error_deleting_treatment_service",
              message: "Error Deleting treatment service",
            })
          );
        }
      })
      )
    )
  );
};

const TreatmentsEpic = combineEpics(
  epicGetTreatmentServicesResults,
  epicGetSelectedTreatmentServices,
  epicCreateTreatmentServicesResults,
  epicUpdateTreatmentServicesResults,
  epicDeleteTreatmentServicesResults
);

export default TreatmentsEpic;
