import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, mergeMap } from "rxjs/operators";
import { httpGet, } from "@core-http";
import { GET_APPOINTMENT_DATA, UPDATE_APPOINTMENT_STATUS, setAppointmentData, updateAppointmentStatusSuccess } from "./appointmentCommon.actions";
import { httpPostPayment } from "app-core/httpCall/httpPayment";
import { of } from "rxjs";
import { addNotification } from "@core-action";
import { NotificationType } from "@core-interface";

export const epicGetAppointmentData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_APPOINTMENT_DATA),
    switchMap(({ payload }: any) =>
      httpGet({
        call: `treatmentservice/GetBookingById?clientId=${payload.clientId}&bookingId=${payload.bookingId}`,
      }).pipe(
        map(({ Data }: any) => setAppointmentData(Data))
      )
    )
  );
};

export const epicUpdateAppointmentStatus = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_APPOINTMENT_STATUS),
    switchMap(({ payload }: any) =>
      httpPostPayment({
        call: `Payment/Update-payment-status`,
        data: payload,
      }).pipe(
        mergeMap((Data: any) => {
          if (Data?.appoinmentStatus) {
            return of(updateAppointmentStatusSuccess(Data),
              addNotification({ type: NotificationType.SUCCESS, messageKey: 'update_appointment_status_success', message: 'Successfully appointment status updated.!' })
            );
          } else {
            return of(addNotification({ type: NotificationType.ERROR, messageKey: 'update_appointment_status_failed', message: 'Appointment status update failed.!' }));
          }
        })
      )
    )
  );
};

const AppointmentViewDataEpic = combineEpics(
  epicGetAppointmentData,
  epicUpdateAppointmentStatus
);

export default AppointmentViewDataEpic;