import { ResetPasswordReducer } from "@core-interface";
import {
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_SUCCESS
} from './resetPassword.action';

const initState: ResetPasswordReducer = {
    loading: false,
    status: null
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_USER_PASSWORD:
            return {
                ...state,
                loading: true,
                status: null
            };
        case UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                status: payload
            };
        default:
            break;
    }
    return state;
}

export default reducer;