import { ofType, combineEpics } from "redux-observable";
import { switchMap, mergeMap, map } from "rxjs/operators";
import { of } from "rxjs";
import { httpGet, httpPost } from "@core-http";
import { GET_ADMIN_REVIEWS_DATA, GET_ADMIN_REVIEW_DATA, SUBMIT_ADMIN_REVIEW_DATA, setAdminReviewData, setAdminReviewsData, updateAdminReviewData } from "./admin.actions";
import { NotificationType, ReviewTreatmentStatus } from "@core-interface";
import { addNotification } from "@core-action";

export const epicAdminReviewList = (action$, state$) => {
  return action$.pipe(
    ofType(GET_ADMIN_REVIEWS_DATA),
    switchMap(
      ({ payload }: any) =>
        httpPost({
          call: `treatmentreview/getreviewsforapproval`,
          data: payload
        }).pipe(
          mergeMap(({ Data, Total, Success }: any) => {
            if (Success) return of(setAdminReviewsData({ Data, Total, Page: payload.PageNumber }));
            else return of(setAdminReviewsData({ Data: [], Total: 0, Page: payload.PageNumber }))
          })
        )
    )
  );
};

export const epicAdminGetReviewData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_ADMIN_REVIEW_DATA),
    switchMap(({ payload }: any) =>
      httpGet({
        call: `TreatmentReview/GetTreatmentReviewsById/${payload}`,
      }).pipe(map(({ Data }: any) => setAdminReviewData(Data)))
    )
  );
};

export const epicSubmitAdminReviewData = (action$, state$) => {
  return action$.pipe(
    ofType(SUBMIT_ADMIN_REVIEW_DATA),
    switchMap(({ payload }: any) =>
      httpPost({
        call: `treatmentreview/takereviewaction`,
        data: payload,
      }).pipe(
        mergeMap(({ Success, Data }: any) => {
          if (Success) {
            if (payload.Status === ReviewTreatmentStatus.ACCEPTED) {
              return of(
                updateAdminReviewData(payload),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: "successfully_review_added",
                  message: "Successfully Review Added",
                })
              );
            } else if (payload.Status === ReviewTreatmentStatus.REJECTED) {
              return of(
                updateAdminReviewData(payload),
                addNotification({
                  type: NotificationType.SUCCESS,
                  messageKey: "successfully_review_rejected",
                  message: "Successfully Review Rejected",
                })
              );
            }
          } else {
            return of(
              addNotification({
                type: NotificationType.ERROR,
                messageKey: "error_submitting_review",
                message: "Error on adding the Review",
              })
            );
          }
        })
      )
    )
  );
};

const AdminEpic = combineEpics(
  epicAdminReviewList,
  epicAdminGetReviewData,
  epicSubmitAdminReviewData
)

export default AdminEpic;