import { SubTitle } from "app-styled-components";
import styled, { css } from "styled-components";
import { device } from "@core-const";
import { FlexCol, FlexRow } from "app-styled/flex";

export const ProfileContentWrapper = styled(FlexCol)`
  height: calc(100vh - 81px);
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  ${({ theme }) => css`
    padding: ${theme["thm-space-xl"]};
    gap: ${theme["thm-space-lg"]};

    @media ${device.tablet} {
      padding: ${theme["thm-space-lg"]} ${theme["thm-space-rg"]};
    }
  `}
`;

export const ProfileContentTitleWrapper = styled(FlexRow)`
  width: 100%;
  flex-wrap: wrap;
  ${({ theme }) => css`
    padding: ${theme["thm-space-xl"]} 20px;
    border-radius: ${theme["thm-space-md"]};
    background-color: ${theme["smylor-light-blueC"]};
  `}
`;
export const ProfileContentDataWrapper = styled(FlexCol)`
  width: 100%;

  ${({ theme }) => css`
    margin-bottom: ${theme["thm-space-lg"]};
    border-radius: ${theme["thm-space-md"]};
    /* box-shadow: ${theme["smylor-light-blueC"]} 0 0 2px,
      ${theme["smylor-light-blueC"]} 0 12px 24px -4px; */
  `}/* @media ${device.tablet} {
    padding: 1rem 0.5rem;
  } ; */
`;
export const ProfileContentDataContainer = styled.div`

  ${({ theme }) => css`
    padding: ${theme["thm-space-xl"]} 0px;

    @media ${device.tablet} {
      padding: ${theme["thm-space-lg"]} 0px;
    }
  `}
`;

const ProfileContentPageContainer = ({ title, children }: {title: {mainTitle: any; titleChild?: any}, children: any}) => {
  return (
    <ProfileContentWrapper>
      <ProfileContentTitleWrapper>
        <SubTitle bold>{title.mainTitle}</SubTitle>
        {title.titleChild}
      </ProfileContentTitleWrapper>
      <ProfileContentDataContainer>
        <ProfileContentDataWrapper>{children}</ProfileContentDataWrapper>
      </ProfileContentDataContainer>
    </ProfileContentWrapper>
  );
};

export default ProfileContentPageContainer;
