import styled, { css } from "styled-components";
import { device } from "@core-const";
import { FlexCol } from "app-styled/flex";
import { LinkButtonSmall } from "app-styled/link";

export const SocialMediaContainer = styled(FlexCol)`
  width: 100%;
  margin-top: 10px;
  ${({ theme }) => css`
    gap: 24px;
  `};
`;
export const FaceBookLoginButton = styled(LinkButtonSmall)`
  background: #4267b2;
  color: #fff !important;
  text-transform: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]};
    .loading-wrapper {
      padding: ${theme["thm-space-md"]} 0;
    }
  `};
  ${({ overlayLoading }) =>
    !overlayLoading &&
    css`
      margin: 0px;
    `};
  .facebook-icon {
    font-size: 28px;
  }
`;

export const GoogleLoginButton = styled(LinkButtonSmall)`
  text-transform: none;
  color: #000 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]};
    .loading-wrapper {
      padding: ${theme["thm-space-md"]} 0;
    }
  `};
  ${({ overlayLoading }) =>
    !overlayLoading &&
    css`
      margin: 0px;
    `};
  .google-icon {
    width: 28px;
    height: 28px;
  }
`;

export const SocialButtonText = styled.div`
  flex-grow: 1;
`;
