import { Breadcrumb, GeoLocation, NotificationMessage, Settings } from '@core-interface';

export const SET_BUSSY = 'SET_BUSSY';
export const setBussy = payload => ({ type: SET_BUSSY, payload });

export const SET_MAP_LOADED = 'SET_MAP_LOADED';
export const setMapLoaded = payload => ({ type: SET_MAP_LOADED, payload });

export const SET_FILTER = 'SET_FILTER';
export const setFilter = payload => ({ type: SET_FILTER, payload });

export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const setBreadcrumb = (payload: Breadcrumb[]) => ({ type: SET_BREADCRUMB, payload });

export const GET_LOCALIZATION_DATA = 'GET_LOCALIZATION_DATA';
export const getLocalizationData = () => ({ type: GET_LOCALIZATION_DATA });

export const SET_LOCALIZATION_DATA = 'SET_LOCALIZATION_DATA';
export const setLocalizationData = (payload: any) => ({ type: SET_LOCALIZATION_DATA, payload });

export const SET_LOCALIZATION_LANGUAGE = 'SET_LOCALIZATION_LANGUAGE';
export const setLocalizationlanguage = (payload: string) => ({ type: SET_LOCALIZATION_LANGUAGE, payload });

export const SET_GEO_LOCATION = 'SET_GEO_LOCATION';
export const setGeoLocation = (payload: GeoLocation) => ({ type: SET_GEO_LOCATION, payload });

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const addNotification = (payload: NotificationMessage) => ({ type: ADD_NOTIFICATION, payload });

export const SET_SETTINGS = 'SET_SETTINGS';
export const setSettings = (payload: Settings) => ({ type: SET_SETTINGS, payload });
