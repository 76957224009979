import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";
import { device } from "@core-const";
import { Rating } from "@progress/kendo-react-inputs";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Title } from "app-styled-components";
import { FlexCol, FlexRow } from "app-styled/flex";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { SubContainerProps } from "@core-interface";
import { SmylorPageCard } from "app-core/styled/components_v3";

export const MainWrapper = styled(FlexCol)`
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  @media ${device.tablet} {
  ${({ theme, }) => css`
    @media ${device.tablet} {
      padding: ${theme["thm-space-lg"]} ${theme["thm-space-rg"]};
  `};
  ${({ theme, extraMarging }) => extraMarging && css`
    @media ${device.tablet} {
      margin-top: 75px;
  `};
}
`;

export const SubContainer = styled(FlexCol) <SubContainerProps>`
  width: 100%;
  flex-grow: 1;
  ${({ theme }) => css`
    background: ${theme["color-light"]};

    @media ${device.mobileL} {
      padding: 0;
    }
  `};

  ${({ theme, edit }) =>
    edit &&
    css`
      border-radius: ${theme["thm-radius"]};
    `};
  ${({ theme, show }) =>
    show &&
    css`
      display: none;
    `};
`;

export const StyledRating = styled(Rating)`
  .k-rating-item {
    padding: 0rem;

    &.k-state-selected {
      ${({ theme, review }) => css`
        color: ${review ? theme["selected-bg"] : theme["color-positive"]};
      `}
    }
    .k-rating-precision-complement {
      left: 0rem;
      top: 0rem;
    }
    .k-rating-precision-part {
      left: 0rem !important;
      top: 0rem !important;
    }
  }
`;

export const StyledNumbers = styled.div`
  padding: 0.3rem 0.75rem;
  ${({ theme }) => css`
    font-family: ${theme["font-bold"]};
    color: ${theme["thm-base"]};
  `}

  .count {
    padding: 0 0.25rem;
    font-size: 0.75rem;
    color: red;
  }

  @media ${device.tablet} {
    font-size: 0.75rem;
    margin-left: auto;
    letter-spacing: 0.05rem;

    .count {
      font-size: 0.5rem;
      color: red;
      letter-spacing: 1px;
    }
  }
`;

export const VerifiedIcon = styled(CheckCircleIcon)`
  margin: 0 0 0 0.5rem;
  ${({ theme }) => css`
    color: ${theme["color-positive"]};
  `}
`;

export const MiniTitle = styled(Title)`
  margin: 1rem 0 0 0;
  ${({ noTop }: any) =>
    noTop &&
    css`
      margin: 0;
    `}
`;

export const EditIconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 32px;
`;

export const StyledCheckBox = styled(Checkbox)`
  ${({ theme }) => css`
    margin: 0 ${theme["space-md"]};
    input {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      border: 5px solid ${theme["color-positiveLight"]};

      &.k-checkbox:checked {
        background-color: ${theme["color-positive"]};
        border-color: ${theme["color-positive"]};
      }
    }
  `}
`;

export const FormLabel = styled(Label)`
  ${({ theme }) => css`
    font-family: ${theme["fontFamily-bold"]};
  `};
`;

export const FormItemWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme["thm-space-lg"]};
    margin-left: ${theme["thm-space-xl"]};
    @media ${device.mobileL} {
      margin-left: 0;
    }
  `};
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    font-family: ${theme["fontFamily-bold"]};
    .k-label {
      // margin-bottom: ${theme["margin-elementSmall"]};
    }
  `};
`;

export const FooterContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme["thm-space-md"]};
  `};
  width: 100%;
  width: calc(100% + 60px);
  margin-bottom: -20px;
  @media ${device.tablet} {
    width: calc(100% + 24px);
  }

`;

export const DentistProfileMainWrapper = styled(FlexCol)`
  width: 100%;
  max-width: 1440px;
  ${({ theme }) => css`
    gap: ${theme["thm-space-xl"]};
    margin-bottom: ${theme["thm-space-xl"]};

    @media ${device.tabletL} {
      gap: ${theme["thm-space-rg"]};
    }
  `};
`;
export { Row, Col, FlexCol, FlexRow, SmylorPageCard };
