import { PatientRequestsDataCommon, SharepointAppointReqData } from "@core-interface";

export const SET_APOINTMENT_DATA = 'SET_APOINTMENT_DATA';
export const setProfileAppointmentsData = (payload: PatientRequestsDataCommon) => ({ type: SET_APOINTMENT_DATA, payload });

export const GET_APOINTMENT_COMPLETE_DATA = 'GET_APOINTMENT_COMPLETE_DATA';
export const getAppoinmentsCompleteData = payload => ({ type: GET_APOINTMENT_COMPLETE_DATA, payload });

export const SET_APOINTMENT_COMPLETE_DATA = 'SET_APOINTMENT_COMPLETE_DATA';
export const setAppoinmentsCompleteData = payload => ({ type: SET_APOINTMENT_COMPLETE_DATA, payload });

export const GET_SHAREPOINT_APPOINMENT_DATA = 'GET_SHAREPOINT_APPOINMENT_DATA';
export const getSharePointAppointmentData = (payload: SharepointAppointReqData) => ({ type: GET_SHAREPOINT_APPOINMENT_DATA, payload });

export const SET_SHAREPOINT_APPOINMENT_DATA = 'SET_SHAREPOINT_APPOINMENT_DATA';
export const setSharePointAppointmentData = (payload: any) => ({ type: SET_SHAREPOINT_APPOINMENT_DATA, payload });

export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const setAppointmentSelectedView = (payload: number) => ({ type: SET_SELECTED_VIEW, payload });

export const GET_SELECTED_VIEW = 'GET_SELECTED_VIEW';
export const getAppointmentSelectedView = (payload: number) => ({ type: GET_SELECTED_VIEW, payload });