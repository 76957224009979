import { DentistRequestsData, DentistRequestsDataCommon, PatientRequestsDataCommon, TreatmentRequestsData } from "@core-interface";

export const SET_EMERGENCY_REQUESTS_DATA = 'SET_EMERGENCY_REQUESTS_DATA';
export const setEmergencyRequestsData = (payload: DentistRequestsDataCommon) => ({ type: SET_EMERGENCY_REQUESTS_DATA, payload });

export const SET_EMERGENCY_REQUEST_DATA = 'SET_EMERGENCY_REQUEST_DATA';
export const setEmergencyRequestData = payload => ({ type: SET_EMERGENCY_REQUEST_DATA, payload });

export const UPDATE_EMERGENCY_REQUEST_DATA = 'UPDATE_EMERGENCY_REQUEST_DATA';
export const updateEmergencyRequestData = payload => ({ type: UPDATE_EMERGENCY_REQUEST_DATA, payload });

export const SET_UPDATE_EMERGENCY_REQUEST_DATA = 'SET_UPDATE_EMERGENCY_REQUEST_DATA';
export const setUpadatedEmergencyRequestData = payload => ({ type: SET_UPDATE_EMERGENCY_REQUEST_DATA, payload });

// Get Tratment Patient Request Data

export const GET_PATIENT_TREATMENT_REQUESTS_DATA = 'GET_PATIENT_TREATMENT_REQUESTS_DATA';
export const getTreatmentRequestsData = (payload: TreatmentRequestsData) => ({ type: GET_PATIENT_TREATMENT_REQUESTS_DATA, payload });

export const SET_PATIENT_EMERGENCY_REQUESTS_DATA = 'SET_PATIENT_EMERGENCY_REQUESTS_DATA';
export const setEmergencyPatientRequestsData = (payload: PatientRequestsDataCommon) => ({ type: SET_PATIENT_EMERGENCY_REQUESTS_DATA, payload });

export const SET_PATIENT_TREATMENT_REQUESTS_DATA = 'SET_PATIENT_TREATMENT_REQUESTS_DATA';
export const setTreatmentPatientRequestsData = (payload: PatientRequestsDataCommon) => ({ type: SET_PATIENT_TREATMENT_REQUESTS_DATA, payload });

export const GET_PATIENT_TREATMENT_REQUEST = 'GET_PATIENT_TREATMENT_REQUEST';
export const getPatientTreatmentRequestData = payload => ({ type: GET_PATIENT_TREATMENT_REQUEST, payload });

export const SET_PATIENT_EMERGENCY_REQUEST = 'SET_PATIENT_EMERGENCY_REQUEST';
export const setEmergencyPatientRequestData = payload => ({ type: SET_PATIENT_EMERGENCY_REQUEST, payload });

export const SET_PATIENT_TREATMENT_REQUEST = 'SET_PATIENT_TREATMENT_REQUEST';
export const setTreatmentPatientRequestData = payload => ({ type: SET_PATIENT_TREATMENT_REQUEST, payload });

export const SELECTED_TREATMENT_TIME_SLOT = 'SELECTED_TREATMENT_TIME_SLOT';
export const updateSelectedTimeSlotRequestData = payload => ({ type: SELECTED_TREATMENT_TIME_SLOT, payload });

export const ACCEPT_SELECTED_EMERGENCY_TIME_SLOT = 'ACCEPT_SELECTED_EMERGENCY_TIME_SLOT';
export const updateAcceptSelectedTimeSlotRequestData = payload => ({ type: ACCEPT_SELECTED_EMERGENCY_TIME_SLOT, payload });

export const SET_ACCEPT_SELECTED_EMERGENCY_TIME_SLOT = 'SET_ACCEPT_SELECTED_EMERGENCY_TIME_SLOT';
export const setAcceptEmergencySelectedTimeSlot = payload => ({ type: SET_ACCEPT_SELECTED_EMERGENCY_TIME_SLOT, payload });

export const SET_SELECTED_EMERGENCY_TIME_SLOT = 'SET_SELECTED_EMERGENCY_TIME_SLOT';
export const setSelectedTimeSlotEmergencyRequestData = payload => ({ type: SET_SELECTED_EMERGENCY_TIME_SLOT, payload });

export const SET_SELECTED_TREATMENT_TIME_SLOT = 'SET_SELECTED_TREATMENT_TIME_SLOT';
export const setSelectedTimeSlotTreatmentRequestData = payload => ({ type: SET_SELECTED_TREATMENT_TIME_SLOT, payload });

export const SET_ACCEPTED_EMERGENCY_TIME_SLOT = 'SET_ACCEPTED_EMERGENCY_TIME_SLOT';
export const setAcceptedEmergencyTimeSlotRequestData = payload => ({ type: SET_ACCEPTED_EMERGENCY_TIME_SLOT, payload });

export const SET_ACCEPTED_TREATMENT_TIME_SLOT = 'SET_ACCEPTED_TREATMENT_TIME_SLOT';
export const setAcceptedTreatmentTimeSlotRequestData = payload => ({ type: SET_ACCEPTED_TREATMENT_TIME_SLOT, payload });

// Get all Request Data

export const GET_TREATMENT_REQUESTS_DATA = 'GET_TREATMENT_REQUESTS_DATA';
export const getDentistRequestsData = (payload: DentistRequestsData) => ({ type: GET_TREATMENT_REQUESTS_DATA, payload });

export const SET_TREATMENT_REQUESTS_DATA = 'SET_TREATMENT_REQUESTS_DATA';
export const setTreatmentRequestsData = (payload: DentistRequestsDataCommon) => ({ type: SET_TREATMENT_REQUESTS_DATA, payload });

export const SET_TREATMENT_REQUEST_DATA = 'SET_TREATMENT_REQUEST_DATA';
export const setTreatmentRequestData = payload => ({ type: SET_TREATMENT_REQUEST_DATA, payload });

export const GET_TREATMENT_REQUEST_DATA = 'GET_TREATMENT_REQUEST_DATA';
export const getTreatmentRequestData = payload => ({ type: GET_TREATMENT_REQUEST_DATA, payload });

export const UPDATE_TREATMENT_REQUEST_DATA = 'UPDATE_TREATMENT_REQUEST_DATA';
export const updateTreatmentRequestData = payload => ({ type: UPDATE_TREATMENT_REQUEST_DATA, payload });

export const SET_UPDATE_TREATMENT_REQUEST_DATA = 'SET_UPDATE_TREATMENT_REQUEST_DATA';
export const setUpadatedTreatmentRequestData = payload => ({ type: SET_UPDATE_TREATMENT_REQUEST_DATA, payload });

export const SET_UPDATE_TREATMENT_STATUS = 'SET_UPDATE_TREATMENT_STATUS';
export const setTreatmentRequestStatus = (payload: string) =>  ({ type: SET_UPDATE_TREATMENT_STATUS, payload });

export const GET_UPDATE_TREATMENT_STATUS = 'GET_UPDATE_TREATMENT_STATUS';
export const getTreatmentRequestStatus = (payload) =>  ({ type: GET_UPDATE_TREATMENT_STATUS, payload });