import { useLocalization } from "@progress/kendo-react-intl";

export const Breadcrumb = () => {
  const localization = useLocalization();
  return [
    {
      id: "home",
      text: localization.toLanguageString("breadcrumb_home", "Home"),
      url: "/",
      iconClass: "k-i-home",
    },
    {
      id: "results",
      text: localization.toLanguageString("breadcrumb_results", "Results"),
    },
  ];
};
