import { PatientReviewFormReducer } from "@core-interface";
import { GET_ADD_PATIENT_TREATMENT_REVIEW, GET_CREATE_DENTIST, RESET_REVIEW_DENTIST_DETAILS, SET_ADD_PATIENT_TREATMENT_REVIEW, SET_CREATE_DENTIST } from "./patientReview.actions";

const initState: PatientReviewFormReducer = {
  createDentistLoading: false,
  addPatientReviewLoading: false,
  createdentistData: null,
  addPatientReviewData: null
}

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CREATE_DENTIST:
      return {
        ...state,
        createDentistLoading: true,
        createdentistData: payload
      };
    case GET_CREATE_DENTIST:
      return {
        createDentistLoading: false,
        createdentistData: payload
      };
    case SET_ADD_PATIENT_TREATMENT_REVIEW:
      return {
        ...state,
        addPatientReviewLoading: true,
        addPatientReviewData: payload
      };
    case RESET_REVIEW_DENTIST_DETAILS:
      return {
        ...state,
        createdentistData: null,
        addPatientReviewData: null
      };
    case GET_ADD_PATIENT_TREATMENT_REVIEW:
      return {
        initState
      };
    default:
      break;
  }
  return state;
};

export default reducer;
