import styled, { css } from 'styled-components';
import {device} from '@core-const';
import { Page } from '../../core/styled/components/containers';


export const PrizingWrapper = styled(Page)`
    margin-top : 117px;
    @media ${ device.tablet } { 
        margin-top : 80px;
    } 
`;

export const PrizingContainer = styled.div`
    // height: 100vh;
`;
