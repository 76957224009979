import { ajax } from "rxjs/ajax";
import { EMPTY, of } from "rxjs";
import { setActionBussy } from "./actions";
import { catchError, switchMap, tap } from "rxjs/operators";
import { logoutUser, getAccessToken } from "@core-const";
import { StatusCode } from "app-core/constant/authTypes";

const httpPost = ({ call, data }) => {
  return httpCall("POST", call, data);
};

const httpGet = ({ call }) => {
  return httpCall("GET", call);
};

const httpDelete = ({ call }) => {
  return httpCall("DELETE", call);
};

const httpPut = ({ call, data }) => {
  return httpCall("PUT", call, data);
};

const apiFectingError = (error) => {
  if (error.status === StatusCode.unAuthenticated) {
    logoutUser();
  }
};

const httpCall = (type, call, data = null) => {
  setActionBussy(true);

  let request_url = process.env.REACT_APP_SERVER_PATH;
  let headers: any = {
    "Access-Control-Allow-Origin": "*",
    // 'Access-Control-Allow-Origin': 'http://localhost:1096/api/',
    "Content-type": "application/json",
    "Access-Control-Max-Age": 7200,
  };

  let jsonData = null;
  if (data) {
    jsonData = JSON.stringify(data);
  }

  request_url = `${request_url}/${call}`;
  headers = {
    ...headers,
    Authorization: `Bearer ${getAccessToken()}`,
  };

  return ajax({
    url: request_url,
    crossDomain: true,
    method: type,
    responseType: "json",
    timeout: 0,
    headers,
    body: data ? jsonData : null,
    createXHR: function () {
      return new XMLHttpRequest();
    },
  }).pipe(
    tap((result) => setActionBussy(false)),
    switchMap((data) => of(data.response)),
    catchError((error) => {
      apiFectingError(error);
      return EMPTY;
    })
  );
};

const apiStatus = {
  success: 200,
};
export { httpPost, httpGet, httpDelete, httpPut, apiStatus };
