import styled, { css } from 'styled-components';
import { device } from '@core-const';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    padding-left: ${theme['padding-page']};
    padding-right: ${theme['padding-page']};
  `}

  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

    @media ${device.tablet} { 
      padding-left: 1rem;
      padding-right:1rem;
    }

    @media ${device.mobileL} { 
      padding-left: 0.75rem;
      padding-right:0.75rem;
    }
`;

const PageContainerFullWidth = styled(Page)`
  width: unset;
  ${({ theme }) => css`
    margin-left: -${theme['padding-page']};
    margin-right: -${theme['padding-page']};
  `}
  @media ${device.tablet} { 
    padding-left: 1.5rem;
    padding-right:1.5rem;
  }
`;

const PageContainerSmall = styled(Page)`
  width: 90rem;
  height: 100%;
  @media ${device.tablet} { 
    width: 100%;
  }
`;

export { Page, PageContainerFullWidth, PageContainerSmall };
