import styled, { css } from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  ${({ right, }) =>
        right &&
        css`
      margin-left: auto;
    `}
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FlexCenter = styled.div`
 display: flex;
 justify-content:center;
 align-items:center;
`