
import React from 'react';
import { EmergencyRequestStatus } from '@core-interface';
import { faCalendarPen, faCalendarCheck, faCalendarXmark, faCalendarStar, faCalendarClock, faCalendarExclamation, faCalendarCircleUser, faCalendarLinesPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from 'styled-components';

const StyledEmergencyStatusIcon = styled.div`
    position: absolute; 
    top: 0px; 
    right: 6px;
`;

const EmergencyStatusIcon = ({ status }) => {

    const getValue = (status) => {
        switch (status) {
            case EmergencyRequestStatus.PENDING: return <StyledEmergencyStatusIcon style={{ color: '#fca103' }}><FontAwesomeIcon icon={faCalendarPen} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.ACCEPTED: return <StyledEmergencyStatusIcon style={{ color: '#02781e' }}><FontAwesomeIcon icon={faCalendarCheck} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.REPLIED: return <StyledEmergencyStatusIcon style={{ color: '#150b7d' }}><FontAwesomeIcon icon={faCalendarLinesPen} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.EXPIRED: return <StyledEmergencyStatusIcon style={{ color: '#818182' }}><FontAwesomeIcon icon={faCalendarClock} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.REJECTED: return <StyledEmergencyStatusIcon style={{ color: '#b8042e' }}><FontAwesomeIcon icon={faCalendarXmark} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.COMPLETED: return <StyledEmergencyStatusIcon style={{ color: '#005963' }}><FontAwesomeIcon icon={faCalendarStar} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.ALREADYTAKEN: return <StyledEmergencyStatusIcon style={{ color: '#999999' }}><FontAwesomeIcon icon={faCalendarCircleUser} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.PARTIALLYCOMPLETED: return <StyledEmergencyStatusIcon style={{ color: '#EE4B2B' }}> <FontAwesomeIcon icon={faCalendarExclamation} size={'3x'} /></StyledEmergencyStatusIcon>;
            case EmergencyRequestStatus.NOTRESPONDED: return <StyledEmergencyStatusIcon style={{ color: '#FF0000' }}><FontAwesomeIcon icon={faCalendarPen} /></StyledEmergencyStatusIcon>;
            default: return <div></div>
        };
    };

    return <div>{getValue(status)}</div>;

}

export default React.memo(EmergencyStatusIcon);
