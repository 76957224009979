import { ServicesReducer } from "@core-interface";
import {
    SET_SERVICES_DATA
} from './services.actions';

const initState: ServicesReducer = {
    ServicesData: {},
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SERVICES_DATA:
            return {
                ...state,
                ServicesData: payload,
            };
        default:
            break;
    }
    return state;
}

export default reducer;