import styled, { css } from "styled-components";
import { device } from "@core-const";
import { MyProfileNavigationListStyledProps } from "@core-interface";
import { FlexRow, FlexCenter, FlexCol } from "app-styled/flex";
import { SmylorSmallButton } from "app-core/styled/components_v3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItemTitle } from "app-styled-components";

export const MyProfileNavigatorContainer = styled(FlexRow)`
  margin: 0;
  height: calc(100vh - 81px);
  ${({ theme }) => css`
    @media${theme.tablet} {
      width: auto;
    }
  `}
`;
export const MyProfileNavigationList = styled.div`
  padding: 0;
  margin-top: 80px;
  width: 100%;

  ${({ theme }) => css`
    /* background-color: ${theme["smylor-blue-darken-D"]}; */
    @media ${device.tabletL} {
    .k-drawer {
        position: absolute;
        left: -100%;
      }
      &.show {
        .k-drawer {
          animation-duration: 4s;
          position: absolute;
          width: 320px;
          height: 100%;
          top: 80px;
          left: 0px;
          z-index: 2;
          .k-drawer-wrapper {
            animation-duration: 4s;
            width: 100% !important;
          }
          .nav-item-logout-icon {
            margin-left: auto;
          }
        }
      }
    }

    .k-drawer-container {
      height: calc(100vh - 80px);
      .k-drawer-items {
        margin-top: ${theme["thm-space-lg"]};
        height: calc(100% - ${theme["thm-space-lg"]});
      }
      .k-drawer-content {
        overflow-y: auto;
      }
    }

    // mini view need styles
    /* .k-drawer-start{
      flex-basis: 94px !important;
      .nav-item-text{
        display: none;
      }
      .nav-item-container{
        width: 48px;
        padding: ${theme["thm-space-rg"]};
        align-items: center;
        justify-content: center;
      }
      .nav-item-logout-container{
        width: 48px;
        padding: ${theme["thm-space-sm"]};
        align-items: center;
        justify-content: center;
      }
      .nav-item-logout-icon{
          width: 35px;
          height: 35px;
          border-radius: 50%;
      }
    } */
    .k-drawer-wrapper {
      height: 100%;
    }

    .k-drawer-start {
      flex-basis: unset !important;
      .nav-item-text {
        display: flex;
      }
      .nav-item-container {
        width: 100%;
        justify-content: flex-start;
      }
      .nav-item-logout-container {
        width: 100%;
        justify-content: flex-start;
        padding: 20px;
      }
      .nav-item-logout-icon {
        width: 50px;
        height: 50px;
      }
    }
  `}
`;

export const NavigatorListItemConatainer = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme["thm-space-md"]};
    padding-left: ${theme["thm-space-xl"]};
    padding-right: ${theme["thm-space-xl"]};
    
    @media ${device.tabletL} {
      padding-left: ${theme["thm-space-rg"]};
      padding-right: ${theme["thm-space-rg"]};
    }
  `}
  &.nav-item-logout {
    margin-top: auto;
    margin-bottom: 0;
  }
`;

export const ListItemLogoutContainer = styled(FlexRow)`
  width: 100%;
  align-items: center;

  ${({ theme }) => css`
    gap: ${theme["thm-space-rg"]};
    margin-top: ${theme["thm-space-xl"]};
    margin-bottom: ${theme["thm-space-lg"]};
    padding: 20px;
    background-color: ${theme["smylor-light-blueD"]};
    border-radius: ${theme["thm-space-md"]};

    .k-avatar-solid-primary {
      background-color: ${theme["smylor-white"]};
    }
  `}
`;

export const ListItemLogoutProfilewrapper = styled(FlexCol)`
  flex-grow: 1;
  max-width: 124px;
  overflow: hidden;
`;

export const LogoutNameContainer = styled(ItemTitle)`
  margin-bottom: 0px;
`;

export const MyProfileNavigatorListItem = styled.div<MyProfileNavigationListStyledProps>`
  font-weight: 500 !important;
  text-align: left;
  width: 100%;
  display: flex;
  ${({ theme }) => css`
    padding: ${theme["padding-dropMenu"]} 20px;
    gap: ${theme["thm-space-lg"]};
    color: ${theme["smylor-blue-darken-A"]};
    border-radius: ${theme["thm-space-md"]};
    &:hover {
      background-color: ${theme["smylor-pink-D"]};
      cursor: pointer;
      color: #000000;
    }
  `}
  ${({ theme, selected }) =>
    selected &&
    css`
      color: ${theme["smylor-white"]};
      /* background-color: ${theme["smylor-blue"]}; */
      background-color: ${theme["smylor-blue-light"]};
      &:hover {
        /* background-color: ${theme["smylor-blue"]}; */
        background-color: ${theme["smylor-blue-light"]};
        color: ${theme["smylor-white"]};
      }
    `}
`;

export const ListItemIcon = styled(FontAwesomeIcon)`
  width: 30px;
`;

export const ListItemLogoutIconContainer = styled(FlexCenter)`
  cursor: pointer;
  ${({ theme }) => css`
    :hover {
      background-color: ${theme["smylor-light-blueA"]};
      background-size: 50px;
      border-radius: 50%;
    }
  `}
`;

export const LoadingWrapper = styled(FlexCenter)`
  ${({ theme }) =>
    css`
      font-size: ${theme["fontSize-elementSmall"]};
      color: ${theme["color-dark"]};
      margin: ${theme["thm-space-xl"]};
      font-family: ${theme["fontFamily-regular"]};
    `}
`;
export const ReviewButtonContainer = styled(FlexRow)`
  justify-content: end;
  /* ${({ theme }) =>
    css`
      font-size: ${theme["fontSize-elementSmall"]};
      color: ${theme["color-dark"]};
      margin: ${theme["thm-space-xl"]};
      font-family: ${theme["fontFamily-regular"]};
    `} */
`;
export const ReviewButton = styled(SmylorSmallButton)`
  margin-right: 0;
  ${({ theme }) => css`
    padding: ${theme["thm-space-sm"]} ${theme["thm-space-xl"]};
    /* margin-bottom: ${theme["thm-space-xl"]}; */
  `}
`;

export const EmergengcyPopupWrapper = styled(FlexRow)`
  margin-left: auto;
  align-items: center;
  ${({ theme }) => css`
    @media ${device.mobileL} {
        margin-top: ${theme["thm-space-rg"]};
        margin-left: 0px;
      }
  `}
`;

export const TreatmentIcon = styled.img`
  height: 28px;
  width: auto;
  margin-right: 5px;
  background-color: #ffffff;
  padding: 2px;
  border-radius: 50%;
`;

