import React from 'react';
import styled, { css } from "styled-components";
import { FlexCol, FlexRow } from "app-styled/flex";
import { SmylorTimeSlotCardTitle } from './timeSlotTitleWrapper';
import StartEndTimeRender from 'app-core/styled/components_v3/TimeDuration/startEndTimeRender';
import { device } from '@core-const';
import { ReqTimeRender } from '../reqTimeRender';


export const CardWrapper = styled(FlexCol)`
  /* width: 100%; */
  ${({ theme }) => css`
    box-shadow: ${theme["smylor-light-blueA"]} 0 0 2px,
      ${theme["smylor-light-blueA"]} 0 6px 12px -8px;
    border-radius: ${theme["thm-space-md"]};
    margin-bottom: ${theme["thm-space-xl"]};
    width: 48%;

    @media ${device.laptop} {
      width: 100%;
  `}
  ${({ theme, clickable }) => clickable && css`
    cursor: pointer;
    :hover{
      box-shadow: ${theme["smylor-light-blueA"]} 0 0 6px,
      ${theme["smylor-light-blueA"]} 0 6px 12px -8px;
    }
  `}
`;

export const SymlorCardContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 20px;
  `}
`;

export const SymlorCardFooter = styled(FlexRow)`
  ${({ theme }) => css`
    position: relative;
    justify-content: space-between;
    border-top: 1px solid ${theme["smylor-light-blueA"]};
    padding: ${theme["thm-space-lg"]} 20px;
  `}
`;

const SmylorTimeSlotCard = ({ children, duration, reqTime, clickable, onClick, style, footerData }: { children: any; duration?: { stTime: string, edTime: string }, reqTime: any, clickable: boolean, onClick?: Function, style?: any, footerData?: any }) => {

  return (
    <CardWrapper clickable={clickable} onClick={onClick} style={style}>
      <SmylorTimeSlotCardTitle duration={duration} />
      <SymlorCardContent>
        <StartEndTimeRender duration={duration} />
        {children}
      </SymlorCardContent>
      <SymlorCardFooter>{footerData}{ReqTimeRender(reqTime)}</SymlorCardFooter>
    </CardWrapper>
  );
};

export default SmylorTimeSlotCard;