export const GET_DATA = "GET_DATA";
export const getSearchData = (payload) => ({ type: GET_DATA, payload });

export const SET_DATA = "SET_DATA";
export const setSearchData = (payload) => ({ type: SET_DATA, payload });

export const SET_REFERRAL_DENTIST_DATA = "SET_REFERRAL_DENTIST_DATA";
export const setReferralDentistData = (payload) => ({
  type: SET_REFERRAL_DENTIST_DATA,
  payload,
});

export const SET_BOOKNOW_TREATMENT_DATA = "SET_BOOKNOW_TREATMENT_DATA";
export const setBookNowPopupData = (payload) => ({
  type: SET_BOOKNOW_TREATMENT_DATA,
  payload,
});
