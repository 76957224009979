import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Google } from 'app-core-img';
import LoadingIndicator from 'app-core/styled/components/shared/LoadingIndicator';
import { useLocalization } from '@progress/kendo-react-intl';

import * as SC from './socialMediaLogin.styled'

const SocialMediaLogin = ({ setLoadingLogin, overlayLoading = false }: { setLoadingLogin?: Function, overlayLoading?: boolean }) => {
    const [loading, setLoading] = useState<{ facebbok: boolean, google: boolean }>({ facebbok: false, google: false });
    const localization = useLocalization();

    const onGoogleClick = (e) => {
        overlayLoading && setLoadingLogin(true);
        setLoading(prev => ({ ...prev, google: true }));
        window.open(process.env.REACT_APP_GOOGLE_URL, "_self");
    };
    const onFacebookClick = (e) => {
        overlayLoading && setLoadingLogin(true);
        setLoading(prev => ({ ...prev, facebbok: true }));
        window.open(process.env.REACT_APP_FACEBOOK_URL, "_self");
    };
    return (
        <SC.SocialMediaContainer>
            <SC.FaceBookLoginButton colorType={'light'} onClick={onFacebookClick} overlayLoading={overlayLoading}>
                <FontAwesomeIcon
                    icon={faSquareFacebook as IconProp}
                    className="facebook-icon"
                />
                <SC.SocialButtonText>
                    {localization.toLanguageString(
                        "login_module_sign_in_facebook",
                        "Sign in with Facebook"
                    )}
                </SC.SocialButtonText>
                {loading.facebbok && <LoadingIndicator />}
            </SC.FaceBookLoginButton>
            <SC.GoogleLoginButton colorType={'light'} onClick={onGoogleClick} overlayLoading={overlayLoading}>
                <img src={Google} className="google-icon" />
                <SC.SocialButtonText>
                    {localization.toLanguageString(
                        "login_module_sign_in_google",
                        "Sign in with Google"
                    )}
                </SC.SocialButtonText>
                {loading.google && <LoadingIndicator />}
            </SC.GoogleLoginButton>
        </SC.SocialMediaContainer>
    )
}

export default SocialMediaLogin