import { rootState } from '@core-interface'

export const getFilters = (state: rootState) => state?.coreReducer?.filter;

export const getBreadcrumb = (state: rootState) => state?.coreReducer?.breadcrumb;

export const getGeoLocationfromStore = (state: rootState) => state?.coreReducer?.geoLocation;

export const getNotificationFromStore = (state: rootState) => state?.coreReducer?.notificationMessage;

export const getSettingsFromStore = (state: rootState) => state?.coreReducer?.settings;

export const getBussyStatusFromStore = (state: rootState) => state?.coreReducer?.bussy;

