import MainRoutes from "./main.router";
import { NotificationMsg } from 'app-styled-components';
import * as SC from './main.styled';

const MainComponet = () => {
  return (
    <div>
      <SC.MainWrapper>
        <SC.MainContainer>
          <MainRoutes />
        </SC.MainContainer>
      </SC.MainWrapper>
      <NotificationMsg />
    </div>
  );
};

export default MainComponet;
