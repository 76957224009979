export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const setProfileData = payload => ({ type: SET_PROFILE_DATA, payload });

export const REGISTER_DENTIST_DATA = 'REGISTER_DENTIST_DATA';
export const registerDentistData = payload => ({ type: REGISTER_DENTIST_DATA, payload });

export const REGISTER_DENTIST_DATA_SUCCESS = 'REGISTER_DENTIST_DATA_SUCCESS';
export const registerDentistDataSuccess = (payload: boolean) => ({ type: REGISTER_DENTIST_DATA_SUCCESS, payload });

export const GET_CHECK_DENTIST_DATA = 'GET_CHECK_DENTIST_DATA';
export const getChecDentistData = (payload: { Key: number, Value: string | number }) => ({ type: GET_CHECK_DENTIST_DATA, payload });

export const SET_CHECK_DENTIST_DATA = 'SET_CHECK_DENTIST_DATA';
export const setChecDentistData = payload => ({ type: SET_CHECK_DENTIST_DATA, payload });

export const REMOVE_CHECK_DENTIST_DATA = 'REMOVE_CHECK_DENTIST_DATA';
export const removeChecDentistData = () => ({ type: REMOVE_CHECK_DENTIST_DATA });

export const REGISTER_DENTIST_SUCCESS_RESET = 'REGISTER_DENTIST_SUCCESS_RESET';
export const registerDentistSuccessReset = () => ({ type: REGISTER_DENTIST_SUCCESS_RESET });
