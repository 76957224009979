import React from "react";
import loadable from "@loadable/component";
import Footer from "app-module/footer";
const Header = loadable(
  () => import(/*webpackChunkName: "pkg_____header"*/ "../header/index")
);

const MainHeader = ({ bgColor, children, showPreHeader }) => {
  return (
    <>
      <Header preHeader={showPreHeader} bgColor={bgColor}/>
      {children}
      {/* <Footer/> */}
    </>
  );
};

export default MainHeader;
