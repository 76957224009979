import React from "react";
import styled, { css } from "styled-components";
import { FlexCol } from "app-styled/flex";
import SymlorCardTitle from "./titleWrapper";
import SmylorTimeSlotCard from "./TimeSlotCard";
import { ReqTimeRender } from "./reqTimeRender";
import EmergencyStatusIcon from "app-core/styled/components/shared/EmergencyStatusIcon";
import { ItemTitle } from "app-core/styled/components/titles";
import { device } from "@core-const";

export const CardWrapper = styled(FlexCol)`
  /* width: 100%; */
  ${({ theme }) => css`
    box-shadow: ${theme["smylor-light-blueA"]} 0 0 2px,
      ${theme["smylor-light-blueA"]} 0 6px 12px -8px;
    border-radius: ${theme["thm-space-md"]};
    margin-bottom: ${theme["thm-space-xl"]};
    gap: ${theme["thm-space-md"]};
  `}
  ${({ theme, clickable }) => clickable && css`
    cursor: pointer;
    :hover{
      box-shadow: ${theme["smylor-light-blueA"]} 0 0 6px,
      ${theme["smylor-light-blueA"]} 0 6px 12px -8px;
    }
  `}
`;

export const PageCardWrapper = styled(FlexCol)`
  /* width: 100%; */
  ${({ theme, fullView }) => css`
    width: ${fullView ? '100%' : undefined};
    box-shadow: ${theme["smylor-light-blueA"]} 0 0 2px,
      ${theme["smylor-light-blueA"]} 0 6px 12px -8px;
    border-radius: ${theme["thm-space-md"]};
    padding: ${theme["thm-space-xl"]} 20px;
  `}
  ${({ theme, noShadow }) => noShadow && css`
    box-shadow: none;
    padding: ${theme["thm-space-xl"]} 0px;

    @media ${device.tabletL} {
    padding: 0px;
    padding-bottom: ${theme["thm-space-lg"]};
  }
  `}
`;

export const SymlorCardContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 20px;
  `}
`;

export const SymlorCardFooter = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-top: 1px solid ${theme["smylor-light-blueA"]};
    padding: ${theme["thm-space-lg"]} 20px;
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 14px;
    bottom: 60px;
  `}
`;

export const  SmylorPageCardTitle = styled(FlexCol)`
  ${({ theme }) => css`
    color: ${theme["smylor-blue-darken-A"]};
    margin-bottom: ${theme["thm-space-xl"]};
  `}
`;



const SmylorCard = ({ children, status, reqTime, clickable, onClick } : {children : any; status: any,  reqTime: any, clickable: boolean, onClick?: Function }) => {

  return (
    <CardWrapper clickable={clickable} onClick={onClick} >
      <SymlorCardTitle status={status} />
      <SymlorCardContent>{children}
        <IconContainer>
          <EmergencyStatusIcon status={status} />
        </IconContainer>
      </SymlorCardContent>
      <SymlorCardFooter>{ReqTimeRender(reqTime)}</SymlorCardFooter>
    </CardWrapper>
  );
};


const SmylorPageCard = ({ children, fullView, header, noShadow, style }: { children: any; fullView?: boolean, header?: { title?: string; description?: string }, noShadow?: boolean, style?: any}) => {

  return (
    <PageCardWrapper fullView={fullView} noShadow={noShadow} style={style}>
      {header ?
        <SmylorPageCardTitle>
          <ItemTitle>
            {header.title}
          </ItemTitle>
          {header.description}
        </SmylorPageCardTitle> : <></>}
      {children}
    </PageCardWrapper>
  );
};


export { SmylorPageCard, SmylorCard, SmylorTimeSlotCard, ReqTimeRender };


