import { AppointmentStatusUpdateProps, AppointmentStatusUpdatedProps, AppointmentViewData } from "@core-interface";

export const GET_APPOINTMENT_DATA = "GET_APPOINTMENT_DATA";
export const getAppointmentData = (payload: { clientId: number, bookingId: number }) => ({ type: GET_APPOINTMENT_DATA, payload });

export const SET_APPOINTMENT_DATA = "SET_APPOINTMENT_DATA";
export const setAppointmentData = (payload: AppointmentViewData) => ({ type: SET_APPOINTMENT_DATA, payload });

export const UPDATE_APPOINTMENT_STATUS = "UPDATE_APPOINTMENT_STATUS";
export const updateAppointmentStatus = (payload: AppointmentStatusUpdateProps) => ({ type: UPDATE_APPOINTMENT_STATUS, payload });

export const UPDATE_APPOINTMENT_STATUS_SUCCESS = "UPDATE_APPOINTMENT_STATUS_SUCCESS";
export const updateAppointmentStatusSuccess = (payload: AppointmentStatusUpdatedProps) => ({ type: UPDATE_APPOINTMENT_STATUS_SUCCESS, payload });
