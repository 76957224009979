import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {  } from "@fortawesome/free-regular-svg-icons";
import { faCircleSmall } from "@fortawesome/pro-light-svg-icons";
// import { faCircleSmall } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Message } from "@localization";
import { DateDrawer } from "app-core/styled/components/shared";
import { FlexRow } from "app-styled/flex";
import styled, { css } from "styled-components";

export const ReqTimeSlotContainer = styled(FlexRow)`
  margin-left: auto;
  justify-content: end;
  font-size: 12px;
  flex-wrap: wrap;
  align-items: center;
  
  ${({ theme }) => css`
    gap: ${theme["thm-space-md"]};
    color: ${theme["smylor-blue-darken-A"]};
  `};
`;

export const ReqTimeRender = (reqTime) => {
  return (
    <>
      <ReqTimeSlotContainer>
        <FontAwesomeIcon icon={faCircleSmall as IconProp} size="lg"/>
        <DateDrawer date={reqTime} />
      </ReqTimeSlotContainer>
    </>
  );
};