import React, { memo } from "react";

import * as SC from "./profile.styled";
import ProfileContent from "./ProfileContent";
import { getSession } from "@core-const";

const ProfilePage = () => {
  const session = getSession();
  return (
    <SC.MainWrapper extraMarging={session?.role ? false : true}>
      <ProfileContent />
    </SC.MainWrapper>
  );
};

export default memo(ProfilePage);
