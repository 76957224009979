import React from 'react';
import { EmergencyRequestStatus, SharepointStatus } from '@core-interface';
import styled, { css } from "styled-components";
import { Message } from '@localization';
import { FlexRow } from 'app-styled/flex';
import { device } from '@core-const';

export const TitleContainer = styled(FlexRow)`

  ${({ theme }) => css`
    padding: ${theme["thm-space-"]} 20px;
    border-top-left-radius: ${theme["thm-space-md"]};
    border-top-right-radius: ${theme["thm-space-md"]};
    color: ${theme["smylor-white"]};
    font-weight: bold;
  `}/* @media ${device.tablet} {
    padding: 1rem 0.5rem;
  } ; */
`;
const colorList = [
    { color: '#fca103', lightColor: 'rgba(252,161,3, 0.1)' },
    { color: '#fca103', lightColor: 'rgba(252,161,3, 0.1)' },
    { color: '#fca103', lightColor: 'rgba(252,161,3, 0.1)' },
    { color: '#02781e', lightColor: 'rgba(2, 120, 30, 0.1)' },
    { color: '#150b7d', lightColor: 'rgba(21, 11, 125, 0.1)' },
    { color: '#818182', lightColor: 'rgba(129, 129, 130, 0.1)' },
    { color: '#b8042e', lightColor: 'rgba(184, 4, 46, 0.1)' },
    { color: '#005963', lightColor: 'rgba(0, 89, 99, 0.1)' },
    { color: '#999999', lightColor: 'rgba(153, 153, 153, 0.1)' },
    { color: '#EE4B2B', lightColor: 'rgba(238, 75, 43, 0.1)' },
    { color: '#FF0000', lightColor: 'rgba(255, 0, 0, 0.1)' },
    { color: '#FF0000', lightColor: 'rgba(255, 0, 0, 0.1)' },
];

const SharepointCardTitle = ({ status }) => {

  const getValue = () => {
    switch (status) {
        case SharepointStatus.CONSULT: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'consult'} defaultMessage={'Consult'} /></TitleContainer>;
        case SharepointStatus.PENDING: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'pending'} defaultMessage={'Pending'} /></TitleContainer>;
        case SharepointStatus.BOOKED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'booked'} defaultMessage={'Booked'} /></TitleContainer>;
        case SharepointStatus.RESERVED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'reserved'} defaultMessage={'Reserved'} /></TitleContainer>;
        case SharepointStatus.OTHER: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'other'} defaultMessage={'Other'} /></TitleContainer>;
        case SharepointStatus.CANCELLED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'cancelled'} defaultMessage={'Cancelled'} /></TitleContainer>;
        case SharepointStatus.PAID: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'paid'} defaultMessage={'Paid'} /></TitleContainer>;
        case SharepointStatus.CLOSED: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'closed'} defaultMessage={'Closed'} /></TitleContainer>;
        case SharepointStatus.CHECKING: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'checking'} defaultMessage={'Checking'} /></TitleContainer>;
        case SharepointStatus.NOSHOW: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'noshow'} defaultMessage={'No show'} /></TitleContainer>;
        case SharepointStatus.UNAVAILABLE: return <TitleContainer style={{ backgroundColor: colorList[status].lightColor, color: colorList[status].color }}><Message messageKey={'unavailable'} defaultMessage={'Unavailable'} /></TitleContainer>;
        default: return <div></div>
    };
  };
    return getValue();
}

export default React.memo(SharepointCardTitle);
